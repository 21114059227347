.binanceHeading {
  display: flex;
  font: var(--font-20-bold);
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.root {
  width: 400px;
  text-align: left;
}

.closeButton {
  width: 30px;
  height: 30px;
}

.title {
  font: var(--font-17-semibold);
  color: var(--white);
  padding-bottom: 10px;
}

.colorGradient {
  background-image: var(--gradient-foreground);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 200px;
}

.textWhite {
  font: var(--font-14-medium);
}

.text {
  color: var(--foreground-2);
  font: var(--font-14-medium);
}

.mt5 {
  margin-top: 5px;
}

.mt30 {
  margin-top: 30px;
}

.tutorialBlock {
  padding-top: 30px;
}

.message {
  margin-top: 30px;

  & > div {
    background: var(--background-3);
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    margin-bottom: 30px;
    align-items: flex-start;
  }
}

.startVerifyButton {
  min-width: 400px;
  height: 45px;
  margin-top: 0;
}

.pendingText {
  font: var(--font-14-medium);
  color: var(--white);
  padding-bottom: 30px;
}

.markText {
  color: var(--foreground-2);
  font: var(--font-14-medium);
}

.root .btnTop button {
  margin-top: 10px;
}

.pendingMessage {
  padding-top: 30px;
  display: flex;
  gap: 10px;
  font: var(--font-14-bold);
}

.statusBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--foreground-2);
}

.errorStatusBlock {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
}

.pendingItn {
  display: flex;
  gap: 20px;
  padding: 20px;
  background: var(--background-3);
  border-radius: 25px;
  margin-bottom: 30px;
}

.mark {
  margin-top: 4px;
  border-radius: 15px;
  width: 40px;
  height: 40px;
  background-image: var(--gradient-foreground);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.mailLink {
  padding-bottom: 0px;
}

.successMessage {
  padding-top: 0;
  color: var(--green-text);
  font: var(--font-14-bold);
}

.whatDoMessage {
  padding-bottom: 10px;
}

.errorMessage {
  color: var(--second-red-text);
  font: var(--font-17-semibold);
}

.chooseExchangeBlock {
  margin-top: 30px;
  padding: 20px;
  border-radius: 25px;
  background-color: var(--background-3);

  & > div {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
  }
}

.chooseExchangeButton {
  width: 100%;
  margin: 0 auto;
  background: var(--btn-hover-on-bg);
}

.textMargin {
  margin: 30px 0;
}

.color {
  font: var(--font-14-medium);
  background-image: var(--gradient-foreground);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.whiteText {
  font: var(--font-14-medium);
  color: var(--white);
}

.warningBlock {
  padding-top: 30px;
}

.noWrap {
  white-space: nowrap;
}

.warning {
  display: flex;
  align-items: center;
  gap: 8px;
}

.warningIcon {
  height: 18px;
  width: 18px;
}

.pendingIcon {
  flex: 0 0 18px;
  width: 18px;
  height: 18px;
}

.warningText {
  font: var(--font-16-bold);
  color: var(--second-red-text);
}
