.root {

}

.loader {
  margin: 200px auto 0;
}

.error {
  margin-top: 200px;
}