.button {
  position: relative;

  font: var(--font-12-medium);
  color: var(--white);
  opacity: 0.5;

  transition-property: opacity;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
  cursor: pointer;
}
.button:hover,
.active {
  opacity: 1;
}

.content {
  display: flex;
  align-items: center;
  gap: 4px;
}

.hidden {
  opacity: 0;
}

.lodaer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
