.container {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  padding: 20px;

  background-color: var(--background-2);
  border-radius: 15px;
}

.withLegends {
  padding: 20px 20px 40px 20px;
}

.chart {
  flex-grow: 1;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.title {
  font: var(--font-17-semibold);
  color: var(--white);
}

.toolbar {
  height: 20px;
}

.flexAlignCenter {
  display: flex;
  align-items: center;
  gap: 10px;
}

.separator {
  height: 70%;
  width: 1px;
  background-color: var(--foreground-1);
}

.iconButton {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;
}

.noData {
  font: var(--font-14-semibold);
  color: var(--foreground-2);
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
