@import "./src/styles/mixins";


.cashback {
  height: 100%;
  padding: 40px 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  .content {
    @include contentPage();
  }
}