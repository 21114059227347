.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 450px;
  box-sizing: border-box;
  color: var(--foreground-2);
}

.feeWrap {
  padding-top: 5px;
  font: var(--font-14-semibold);
}

.fee {
  color: var(--foreground-2);
}

.accountWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0 30px 0;

  .accountName {
    font: var(--font-14-semibold);
  }

  .account {
    font: var(--font-14-bold);
    color: var(--white);
  }
}

.btnWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}