.sorting {
  display: flex;
  align-items: center;
  .label {
    color: var(--foreground-2);
    font: var(--font-12-semibold);
    margin-right: 20px;
  }
  .sort {
    margin: 0 0 0 5px;
    width: 40px;

    svg {
      width: 10.32px;
      height: 12.01px;
      path {
        stroke: none;
      }
    }
  }
  .activeSort {

    svg {
      transform: rotate(180deg);
    }
  }
  .closeHolder {
    margin-left: 20px;
  }
  .popupTitle {
    font: var(--font-25-bold);
    color: var(--foreground-3);
    margin: 30px 0 5px;
  }
  .popupDescription {
    font: var(--font-14-medium);
    color: var(--foreground-3);
    margin-bottom: 30px;
  }
  .popupButton {
      width: 100%;
    &:last-child {
      margin-top: 10px;
    }
    .buttonReturn {
      width: 100%;
    }
    .buttonClose {
      width: 100%;
    }
  }
}

.closeButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}