.card {
  width: 175px;
  height: 474px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.calendarContainer {
  display: flex;
  align-items: center;
  gap: 13px;
}

.day {
  color: var(--white);
  font: var(--font-14-bold);
}

.cardItemsContainer {
  display: flex;
  flex-direction: column;
  gap: 14px;
  background-color: var(--background-2);
  border-radius: 15px;
  padding: 20px;
}
