@import "./src/styles/mixins";

.wrapper {
  width: 240px;
  height: 95px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font: var(--font-17-semibold);
  color: var(--white);
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-top: 15px;
}

.socialButton {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 50px;
  height: 50px;
  border-radius: 14px;
  background-color: var(--background-light-dark);
}