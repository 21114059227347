.container {
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  width: 300px;
  text-align: center;
  align-items: center;
}

.header {
  font: var(--font-25-bold);
  color: var(--foreground-3);
  margin-bottom: 10px;
}

.text {
  font: var(--font-14-medium);
  color: var(--foreground-2);
  margin-bottom: 30px;
  max-width: 250px;
}

.icon {
  width: 20px;
  height: 20px;
}

