.bordered {
  padding: 2px;
  border-radius: 50%;
  background: var(--gradient-foreground);
}

.image {
  border-radius: 50%;

  .bordered & {
    border: 2px solid var(--background-1);
  }
}