.root {
  width: 216px;
  height: 400px;
  padding: 20px;
  border-radius: 15px;
  background: #12101a;
}

.calendar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-bottom: 24px;
}

.to {
  font: var(--font-14-medium);
  color: var(--foreground-2);
}

.cardItem {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.clickable {
  cursor: pointer;
  user-select: none;
  width: 216px;
  height: 400px;
  padding: 20px;
  border-radius: 15px;
  background: #12101a;
}

.clickable:hover {
  background: var(--btn-hover);
}

.clickable:hover .cardItem div {
  background: var(--btn-hover-on-bg);
}

.emptyWeekCard {
  user-select: none;
  width: 216px;
  height: 400px;
  padding: 20px;
  border-radius: 15px;
  background: #12101a;
}
