@import './src/styles/mixins';

.questionButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;

  .icon {
    @include flex-center;
    border-radius: 7px;
    width: 20px;
    height: 20px;
    background: var(--background-3);
    cursor: pointer;
  }
}

.hintClassForTable {
  position: unset;
}

.tmmHint {
  margin-left: 2px;
}
