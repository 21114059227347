.footer {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex: 0 0 auto;
  padding: 20px 0;
  background-color: var(--background-1);
  z-index: 50;
}

.footerFixHeight {
  height: 70px;
}

.okxAlert {
  z-index: 100;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.disabled {
    display: none;
  }
}

.okxAlertClose {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  color: #fff;
  background: var(--background-3);
  svg {
    width: 10px;
    height: 10px;
  }
}

.footerXl {
  @media screen and (min-width: 1440px) {
    padding-right: 200px;
  }
}
