.wrapper {
  width: 100%;
  box-sizing: border-box;
  color: var(--foreground-2);
  transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
}

.infoWrapp {
  margin-left: 0;
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.loadWrapper {
  min-height: 392px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
}