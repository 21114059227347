.profitContainer {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 20px;
  background-color: var(--background-2);
  border-radius: 15px;

  grid-column: span 3;
  min-height: 383px;
}

.header {
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.chartTitle {
  text-align: center;
  font: var(--font-17-semibold);
  color: var(--white);
  margin-bottom: 10px;
}

.chartBackground {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.chart {
  flex-grow: 1;
}

.modal {
  min-width: 572px;
  box-sizing: border-box;
}

.profitContainer {
  display: flex;
  flex-direction: column;
  min-height: 383px;
  height: 383px;
}

.chartWrapper {
  height: 100%;
  max-height: 383px;
  box-sizing: border-box;
  overflow-y: auto;
}

.chartBackground {
  width: 100%;
  height: 100%;
}