.root {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--foreground-1);
    border-radius: 10px;
    cursor: pointer;

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
        cursor: not-allowed;
    }
}