.container {
  position: fixed;

  // Выше модальных (Popup) окон
  z-index: 1100;
}

.background {
  position: absolute;
  z-index: 100;
  background-color: var(--black-80);
}

.container,
.background {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.sheet {
  position: absolute;
  top: calc(var(--mobile-header-height) + 10px);
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;

  display: flex;
  flex-direction: column;
  gap: 15px;

  background-color: var(--background-3);
  border-radius: 20px 20px 0 0;
  padding: 20px 16px;
  overflow: hidden;
}

.top {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 30px;
}

.label {
  color: var(--white);
  font: var(--font-17-semibold);
}

.closeButton {
  position: absolute;
  right: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 30px;
  width: 30px;
  background-color: var(--btn-hover-on-bg);
  border-radius: 10px;

  color: var(--foreground-3);
  stroke: var(--foreground-3);
}

.content {
  flex-grow: 1;
  overflow-y: auto;
}
