.root {
  padding: 20px 25px 20px 20px;
  gap: 20px;
  width: 422px;
  border-radius: 25px;
  background: var(--background-2);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .message {
    font: var(--font-14-semibold);
    color: var(--white);
    flex-grow: 1;
  }

  .icon {
    border-radius: 15px;
    width: 40px;
    height: 40px;
    background: var(--btn-hover-grad);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
}
