.whiteListBlock {
  display: flex;
  font: var(--font-14-bold);
  justify-content: flex-end;
  color: var(--foreground-3);
  padding-top: 10px;
}

.whitelistContentLabel {
  gap: 0;
}

.whitelistContent {
  display: flex;
  align-items: center;
  gap: 10px;
}

.whiteListBlock:not(:last-child) {
  gap: 10px;
}

.statusIcon {
  width: 18px;
  height: 18px;
}

.text {
  cursor: pointer;
}