.root {
  max-width: 500px;
}

.title {
  font: var(--font-25-bold);
  padding-bottom: 10px;
}

.description {
  font: var(--font-14-medium);
  color: var(--foreground-2);
  padding-bottom: 30px;
}

.textPosition {
  text-align: center;
}

.btn {
  width: 100%;
}

.colorGradient {
  background-image: var(--gradient-foreground);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text {
  font: var(--font-14-medium);
  color: var(--foreground-2);
}

.rejectedInfo {
  padding-top: 30px;
}

.pendingInfo {
  padding-top: 10px;
}

.supportMail {
  text-decoration: underline;
  cursor: pointer;
}

.container {
  text-align: left;
}

.content {
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.infoText {
  display: flex;
  align-items: center;
  width: 298px;
  margin: 30px auto 40px;
  gap: 10px;
  font: var(--font-14-medium);
  color: var(--foreground-2);
}

.textRed {
  display: flex;
  align-items: center;
  margin: 30px auto 40px;
  gap: 10px;
  font: var(--font-17-medium);
  color: var(--second-red-text);
}

.chooseExchangeBlock {
  margin-top: 30px;
  padding: 20px;
  border-radius: 25px;
  background-color: var(--background-3);

  & > div {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
  }
}

.chooseExchangeButton {
  width: 100%;
  margin: 0 auto;
  background: var(--btn-hover-on-bg);
}

.textWhite {
  font: var(--font-14-semibold);
  color: var(--foreground-3);
}

.kycLinkStatusIcon {
  flex: 0 0 18px;
  width: 18px;
  height: 18px;
  margin-top: 2px;
}

.verifyButton {
  width: 100%;
  margin: 0 auto;
}

.subTitle {
  font: var(--font-17-semibold);
  color: var(--white);
}
