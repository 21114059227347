.level {
  margin-top: 30px;
  padding: 25px 20px;
  border-radius: 20px;
  background-color: var(--background-2);
}

.levelTitle {
  margin: 0 0 10px;
  font: var(--font-25-bold);
  color: var(--white);
}

.restrictions {
  margin-top: 20px;

  & ~ * {
    margin-top: 20px;
  }
}

.restriction {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:first-child) {
    margin-top: 20px;
  }
}

.restrictionName {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
}

.restrictionValue {
  margin-top: 2px;
  font: var(--font-17-semibold);
  color: var(--gray-5);

  &:first-letter {
    text-transform: uppercase;
  }
}

.statusInfo {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
}

.button, .upgradeButton {
  width: 100%;
}

.upgradeButtonsItem {
  padding-top: 10px;

  &:first-child:last-child {
    padding-top: 0;
  }
}