.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 176px;
  height: 68px;
  padding: 0 10px;
  gap: 5px;
  background: var(--background-3);
  border-radius: 10px;
  padding: 10px 0;
}

.count {
  color: var(--white);
  font: var(--font-18-medium);
}

.text {
  font: var(--font-14-medium);
  color: var(--foreground-2);
}

.positive {
  color: var(--green-text);
}

.negative {
  color: var(--second-red-text);
}
