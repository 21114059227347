.wrapper {
  width: 100%;
  box-sizing: border-box;
  color: var(--foreground-2);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 24px;
  font: var(--font-12-semibold);
}

.contentWrapper {
  padding-top: 20px;
}

.content {
  height: 270px;
  overflow-y: auto;
}

.contentHeader {
  display: flex;
  align-items: center;
  gap: 20px;
  font: var(--font-12-semibold);
  max-height: 40px;
  box-sizing: border-box;
}

.closeBtn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #1C1A24;
  color: white;
  cursor: pointer;
}

.search {
  width: 160px;
}

.size {
  width: 125px;
  padding-left: 5px;
  text-align: start;
}

.margin {
  width: 120px;
  text-align: end;
}