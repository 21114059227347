.component {
  padding: 20px;
  border-radius: 15px;
  background: var(--background-2);
  display: grid;
  gap: 10px;
  grid-template-columns: 2.4fr 1.4fr 2fr 50px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color .2s linear;
  min-height: 100px;

  &.disabledEdit {
    cursor: default;
  }

  &.disabled {
    opacity: 0.5;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:hover:not(.disabledEdit) {
    background: var(--btn-hover);

    .copyButton {
      --background: var(--btn-hover);
    }

    .closeButton {
      background-color: var(--btn-hover-on-bg);
    }
  }

  &:hover:has(.closeButton:not(:hover)):has(.copyButton:not(:hover)) {
    .editButton {
      color: var(--foreground-3);
    }
  }

  &:hover:has(.closeButton:not(:hover)):has(.copyButton:not(:hover)) {
    .editButton {
      color: var(--foreground-3);
    }
  }

  .primary {
    color: var(--foreground-3);
    font: var(--font-14-bold);
    display: flex;
    align-items: center;
  }

  .apiKeyName {
    width: 200px;
  }

  .copyButton {
    --background: var(--background-2);
    --background-hover: var(--btn-hover);
    width: 22px;
    height: 22px;
    margin-left: 6px;
    padding: 0;
    color: var(--foreground-1);

    &:hover {
      color: var(--foreground-3);
    }
  }

  .secondary {
    font: var(--font-12-semibold);
    color: var(--foreground-1);
  }

  .actions {
    display: flex;
  }

  .closeButton {
    height: 30px;
    width: 30px;
    padding: 10px;
    margin-left: 20px;
  }

  .editButton {
    height: 25px;
    width: 25px;
    margin-left: 1px;
    padding: 5px;
    color: var(--foreground-1);
    transition: color .2s ease;


    &:hover {
      color: var(--foreground-3);
    }

    svg {
      width: 15px;
    }
  }
}