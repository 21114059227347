.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  box-sizing: border-box;
  width: 100%;
  color: var(--foreground-2);
}

.description {
  font: var(--font-17-semibold);
}

.item {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 20px;
  border-radius: 15px;
  background: var(--background-3);
}

.numberWrap {
  display: flex;
  align-items: center;
  gap: 12px;
}

.number {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  padding: 15px;
  align-items: center;
  border-radius: 50%;
  border: 1px solid var(--white);
  font: var(--font-14-medium);
  color: var(--white);
}

.itemText {
  text-align: left;
  color: var(--white);
  font: var(--font-14-semibold);
}

.copyTextWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.line {
  height: 1px;
  background-color: var(--btn-hover-on-bg);
  flex-grow: 1;
}

.copyText {
  color: var(--white);
  font: var(--font-14-medium);
  font-style: italic;
}

.iconWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 15px;
  background-color: var(--background-3);
}

.btnWrap {
  display: flex;
  width: 100%;
}