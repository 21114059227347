.root {
  display: flex;
  gap: 8px;
  max-width: 100%;
  cursor: pointer;

  &.disabled {
    cursor: default;
  }
}

.styled {
  display: inline-flex;
  vertical-align: top;
  white-space: nowrap;
  align-items: center;
  font: var(--font-14-bold);
  color: var(--foreground-2);

  .inputVisual {
    margin-right: 15px;
    color: var(--white);
  }
}

.dark {
  display: inline-flex;
  vertical-align: top;
  white-space: nowrap;
  align-items: center;
  font: var(--font-14-bold);
  color: var(--foreground-2);

  .inputVisual {
    margin-right: 15px;
	background-color: var(--background-1);
  }
}

.lighter {
  display: inline-flex;
  vertical-align: top;
  white-space: nowrap;
  align-items: center;

  &.checked {
    .inputVisual {
      background-color: var(--foreground-1);
    }
  }

  .inputVisual {
    border: 2px solid var(--foreground-1);
  }
}

.lighterInverse {
  &.checked {
    .inputVisual {
      border: 0;
      background-color: var(--foreground-1);
    }
  }

  .inputVisual {
    border: 0;
    background-color: var(--background-3);
  }
}

.input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
  visibility: hidden;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: var(--background-3);
  transition: opacity 0.2s linear;

  .checked & {
    background-color: var(--green-text);
  }

  .disabled & {
    opacity: 0.8;
  }
}

.square {
  display: flex;
  align-items: center;
  justify-content: center;

  border: 2px solid var(--background-3);
  transition: all 0.2s linear;

  .checked & {
    background-color: var(--background-3);
  }

  .disabled & {
    opacity: 0.8;
  }
}

.small {
  min-width: 16px;
  width: 16px;
  min-height: 16px;
  height: 16px;
  border-radius: 3.5px;

  .checkmark {
    width: 12px;
    height: 12px;
  }
}
.medium {
  width: 20px;
  height: 20px;
  border-radius: 4.5px;

  .checkmark {
    width: 12px;
    height: 12px;
  }
}
.large {
  width: 30px;
  height: 30px;
  border-radius: 7px;

  .checkmark {
    width: 26px;
    height: 26px;
  }
}

.checkmark {
  opacity: 0;
  transition: opacity 0.1s linear;

  .checked & {
    opacity: 1;
  }
}
