.root {
  display: flex;
  justify-content: center;
  width: 100%;
  @media screen and (max-width: 1600px) {
    max-width: calc(100% - 432px);
    flex-basis: 0;
  }
}

.main_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.headerBlock {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  width: 100%;
  max-width: 1162px;
  @media screen and (min-width: 2100px) {
    min-width: 1162px;
    max-width: none;
  }
}

.year {
  color: var(--white);
  font: var(--font-30-extrabold);
}
.cards_wrapper {
  width: 100%;
  display: flex;
  overflow-y: auto;
}
.cardContainer {
  max-width: 1162px;
  width: 100%;
  height: calc(100vh - 290px);
  @media screen and (min-width: 2100px) {
    min-width: 1162px;
    max-width: none;
  }
  @media screen and (max-width: 1650px) {
    max-width: 1080px;
  }
}

.infiniteScrollPlate {
  height: 40px;
}

.errorBlock {
  position: relative;
  top: 150px;
  left: -10%;
}

.emptyList {
  position: relative;
  top: 80px;
  right: 58px;
}
