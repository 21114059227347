:root {
  --gradient-foreground: radial-gradient(100% 2339.91% at 0% 0%, #E571FF 9.59%, #FF8933 100%);
  --gradient-foreground-2: radial-gradient(1017.46% 23463.11% at 0% 47.06%, #B7B6FF 0%, #FF3364 100%);
  --gradient-foreground-3: linear-gradient(180deg, hsla(241, 100%, 86%, 1) 0%, hsla(346, 100%, 60%, 1) 100%);

  --btn-hover-grad: linear-gradient(92.03deg, #F37E9A 0%, #FF8933 100%);

  //Additional gradients 45°
  --gradient-1: linear-gradient(38.61deg, #FCC4ED 0%, #FEA471 48.21%, #FF627E 100%);
  --gradient-2: linear-gradient(33.23deg, #C8CAFB 0%, #E7569A 100%);
  --gradient-3: linear-gradient(45deg, #D395FF 0%, #EF32FF 44.56%, #4062FF 100%);
  --gradient-4: linear-gradient(24.93deg, #24C8DE 0%, #4EED34 100%);
  --gradient-5: linear-gradient(38.61deg, #75E6FF 0%, #A3EA90 47.16%, #FFB951 100%);
  --gradient-6: linear-gradient(33.23deg, #B9F9FF 0%, #6AC4CC 100%);
  --gradient-7: linear-gradient(33.23deg, #BFA8FF 0%, #FF51BC 47.16%, #FC4F49 100%);
  --gradient-8: linear-gradient(33.23deg, #FFF7A4 0%, #F9C1FE 100%);
}