.root {
  margin-top: 20px;
}

.top {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 20px;
}

.tradingHeading,
.tradingMonthHeading {
  font: var(--font-14-medium);
  color: var(--foreground-2);
}

.tradingAmount {
  margin: 7px 0 16px;
  font: var(--font-40-extrabold);
  color: var(--foreground-3);
}

.tradingMonth {
  display: flex;
  align-items: center;
  gap: 10px;
}

.tradingMonthAmount {
  font: var(--font-14-bold);
  color: var(--white);
}

.lastTopColumn {
  text-align: right;
}

.info {
  margin: 30px 0 40px;
  font: var(--font-14-medium);
  color: var(--foreground-2);
}

.howLink {
  display: inline-flex;
  margin: 0 0 10px;
}

.upToMsg {
  max-width: 250px;
  font: var(--font-20-bold);
  color: var(--foreground-2);
  text-align: right;
}

.accent {
  color: var(--white);
}