.skeletonButton {
  position: relative;
  overflow: hidden;
  width: 150px;
  height: 40px;
  border-radius: 10px;
  background: var(--background-2);

  &:before {
    content: '';
    position: absolute;
    width: 30px;
    height: 40px;
    top: 0;
    left: 0;
    background: #1B1824;
    box-shadow: 0 0 50px 50px #1B1824;
    animation: animate 2.2s linear infinite;
    z-index: 1;
  }
}

.skeletonList {
  display: flex;
  background: var(--background-2);
  overflow: hidden;
  position: relative;
  margin-top: 30px;
  .skeletonListRightBar {
    width: 100%;
    .top {
      position: relative;
      z-index: 5;
      .topItems {
        display: flex;
        justify-content: space-between;
        .leftPart {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .itemWrapper {
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            .item {
              width: 403px;
              border-radius: 9px;
              box-shadow: 0 0 0 300px var(--background-1);
              height: 27px;
            }
            &:last-child {
              padding-top: 20px;
              .item {
                width: 195px;
                height: 19px;
              }
            }
          }
        }
        .center {
          flex-basis: 100%;
          background: var(--background-1);
          height: auto;
        }
        .rightPart {
          overflow: hidden;
          display: flex;
          justify-content: flex-end;
          width: 100%;
          .itemWrap {
            height: 100%;
            padding-left: 7px;
            .item {
              width: 147px;
              height: 40px;
              border-radius: 10px;
              box-shadow: 0 0 0 300px var(--background-1);
            }
          }
        }
      }
    }
    .middle {
      height: 45px;
      width: 100%;
      background: var(--background-1);
      position: relative;
      z-index: 10;
    }
    .bottom {
      overflow: hidden;
      .title {
        font: var(--font-25-extrabold);
        color: var(--foreground-1);
        background: var(--background-1);
        position: relative;
        z-index: 10;
        padding-bottom: 23.5px;
      }
      .bottomContent {
        overflow: hidden;
        z-index: 10;
        position: relative;
        &:not(:last-child) {
          padding-bottom: 10px;
        }

        .blockWrap {
          border-radius: 15px;
          padding: 20px;
          box-shadow: 0 0 0 300px var(--background-1);
          display: grid;
          grid-template-columns: 2fr 1fr 1fr 15% auto;
          justify-content: space-between;
          align-items: center;
          position: relative;
          z-index: 5;

          .left {
            display: flex;
            .circle {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background: var(--background-3);
              margin-right: 20px;
            }
            .itemWrapper {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .item {
                width: 179px;
                height: 15px;
                border-radius: 9px;
                background: var(--background-3);
                &:last-child {
                  width: 103px;
                  height: 11px;
                }
              }
            }
          }
          .itemWrap {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .item {
              width: 103px;
              height: 15px;
              border-radius: 9px;
              background: var(--background-3);
              &:last-child {
                width: 45px;
                height: 11px;
              }
            }

            &:last-child {
              align-items: flex-end;
              .item {
                width: 137px;
                height: 15px;
                border-radius: 9px;
                background: var(--background-3);
                &:last-child {
                  width: 50px;
                  height: 11px;
                }
              }
            }
          }
        }
      }
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 30px;
    height: 700px;
    top: 0;
    left: 0;
    background: #1B1824;
    box-shadow: 0 0 50px 50px #1B1824;
    animation: animate 2.2s linear infinite;
    z-index: 1;
  }
}

@keyframes animate {
  0% {
    transform: translate(-100px) skewX(-10deg);
  }
  30% {
    transform: translate(100vw) skewX(-10deg);
  }
  100% {
    transform: translate(100vw) skewX(-10deg);
  }
}
