.root {
  margin: 70px 0 40px;
}

.heading {
  margin: 0;
  font: var(--font-30-extrabold);
  color: var(--white);
}

.section {
  position: relative;
  margin-top: 30px;
}

.sectionHeading {
  margin: 0 0 15px;
  font: var(--font-14-bold);
  color: var(--foreground-2);
}

.sectionIcon {
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
}

.setting {
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 20px;
  border-radius: 15px;
  background-color: var(--background-2);

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &>*:last-child {
    margin-left: auto;
  }
}

.disabled {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
  border-radius: 15px;
}

.disabledAssetMode {
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}

.settingTitle {
  font: var(--font-17-semibold);
  color: var(--gray-5);
}

.settingWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.tooltip {
  display: flex;
}

.setting .settingContainer {
  margin: 0;
}

.settingStatus {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 2px;
  font: var(--font-14-semibold);
  color: var(--foreground-2);
}

.settingAccent {
  background-image: var(--gradient-foreground);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.crossIcon {
  --background: var(--background-3);
  --foreground: var(--foreground-2);
}

.completedIcon {
  --foreground: var(--white);
}

.settingActions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
}

.exchangeSelect {
  --toggle-background: var(--background-3);
}

.consentText {
  color: var(--white) !important;
}

.nowrap {
  white-space: nowrap;
}

.titleReset {
  font: var(--font-17-semibold);
  color: var(--gray-5);
  padding-top: 2px;
}