:root {
  --scrollbar-width: 11px;

  // firefox
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, .2) transparent;

  // chrome / safari
  ::-webkit-scrollbar {
    width: var(--scrollbar-width);
  }

  ::-webkit-scrollbar-track {
    border: 3px solid rgba(255, 255, 255, .01);
    border-radius: 8px;
    background-color: rgba(255, 255, 255, .01);
    background-clip: content-box;
    margin-block: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 3px solid rgba(255, 255, 255, .001);
    background-clip: content-box;
    box-shadow: inset 0 0 2px rgba(255, 255, 255, .5);
    background-color: rgba(255, 255, 255, .01); // color of the scroll thumb
  }

  ::-webkit-scrollbar-corner {
    background-color: rgba(255, 255, 255, .01);
  }

  ::-webkit-resizer {
    border-width: 8px;
    border-style: solid;
    border-color: rgba(255, 255, 255, .5);
  }
}
