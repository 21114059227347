.root {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  border-radius: 15px;
  background-color: var(--background-2);
}

.text {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
  line-height: 17px;
}