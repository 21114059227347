.links {
  display: flex;
  align-content: center;
  margin-left: 25px;

  & > * {
    margin-right: 25px;
    @media screen and (max-width: 1400px) {
      margin-right: 20px;
    }
    @media screen and (max-width: 1340px) {
      margin-right: 15px;
    }
  }

  & > *:last-child {
    margin: 0;
  }
}
