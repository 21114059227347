.accountBalance {
  .label {
    font: var(--font-14-medium);
    color: var(--foreground-2);
    margin-bottom: 5px;
  }
  .balance {
    font: var(--font-40-extrabold);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: var(--gradient-foreground);
  }
  .currency {
    font: var(--font-12-extrabold);
    color: var(--foreground-3);
  }
}
