.wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 15px;
  background: var(--background-3);
  border-radius: 10px;
}

.title {
  font: var(--font-17-semibold);
  color: var(--white);
}