.adl {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
}

.linesWrapper {
  display: flex;
  gap: 2px;
  padding: 4px 0;
  text-decoration: none;

  &.small {
    cursor: pointer;
    border-bottom: 1px dashed var(--foreground-2);
  }
}

.line {
  width: 4px;
  height: 12px;
  border-radius: 10px;
  background-color: var(--foreground-2);
}

.active {
  &.default {
    background-color: var(--green-text);
  }

  &.warning {
    background-color: var(--warning-color);
  }

  &.danger {
    background-color: var(--danger-color);
  }
}

.link {
  font: var(--font-12-semibold);
  line-height: 17px;

  color: var(--foreground-2);
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}
