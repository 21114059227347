.root {
  display: flex;
  align-items: baseline;
  gap: 6px;
  line-height: 1;
}

.currency {
  font: var(--font-20-bold);
  color: var(--foreground-2);
}