.root {
    margin: 20px 0;
}

.achievement {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    font: var(--font-12-semibold);
    line-height: 17px;
    color: var(--foreground-2);
    background: var(--background-2);
    border-radius: 15px;
    &.active {
        .icon {
            width: 40px;
            height: 40px;
            left: 0;
            top: 0;
        }
    }
    &.disabled {
        pointer-events: none;
        opacity: .5;
    }
    & + & {
    margin-top: 10px;
    }
}

.iconWrap {
    position: relative;
    margin: 0 30px 0 0;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 2px dashed var(--foreground-1);
    font: 700 23px/40px var(--font-family);
    text-align: center;
    * {
        vertical-align: top;
    }
}

.icon {
    position: absolute;
    right: -10px;
    top: -10px;
    width: 30px;
    height: 30px;
    svg {
        width: 100%;
    }
}

.text {
    max-width: 480px;
}

.title {
    font: var(--font-14-semibold);
    color: var(--white);
}

.controls {
    margin-left: auto;
}

.btn {
    width: 150px;
}