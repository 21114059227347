.root {
  margin-top: 40px;
  padding: 20px;
  border-radius: 15px;
  background-color: var(--background-2);
}

.heading {
  font: var(--font-20-bold);
  color: var(--white);
}

.info {
  margin-top: 10px;
  font: var(--font-14-medium);
  color: var(--foreground-2);
}

.infoAccent {
  font: var(--font-14-bold);
  color: var(--white);
  white-space: nowrap;
}