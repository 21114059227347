.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.line {
  width: 100%;
  height: 1px;
  border: 1px dashed var(--foreground-2);
  margin-bottom: 30px;
}

.container {
  display: flex;
  gap: 10px;
}

.loader {
  position: relative;
  top: 40%;
}

.errorBlock {
  position: relative;
  top: 120px;
}

.emptyList {
  position: relative;
  top: 100px;
}

.chartWrapper {
  height: 100%;
  width: 100%;
  min-width: 700px;
  border-radius: 7px;
  overflow: hidden;
  outline: 1px solid var(--foreground-1);
}

.chartLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.wrapper {
  display: grid;
  grid-template-columns: 0.9fr auto;
  width: 100%;
  gap: 14px;
  @media screen and (max-width: 1440px) {
    gap: 5px;
  }
}

.chartContainer {
  background: var(--background-2);
  padding: 10px;
  border-radius: 7px;
  width: 100%;
  display: flex;
  gap: 10px;
}
