@import "./src/styles/mixins";

.wrapper {
  height: 100%;
  padding: 40px 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    flex-direction: column;
  }
}

.headerWrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
}

.resultWrapp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leaderLists {
  padding-top: 32px;
  display: flex;
  justify-content: space-between;
  gap: 32px;
}

.header {
  display: flex;
  flex-direction: column;

  &__description {
    font: var(--font-14-medium);
    color: var(--foreground-2)
  }
}

.filter {
  display: flex;
  align-items: center;
  gap: 15px;
  color: var(--white);
}

.linkWrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
}

.emptyWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.share {
  width: 40px;
  margin-left: 15px;
}

.dropdown {
  background: var(--background-3);
}