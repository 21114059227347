.root {
    margin: 30px 0;
    font: var(--font-14-medium);
    line-height: 20px;
}

.item {
    display: flex;
    margin: 15px 0;
    &.active {
        color: var(--white);
        .checklist {
            border-color: transparent;
            background-image: var(--gradient-foreground);
        }
    }
}

.checklist {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 20px;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--background-1);
    border: 1px solid var(--background-3);
    margin: 0 10px 0 0;
}