.section {
  position: relative;
  margin-top: 30px;
}

.sectionHeading {
  margin: 0 0 15px;
  font: var(--font-14-bold);
  color: var(--foreground-2);
}

.sectionIcon {
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
}

.wrapp{
  display: flex;
  align-items: center;
  height: 500px;
}