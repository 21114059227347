.burger {
  position: relative;
  width: 40px;
  height: 40px;
  background-color: var(--background-light-dark);
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  border: none;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;

    background-image: var(--gradient-foreground);
    border-radius: inherit;
    opacity: 0;
    pointer-events: none;

    transition: opacity 0.2s ease-in-out;
    backface-visibility: hidden;
  }

  @media (pointer: fine) {
    &:hover::before {
      opacity: 1;
    }
  }
}

.lines {
  position: relative;
  width: 100%;
  height: 100%;

  &::after,
  &::before {
    content: '';
    position: absolute;

    width: 16px;
    height: 2px;

    border-radius: 1px;
    background-color: var(--white);
    transition: transform 0.2s ease-in-out;
  }

  &::before {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, calc(-50% - 4px), 0);
  }

  &::after {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, calc(-50% + 4px), 0);
  }
}

.open {
  &::before {
    opacity: 1;
  }

  .lines {
    &::before {
      transform: translate3d(-50%, -50%, 0) rotate(-135deg);
    }

    &::after {
      transform: translate3d(-50%, -50%, 0) rotate(135deg);
    }
  }
}

.menu {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background-1);
  padding: var(--mobile-header-height) 0 0;

  .wrapper {
    height: 100%;
    width: 100%;
    max-width: 1440px;
    padding: 0 20px;
    margin: 20px auto 0;
  }
}

.verticalList {
  display: flex;
  flex-direction: column;
  gap: 20px;

  & ~ & {
    margin-top: 40px;
  }
}

.horizontalList {
  display: flex;
  gap: 20px;
  margin-top: 30px;
}

.language {
  font: var(--font-18-semibold);
  color: var(--white);
  opacity: 0.7;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    opacity: 1;
  }
}

.active {
  opacity: 1;
}

.socialWrapper {
  position: absolute;
  bottom: 35px;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.socialList {
  display: flex;
  gap: 10px;

  list-style: none;
  margin: 0;
  padding: 0;
}

.socialTitle {
  text-transform: uppercase;
  font: var(--font-12-semibold);
  color: var(--general-gray);
}

.socialList {
  display: flex;
  gap: 10px;

  list-style: none;
  margin: 0;
  padding: 0;
}

.socialButton {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 50px;
  height: 50px;
  border-radius: 14px;
  background-color: var(--background-light-dark);
}
