.wrap {
  height: 100%;
  padding: 54px 0 76px;
  width: 100%;
  display: flex;
  flex-direction: column;
  .content {
    display: flex;
    gap: 66px;
  }
}
