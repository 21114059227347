.table {
  table-layout: fixed;
  width: 100%;
  background-color: var(--background-2);
  border-radius: 13px;
  padding: 3px;
}

.tab {
  font: var(--font-14-extrabold);
  color: var(--foreground-1);
  line-height: 1.4;
  cursor: pointer;
  border-radius: 10px;
  padding: 10px 0;
  width: 100%;
  text-align: center;

  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  @media (pointer: fine) {
    &:hover {
      color: var(--white);
    }
  }
}

.active {
  cursor: default;
  background-color: var(--background-3);
  color: var(--white);
}
