.errorContainer {
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.title {
  font: var(--font-30-extrabold);
  color: var(--foreground-3);
  padding-bottom: 10px;
}

.description {
  color: var(--foreground-2);
  font: var(--font-14-medium);
  padding-bottom: 30px;
}

.btn {
  width: 200px;
}

.containerRefreshTrades {
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tradesRequestTittle {
  color: var(--white);
  font: var(--font-25-bold);
  padding-bottom: 10px;
}

.button {
  width: 100%;
}

.containerRefreshTrades .button {
  color: var(--foreground-1);
}

.containerRefreshTrades .buttonInitialColor {
  color: var(--white);
}
