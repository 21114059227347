@import 'fonts';
@import 'mixins';
@import 'scrollbar';
@import 'variables/colors';
@import 'variables/gradients';
@import 'variables/typography';
@import 'variables/layout';

* {
  box-sizing: border-box;
}

html {
  overflow: hidden;
}

body {
  margin: 0;
  overflow: auto;
  overflow-scrolling: touch;
  background-color: var(--background-1);
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
  position: relative;
  z-index: 1;
}

//INTERCOM STYLES
.intercom-launcher {
  background: var(--background-3) !important;
}

iframe[data-intercom-frame='true'] {
  background: var(--background-3) !important;
}
