.root {
  position: relative;

  &:hover .popup {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }
}

.avatar {
  width: 40px;
  height: 40px;
}


