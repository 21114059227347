@import "./src/styles/mixins";

.card {
  position: relative;
  width: 180px;
  height: 257px;
  border-radius: 20px;
  border: 1px 0px 0px 0px;
  padding: 40px 15px 20px 15px;
  text-align: center;
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: rgba(18, 16, 26, 1);
  opacity: 0.8;

  &.first {
    background: radial-gradient(102.94% 100% at 50% 100%, rgba(251, 180, 16, 0.5) 0%, rgba(251, 180, 16, 0) 90%);
    border: 0.5px solid #FBB410;
  }

  &.second {
    background: radial-gradient(102.94% 100% at 50% 100%, rgba(218, 217, 217, 0.2) 0%, rgba(218, 217, 217, 0) 90%);
    border: 0.5px solid rgba(218, 217, 217, 0.5);
  }

  &.third {
    background: radial-gradient(102.94% 100% at 50% 100%, rgba(173, 106, 94, 0.2) 0%, rgba(173, 106, 94, 0) 90%);
    border: 0.5px solid rgba(173, 106, 94, 0.5);
  }

  &:hover {
    .copy {
      display: flex;
    }
  }

  &.active {
    opacity: 1;
    border-width: 1.5px;
  }
}

.bg {
  position: absolute;
  top: -4px;
  left: -2px;
  right: -2px;
  width: 185px;
  height: 80px;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(11, 8, 1, 1) 50%, rgba(11, 8, 1, 1) 100%);
}

.position {
  z-index: 2;
}

.firstPosition {
  padding-bottom: 30px;
}

.secondPosition {
  padding-bottom: 25px;
}

.thirdPosition {
  padding-bottom: 15px;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 10px;
  z-index: 10;
}

.username {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font: var(--font-14-semibold);
  padding: 10px 0;
}

.copy {
  position: absolute;
  right: -35px;
  display: none;
  align-items: center;
  justify-content: center;
}

.currency {
  font: var(--font-12-semibold);
}

.amount {
  font: var(--font-14-semibold);
}