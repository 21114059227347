.root {
  width: 340px;
}

.headerMonths {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: var(--font-17-semibold);
}

.headerStartEndDates {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: var(--font-17-semibold);
  margin: 0 10px;
  color: var(--white);
  padding-bottom: 10px;
}

.headerActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
}

.headerActionsButton {
  background-color: transparent;
}

.arrowIcon {
  height: min-content;
  width: min-content;
}

.headerActionsButtonWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.headerDateBlock {
  width: 125px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid var(--background-3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 10px;

  button {
    width: 100%;
  }
}

.actionCancel {
  background-color: var(--background-3);
}

.invalidRange {
  color: var(--red-text);
  font: var(--font-14-semibold);
  padding: 15px 0;
}
.line {
  background: var(--foreground-1);
  width: 13px;
  height: 1px;
}

.inputContainer {
  display: flex;
  height: 40px;
  gap: 4px;
}

.inputContainer .input {
  width: 25px;
  border-bottom: none;
}

.dots {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dateMsg {
  padding-top: 5px;
  font: var(--font-14-semibold);
  color: var(--foreground-2);
}
