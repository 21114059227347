.root {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.cashbackTitle {
  color: var(--white);
  font: var(--font-14-semibold);
}

.cashbackContainer {
  display: flex;
  justify-content: space-around;
}

.cashbackItemName {
  color: var(--foreground-2);
  font: var(--font-14-semibold);
}

.cashbackItem {
  display: flex;
  flex-direction: column;
}

.cashbackPercent {
  color: var(--white);
  font: var(--font-25-bold);
}

.empty{
  width: 30px;
}
