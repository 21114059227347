.radio {
  opacity: .5;
  transition: opacity .2s ease;

  .loaded & {
    opacity: 1;
  }

  & > *:last-child {
    opacity: 0;
    transition: opacity .2s ease;

    .loaded & {
      opacity: 1;
    }
  }
}