.content {
  margin: 0 auto;
  padding-top: 50px;
  max-width: 460px;
  width: 100%;
  .content_title {
    display: block;
  }
}

.ips {
  margin: 0 0 40px;
}

.ip {
  margin-right: 10px;
  margin-bottom: 10px;
}

.button {
  width: 100%;
  margin-top: 20px;
}

.checkboxesWrapper {
  margin-top: 20px;
  display: flex;
  justify-content: left;
  gap: 20px;
  margin-bottom: 40px;
  font: var(--font-14-semibold);
  color: var(--foreground-3);
}
.buttonsWrapper {
  margin-top: 40px;
}

.subtitle {
  text-align: center;
  color: var(--foreground-2);
  margin: 9px 0 30px;
  font: var(--font-12-semibold);
}

.message {
  position: relative;
  border-radius: 20px;
  color: var(--foreground-3);
  background: var(--background-2);
  padding: 20px;
  font-size: 14px;
  line-height: 20px;
  z-index: 50;

  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: auto 40px;
  gap: 20px;
}