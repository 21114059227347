.tigerWidgetPercentColor1 {
  --color: var(--second-red-text);
}

.tigerWidgetPercentColor2 {
  --color: #8ffff9;
}
.tigerWidgetPercentColor3 {
  --color: #0bdba8;
}
.tigerWidgetPercentColor4 {
  --color: #00ff94;
}

.tigerWidgetPercent,
.tigerWidgetPercent * {
  box-sizing: content-box;
}
.tigerWidgetPercent {
  min-width: 200px;
  display: block;
  position: relative;
  text-align: center;
  text-decoration: none;
  color: #676573;
  padding-top: 52px;
  min-height: 116px;
  text-transform: none;
  letter-spacing: normal;
  white-space: normal;
  background: #12101a;
  border: 1px solid #1c1a24;
  border-radius: 16px;
  overflow: hidden;
  backface-visibility: hidden;
  transform: translateZ(0);
}

.tigerWidgetPercentValue {
  font: bold 20px/30px var(--font);
  color: #37363e;
  margin: 0 0 7px 0;
  transition: color 0.5s ease;
  font: var(--font-20-bold);
}

.tigerWidgetPercentNum {
  display: inline-block;
  vertical-align: top;
  background: #262430;
  padding: 4px 10px;
  border-radius: 13px;
  margin: 0 0 13px 0;
  transition: color 0.5s ease;
  font: var(--font-12-semibold);
}

.tigerWidgetPercentText {
  font: var(--font-14-semibold);
  padding-top: 6px;
}
.tigerWidgetPercentReady .tigerWidgetPercentNum {
  color: var(--white);
}
.tigerWidgetPercentCircle {
  position: absolute;
  top: 16px;
  left: 50%;
  width: 148px;
  height: 148px;
  transform: translate3d(-50%, 0, 0);
  --angle: 8.08deg;
  --start: -112.5deg;
  --delay: 0.1s;
}
.tigerWidgetPercentCircle > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.tigerWidgetPercentCircle > *:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  height: 11px;
  width: 3px;
  border-radius: 1.5px;
  transform: translate3d(-50%, 0, 0) scale(1);
  background: #37363e;
  transition-property: transform, background;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  transition-delay: inherit;
}
.tigerWidgetPercentCircle > *:nth-child(1) {
  transform: rotate(calc(var(--start) + var(--angle) * 0));
  transition-delay: calc(var(--delay) * 0);
}
.tigerWidgetPercentCircle > *:nth-child(2) {
  transform: rotate(calc(var(--start) + var(--angle) * 1));
  transition-delay: calc(var(--delay) * 1);
}
.tigerWidgetPercentCircle > *:nth-child(3) {
  transform: rotate(calc(var(--start) + var(--angle) * 2));
  transition-delay: calc(var(--delay) * 2);
}
.tigerWidgetPercentCircle > *:nth-child(4) {
  transform: rotate(calc(var(--start) + var(--angle) * 3));
  transition-delay: calc(var(--delay) * 3);
}
.tigerWidgetPercentCircle > *:nth-child(5) {
  transform: rotate(calc(var(--start) + var(--angle) * 4));
  transition-delay: calc(var(--delay) * 4);
}
.tigerWidgetPercentCircle > *:nth-child(6) {
  transform: rotate(calc(var(--start) + var(--angle) * 5));
  transition-delay: calc(var(--delay) * 5);
}
.tigerWidgetPercentCircle > *:nth-child(7) {
  transform: rotate(calc(var(--start) + var(--angle) * 6));
  transition-delay: calc(var(--delay) * 6);
}
.tigerWidgetPercentCircle > *:nth-child(8) {
  transform: rotate(calc(var(--start) + var(--angle) * 7));
  transition-delay: calc(var(--delay) * 7);
}
.tigerWidgetPercentCircle > *:nth-child(9) {
  transform: rotate(calc(var(--start) + var(--angle) * 8));
  transition-delay: calc(var(--delay) * 8);
}
.tigerWidgetPercentCircle > *:nth-child(10) {
  transform: rotate(calc(var(--start) + var(--angle) * 9));
  transition-delay: calc(var(--delay) * 9);
}
.tigerWidgetPercentCircle > *:nth-child(11) {
  transform: rotate(calc(var(--start) + var(--angle) * 10));
  transition-delay: calc(var(--delay) * 10);
}
.tigerWidgetPercentCircle > *:nth-child(12) {
  transform: rotate(calc(var(--start) + var(--angle) * 11));
  transition-delay: calc(var(--delay) * 11);
}
.tigerWidgetPercentCircle > *:nth-child(13) {
  transform: rotate(calc(var(--start) + var(--angle) * 12));
  transition-delay: calc(var(--delay) * 12);
}
.tigerWidgetPercentCircle > *:nth-child(14) {
  transform: rotate(calc(var(--start) + var(--angle) * 13));
  transition-delay: calc(var(--delay) * 13);
}
.tigerWidgetPercentCircle > *:nth-child(15) {
  transform: rotate(calc(var(--start) + var(--angle) * 14));
  transition-delay: calc(var(--delay) * 14);
}
.tigerWidgetPercentCircle > *:nth-child(16) {
  transform: rotate(calc(var(--start) + var(--angle) * 15));
  transition-delay: calc(var(--delay) * 15);
}
.tigerWidgetPercentCircle > *:nth-child(17) {
  transform: rotate(calc(var(--start) + var(--angle) * 16));
  transition-delay: calc(var(--delay) * 16);
}
.tigerWidgetPercentCircle > *:nth-child(18) {
  transform: rotate(calc(var(--start) + var(--angle) * 17));
  transition-delay: calc(var(--delay) * 17);
}
.tigerWidgetPercentCircle > *:nth-child(19) {
  transform: rotate(calc(var(--start) + var(--angle) * 18));
  transition-delay: calc(var(--delay) * 18);
}
.tigerWidgetPercentCircle > *:nth-child(20) {
  transform: rotate(calc(var(--start) + var(--angle) * 19));
  transition-delay: calc(var(--delay) * 19);
}
.tigerWidgetPercentCircle > *:nth-child(21) {
  transform: rotate(calc(var(--start) + var(--angle) * 20));
  transition-delay: calc(var(--delay) * 20);
}
.tigerWidgetPercentCircle > *:nth-child(22) {
  transform: rotate(calc(var(--start) + var(--angle) * 21));
  transition-delay: calc(var(--delay) * 21);
}
.tigerWidgetPercentCircle > *:nth-child(23) {
  transform: rotate(calc(var(--start) + var(--angle) * 22));
  transition-delay: calc(var(--delay) * 22);
}
.tigerWidgetPercentCircle > *:nth-child(24) {
  transform: rotate(calc(var(--start) + var(--angle) * 23));
  transition-delay: calc(var(--delay) * 23);
}
.tigerWidgetPercentCircle > *:nth-child(25) {
  transform: rotate(calc(var(--start) + var(--angle) * 24));
  transition-delay: calc(var(--delay) * 24);
}
.tigerWidgetPercentCircle > *:nth-child(26) {
  transform: rotate(calc(var(--start) + var(--angle) * 25));
  transition-delay: calc(var(--delay) * 25);
}
.tigerWidgetPercentCircle > *:nth-child(27) {
  transform: rotate(calc(var(--start) + var(--angle) * 26));
  transition-delay: calc(var(--delay) * 26);
}
.tigerWidgetPercentCircle > *:nth-child(28) {
  transform: rotate(calc(var(--start) + var(--angle) * 27));
  transition-delay: calc(var(--delay) * 27);
}
.tigerWidgetPercentCircle > *:nth-child(29) {
  transform: rotate(calc(var(--start) + var(--angle) * 28));
  transition-delay: calc(var(--delay) * 28);
}
.tigerWidgetPercentReady .tigerWidgetPercentCircle > .tigerWidgetPercentCircleActive:before {
  transform: translate3d(-50%, 0, 0) scale(1.2, 1.8);
  background: var(--color) !important;
}

.tigerWidgetPercentReady .tigerWidgetPercentCircle > *:before {
  background: var(--color) !important;
}
.tigerWidgetPercentReady .tigerWidgetPercentCircle > .tigerWidgetPercentCircleActive ~ *:before {
  background: #37363e !important;
}

.tigerWidgetPercentReady .tigerWidgetPercentValue {
  color: var(--color);
}
