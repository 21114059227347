@import "src/styles/mixins";

.component {
  color: var(--foreground-3);
}

.input:has(input:focus),
.input:has(input:not(:placeholder-shown)) {
  .plusBtn {
    opacity: 1;
    pointer-events: auto;
  }
}

.plusBtn {
  width: 30px;
  height: 30px;
  padding: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity .2s ease;
}

.chips {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: 20px;
}

