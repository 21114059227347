@import "./src/styles/mixins";

.button {
  width: 100%;
  margin-top: 20px;
}

.checkboxesWrapper {
  display: flex;
  justify-content: left;
  gap: 20px;
  margin-top: 35px;
  font: var(--font-14-semibold);
  color: var(--foreground-3);
}
.inputAndChipsWrapper {
  margin-top: 45px;
}
.buttonsWrapper {
  margin-top: 35px;
}
.enterCodeWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.subtitle {
  text-align: center;
  color: var(--foreground-2);
  margin: 9px 0 30px;
  font: var(--font-12-semibold);
}

.codeWrapper {
  @include flex-space-between;
  max-width: max-content;
  display: flex;
  justify-content: center;
  gap: 5px;
  .line {
    height: 2px;
    width: 12px;
    background: var(--foreground-3);
    border-radius: 10px;
    margin: 0 10px;
  }
}

.resendButton {
  width: 60%;
  height: 45px;
  font: var(--font-14-bold);
  color: var(--background-1);
  margin-top: 18px;
  border: 0;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  .timerWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 33px;
    height: 33px;
    .timer {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      color: var(--foreground-3);
      z-index: 5;
      font-size: 9px;
    }
  }

  &:hover {
    background: var(--foreground-1);
  }
  &:disabled {
    background: transparent;
    color: var(--foreground-1);
  }
}

.codeError {
  text-align: center;
  display: block;
  margin-top:50px;
  color: var(--red-text);
  font-size: 14px;
}