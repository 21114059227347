@import './src/styles/mixins';

.playBtn {
    display: inline-block;
    cursor: pointer;
  font: var(--font-14-semibold);
  line-height: 18px;
    * {
        vertical-align: top;
    }
  @include gradientText;
  .icon {
    margin-right: 10px;
  }
}