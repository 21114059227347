.transactionsTab {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 30px;
  }

  .text {
    color: var(--foreground-2);
    opacity: 0.7;
    font: var(--font-25-extrabold);
  }

  .activeText {
    color: var(--foreground-3);
    opacity: 1;
    font: var(--font-40-extrabold);
  }
}

.mobile.transactionsTab {
  .activeText {
    font: var(--font-30-extrabold);
  }
}
