.root {
    font: var(--font-14-medium);
}

.content {
    max-height: 150px;
    overflow: auto;
    font: var(--font-14-semibold);
    color: var(--foreground-2);
}

.heading {
    font: var(--font-17-semibold);
    margin-bottom: 15px;
}