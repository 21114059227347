@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy-Medium.eot') format('embedded-opentype'),
       url('./fonts/Gilroy-Medium.woff2') format('woff2'),
       url('./fonts/Gilroy-Medium.woff') format('woff'),
       url('./fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy-Semibold.eot') format('embedded-opentype'),
       url('./fonts/Gilroy-Semibold.woff2') format('woff2'),
       url('./fonts/Gilroy-Semibold.woff') format('woff'),
       url('./fonts/Gilroy-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy-Bold.eot') format('embedded-opentype'),
       url('./fonts/Gilroy-Bold.woff2') format('woff2'),
       url('./fonts/Gilroy-Bold.woff') format('woff'),
       url('./fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy-Extrabold.eot') format('embedded-opentype'),
       url('./fonts/Gilroy-Extrabold.woff2') format('woff2'),
       url('./fonts/Gilroy-Extrabold.woff') format('woff'),
       url('./fonts/Gilroy-Extrabold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/MaterialIcons-Regular.ttf') format('truetype');
}
