@import './src/styles/mixins';

.team {
  @include contentWrapper();

  .content {
    @include contentPage();
  }
}

.customError {
  margin: 10px 0 0;
  width: 300px;
}

.authModal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.root {
  padding: 60px 0;
  width: 100%;
}

.header {
  display: flex;
  gap: 40px;
}

.title {
  font: var(--font-40-extrabold);
  color: var(--foreground-2);

  &:hover {
    color: var(--foreground-3);
  }

  &.active {
    color: var(--foreground-3);
    cursor: default;
  }
}
