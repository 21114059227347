.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-1);
  border-radius: 10px;
  padding: 4px;
  height: 53px;
}

.toggleButton {
  flex: 1;
  padding: 10px 20px;
  border: none;
  height: 45px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--background-1);
  font: var(--font-14-extrabold);
  color: var(--white);
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3a3a4a;
  }
}

.active {
  background-color: var(--background-3);
}