.howItWorks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 20px 25px;
  border-radius: 20px;
  margin-top: 30px;
  background: var(--background-2);

  .title {
    font: var(--font-20-bold);
    color: var(--foreground-3);
    margin-bottom: 20px;
  }
  .itemWrap {
    align-items: center;
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-column-gap: 12px;

    &:not(:last-child) {
      margin-bottom: 25px;
    }
    .number {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      background: var(--btn-hover-on-bg);
      border-radius: 50%;
      font: var(--font-12-extrabold);
      color: var(--foreground-3);
      margin-right: 20px;
    }
    .text {
      font: var(--font-12-semibold);
      color: var(--foreground-3);
      max-width: 210px;
    }
    .textLink {
      text-decoration: underline;
      text-decoration-style: dashed;
      opacity: .8;
      transition: opacity .15s ease;
      &:hover{
        opacity: 1;
      }
    }
  }
}
