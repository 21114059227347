.rightBar {
  max-width: 905px;
  width: 100%;
}

.top {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.header {
  display: flex;
  gap: 50px;
}

.title {
  font: var(--font-30-extrabold);
  color: var(--foreground-2);

  &:hover {
    color: var(--foreground-3);
  }

  &.active {
    color: var(--foreground-3);
    cursor: default;
  }
}
