.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  min-width: 450px;
  padding: 15px 20px;
  border-radius: 15px;
  background-color: var(--background-2);
}

.input {
  margin: 0;
  border: none;
  height: 50px;

  .label {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & > *:first-child {
      font: var(--font-14-semibold);
    }
  }
}