.leftBar {
  position: relative;
  width: 305px;
  min-width: 305px;
  display: flex;
  flex-direction: column;

  ._white {
    color: var(--white);
  }
}

.logoPartner {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.img {
  max-height: 100px;
}