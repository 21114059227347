.root {
  margin-top: 25px;
}

.input {
  margin-bottom: 20px;

  .label {
    margin-bottom: -10px;
  }
}

.button {
  width: 100%;
}