.footerLinks {
  display: inline-flex;
  align-items: center;
  a {
    font: var(--font-12-semibold);
    color: var(--foreground-2);
    position: relative;
    padding: 0 27px;
    cursor: pointer;

    &:before {
      padding-top: 5px;
      position: absolute;
      right: 0;
      content: '';
      width: 1px;
      height: 12px;
      background: var(--foreground-2);
      opacity: 0.5;
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      &:before {
        display: none;
      }
    }
  }
}
