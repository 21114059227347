@import "./src/styles/mixins";
.wrapper{
  border-radius: 25px;
  padding: 2px;
  box-sizing: border-box;
  width: 297px;
  height: 100%;
  &:last-child{
    margin-right: 0;
  }
  &.active{
    background:
    linear-gradient(90deg, #e571ff 0%, #FF8937 100%);
  }
  & h3, p {
    margin: 0;
  }
}
.innerBlock{
  border-radius: 25px;
  padding: 30px;
  height: 100%;

  &.active{
    background-color: var(--background-2);
  }
}

.button{
  width: 100%;
}

.actionBlock{
  height: 80px;
}

.actionText{
  color: var(--foreground-2);
  font-size: 12px;
}

.title{
  font-size: 30px;
  color: var(--white);
  padding-bottom: 15px;
}

.descriptionItem{
  padding-bottom: 25px;
  color:var(--white);

  .wrapper.active &.reqDocs {
    color: var(--foreground-2);
  }
}

.descriptionTitle{
  padding-bottom: 5px;
  font-size: 14px;
  color: var(--foreground-2);
}

.kycStatus{
  margin-top: 0;
}