.tickerList {
  width: 100%;
  min-height: 71px;
  border-left: 1px solid var(--foreground-1);
  padding-left: 14px;
}

.activeTicker {
  border-left: 1px solid var(--white);
}

.tickerListContainer {
  width: 100%;
  min-height: 71px;
  cursor: pointer;
}

.ticker {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
}

.tickerName {
  font: var(--font-14-medium);
  color: var(--foreground-2);
  line-height: 20px;
  position: relative;
  top: 1px;
}

.active {
  color: var(--white);
}

.market {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
  position: relative;
  top: 1px;
}

.icon {
  width: 16px;
  height: 16px;
}

.exchange {
  display: flex;
  gap: 4px;
  padding: 2px 5px 2px 2px;
  border-radius: 6px;
  border: 1px solid var(--foreground-1);
  align-items: center;
}

.deals {
  display: flex;
  gap: 15px;
}

.items {
  display: flex;
  gap: 4px;
  font: var(--font-12-semibold);
  line-height: 18px;
}

.sum {
  color: var(--white);
  white-space: nowrap;
}

.firstWrapper {
  padding-bottom: 5px;
}

.sumTitle {
  color: var(--foreground-2);
  white-space: nowrap;
}
