.button {
  display: flex;
  gap: 6px;
  align-items: center;

  color: var(--foreground-2);

  transition-property: color;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      color: var(--white);
    }
  }
}

.info {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 4px;
}

.text {
  font: var(--font-14-semibold);
  line-height: 17px;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  opacity: 1;

  transition-property: background-color, opacity;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
}

.disabled {
  opacity: 0.5;
}

.bybit {
  box-sizing: content-box;
  border-radius: 3px;
  border: 1px solid rgba(213, 213, 213, 0.1);
}

.white {
  color: var(--white);
}
