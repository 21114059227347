.component {
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
}

.title {
  margin: 30px 0 25px;
}

.listItems {
  position: relative;
}

