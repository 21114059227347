.rightBar {
  max-width: 905px;
  margin-top: 9px;
  width: 100%;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hideAssets {
  display: flex;
  align-items: center;
  gap: 6px;
  padding-left: 12px;
}

.checkbox {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  font: var(--font-14-semibold);
  line-height: 20px;
  color: var(--white);
  white-space: nowrap;
}