.root {
  padding: 40px 0 0;
  width: 100%;
}

.modal {
  position: fixed;
  top: 170px;
}

.scrollIndicator {
  height: 40px;
}

.td {
  &:nth-child(1) {
    font-size: 12px;
    white-space: nowrap;
  }

  &:nth-child(3) {
    white-space: nowrap;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px;
}

.inputContainer {
  margin: 0 !important;
  height: 40px;
  border-radius: 10px;
  background: var(--btn-hover);
  border: none;
  padding-left: 10px;
  gap: 5px;
  width: 360px;
  input {
    font: var(--font-14-bold) !important;
    &:hover {
      border: none;
      box-shadow: none;
    }
  }
  .inputError {
    position: absolute;
    top: 100%;
    left: 0;
  }
}

.dateRange {
  display: flex;
  align-items: center;
  color: white;
  gap: 10px;
}

.datesContainer {
  background-color: var(--background-3) !important;
  padding: 10px 5px 10px 15px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.dateRangeTitle {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
}

.calendarButton {
  padding: 10px !important;
}

.resetButton {
  margin-top: 10px;
  margin-left: 10px;
  text-decoration: underline;
  cursor: pointer;
}

.highligth {
  color: var(--green-text);
}

.tableContainer {
  overflow: auto;
  max-height: calc(80vh - 10rem);
}

.tableHead {
  position: sticky;
  top: 0;
  z-index: 2;
  opacity: 1;
  background-color: var(--background-2);
  margin-bottom: 10px;

  th {
    padding: 5px 0 5px 15px;
  }

  tr {
    border-bottom: 15px solid var(--background-1);
  }
}

.tfoot {
  tr {
    border-top: 15px solid var(--background-1);
  }
}
