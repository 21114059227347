.root {

}

.headingWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.heading {
  font: var(--font-30-extrabold);
  color: var(--white);
}