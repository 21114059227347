.root {
  position: relative;
  overflow: hidden;
}

.header {
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1.5fr 440px 1.5fr;
  gap: 40px;
  padding: 0 10px;
  font: var(--font-12-semibold);
  color: var(--foreground-2);

  & div {
    padding: 15px 20px;
  }
  & > *:last-child {
    text-align: right;
  }
}

.loader {
  margin: 60px auto;
}

.empty {
  margin-top: 10px;
  font: var(--font-20-bold);
  color: var(--foreground-1);
}
