.popupBackgroundWrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(8, 6, 16, 0.94);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: auto;
}

.popupContent {
  min-width: 380px;
  background: var(--background-2);
  border-radius: 40px;
  padding: 40px 50px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.mobile {
  &.popupBackgroundWrapper {
    padding: 20px;
    bottom: auto;
    right: auto;
    isolation: isolate;
  }

  .popupContent {
    max-width: 100%;
    min-width: auto;

    border-radius: 20px;
    overflow-y: auto;

    padding: 30px 20px;
  }
}
