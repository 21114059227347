.labelWithQuestion {
  display: flex;
  align-items: center;
  gap: 8px;

  .label {
    font: var(--font-14-medium);
    color: var(--foreground-2);
  }

  .labelLighter {
    color: var(--foreground-1);
  }
}
