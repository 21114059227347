.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 45px;
}

.title {
  font: var(--font-25-bold);
  color: var(--foreground-3);
  margin-bottom: 8px;
}

.label {
  font: var(--font-20-bold);
  color: var(--foreground-1);
  margin-bottom: 25px;
}

.image {
  width: 534px;
  height: 138px;
  object-fit: cover;
  object-position: center;
}
