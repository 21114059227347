.container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.root {
  padding-top: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1920px;
}
