@import 'src/styles/mixins';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  // margin-left: 20px;
  width: 100%;
  border: 1px solid #FFFFFF05;
  color: var(--white);
  cursor: pointer;
  border-radius: 15px;
  background-color: var(--background-3);

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }
}

.headerWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: var(--second-red-text);
  // z-index: 1;
  // box-shadow: 0 0 0 5.5px rgba(249, 112, 82, 0.2);
}

.title {
  font: var(--font-14-bold);
}

.description {
  font: var(--font-14-medium);
  color: var(--foreground-2);
  overflow: hidden;
  text-overflow: ellipsis;
}

.date {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
}

.toggleButton {
  font: var(--font-14-medium);
  background: var(--gradient-foreground);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;

  &:hover {
    background: var(--gradient-foreground-3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.expanded {
  overflow: visible;
}