@import './src/styles/mixins';

.exchangeCard {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 30px;
  width: 300px;
  background-color: var(--background-1);
  border-radius: 25px;
  color: var(--foreground-3);
  transition: background-color 0.2s ease;

  &::after,
  &::before {
    content: '';
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
    border-radius: 25px;
  }

  &::after {
    background-color: var(--background-2);
    z-index: -2;
  }

  &::before {
    opacity: 0;
    background-image: var(--gradient-foreground);
    z-index: -1;
    transition: opacity 0.2s ease;
  }
}

.gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at top right, rgba(229, 113, 255, 0.1) 20%, rgba(255, 137, 51, 0) 80%);
  border-radius: 25px;
}

.hovered {
  background-color: var(--background-2);

  &::before {
    opacity: 1;
  }
}

.cardHeader {
  display: flex;
  gap: 10px;
  align-items: center;
}

.cardIconWrapper {
  position: relative;
}

.cardAccentIcon {
  position: absolute;
  top: -5px;
  right: -5px;
}

.cardTitle {
  font: var(--font-25-bold);
}

.cardContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
}

.comissionWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.comissionPercent {
  font: var(--font-30-extrabold);
  white-space: nowrap;
}

.comissionPercentAccent {
  @include gradientText;
}

.item {
  @include flex-space-between;
}

.rowItem {
  position: relative;

  & ~ & {
    &::before {
      content: '';
      position: absolute;
      top: -10px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--background-3);
    }
  }
}

.button {
  width: 100%;
}

.fontSemiboldMedium {
  font: var(--font-14-semibold);
}

.fontSemiboldSmall {
  font: var(--font-12-semibold);
}

.fontBoldMedium {
  font: var(--font-14-bold);
  line-height: 20px;
}

.colorGray {
  color: var(--foreground-2);
}

.description {
  padding-top: 20px;
}

.backgroundTextWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  border-radius: 25px;
  z-index: 0;
}


.backgroundText {
  position: absolute;
  top: 10px;
  right: -32px;
  color: rgba(255, 255, 255, 0.05);
  z-index: 1;
  font: var(--font-family);
  font-size: 128px;
  font-weight: 800;
  line-height: 128px;
}