@import './src/styles/mixins';

.root {
  position: relative;
  display: flex;
  gap: 40px;
  margin-top: 20px;
  color: var(--foreground-2);
  font: var(--font-14-medium);
}

.left {
  position: sticky;
  top: 100px;
  flex: 0 0 300px;
  min-width: 300px;
  height: fit-content;

  .links {
    padding-left: 30px;
    border-left: 3px solid var(--background-3);
  }

  .link {
    position: relative;
    color: var(--foreground-2);
    display: block;
    padding: 5px 0;
    line-height: 20px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -34px;
      width: 5px;
      border-radius: 3px;
      background-color: var(--white);
      opacity: 0;
      transition: opacity 0.2s ease;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &:hover {
      color: var(--foreground-3);
    }

    &.active {
      color: var(--foreground-3);
      font-weight: bold;

      &::before {
        opacity: 1;
      }
    }
  }
}

.content {
  line-height: 20px;
  font-weight: 100;
  font-size: 14px;
  text-align: justify;

  [id] {
    scroll-margin-top: 110px;
  }

  .strong {
    color: var(--foreground-3);
    font-weight: bold;
  }

  .listTerms {
    padding: 20px 0;
    counter-reset: item;
    list-style-type: none;

    .listTerms {
      padding: 20px 0 20px 0;
    }

    .listItem {
      padding: 10px 0;
      position: relative;
      text-align: justify;
      counter-increment: item;

      &:before {
        content: counters(item, '.') '. ';
      }

      .listItem::before {
        width: 50px;
        display: inline-block;
        position: absolute;
      }

      .listItem {
        .listItemText {
          display: inline-block;
          margin-left: 45px;
        }
      }

      .header {
        display: inline;
        margin: 0;
      }
    }
  }

  & > .listTerms > .listItem {
    &:before {
      font: var(--font-20-bold);
      color: var(--foreground-3);
    }
  }

  .listNormal {
    padding: 20px 0;
    list-style-type: none;
    counter-reset: counter;

    .listItem:before {
      content: counters(counter, '.') '. ';
      counter-increment: counter;
      width: 36px;
      padding-right: 0.5rem;
      display: inline-block;
    }

    .listItem {
      padding: 5px 0;
    }
  }

  .link {
    font-weight: bold;
  }

  .listPolicy {
    padding: 0 0 20px;
    counter-reset: item;
    list-style-type: none;

    ol {
      padding: 0 0 0 20px;

      li {
        margin: 10px 0;
        padding-left: 20px;
      }
    }

    & > .listItem {
      padding: 10px 0;
      position: relative;
      text-align: justify;

      &:before {
        content: counters(item, '.') '. ';
        counter-increment: item;
        position: absolute;
        width: 35px;
        display: inline-block;
      }

      .listItemText {
        display: inline-block;
        margin-left: 35px;
      }
    }
  }

  .listDash {
    padding-left: 10px;
    list-style-type: '-';

    & > .listItem {
      position: relative;
      padding: 10px 0 10px 10px;
    }
  }

  .table {
    width: 100%;
    border-collapse: collapse;

    & > tr > td,
    & > tr > th {
      vertical-align: top;
      border-bottom: 1px solid var(--btn-hover-on-bg);
      padding: 20px 20px 20px 0;
      text-align: left;
      width: 33.333%;

      & > p {
        padding: 0;
        margin: 0;
      }

      & > .list {
        list-style-type: none;
        counter-reset: item;

        & > .listItem {
          padding: 10px 0;
          position: relative;

          & > .listItemText {
            margin-left: 20px;
          }

          &:before {
            content: counter(item, lower-alpha) '. ';
            counter-increment: item;
            position: absolute;
            width: 35px;
            display: inline-block;
          }
        }
      }
    }

    & > tr > th {
      vertical-align: middle;
      color: var(--foreground-3);
      font-weight: bold;
    }
  }
}

.header {
  display: block;
  margin-top: 60px;
  font: var(--font-20-bold);
  text-transform: uppercase;
}

.title {
  font: var(--font-40-extrabold);
  color: var(--foreground-3);
}
