.cashbackRightBarListHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px 0 20px;
  .label {
    font: var(--font-25-extrabold);
    color: var(--foreground-3);
  }
  .total {
    font: var(--font-14-bold);
    color: var(--foreground-2);
    strong {
      font: var(--font-14-bold);
      color: var(--foreground-3);
    }
  }
}
.empty {
  @extend .cashbackRightBarListHeader;
  opacity: 0.2;
}
