.stickySection {
  position: sticky;
  top: 70px;
  z-index: 100;

  display: flex;
  flex-direction: column;
  gap: 30px;

  background-color: var(--background-1);
  padding: 30px 0 20px;
  height: 155px;
}

.mobileTabs {
  display: flex;
  gap: 20px;

  .tab {
    font: var(--font-20-extrabold);
    color: var(--foreground-1);
    cursor: pointer;
    display: flex;
    gap: 5px;
    align-items: flex-start;
  }

  .active {
    color: var(--white);
    cursor: default;
  }
}

[data-amount]::after {
  content: attr(data-amount);
  display: block;
  font: var(--font-14-extrabold);
}
