.itemWrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  cursor: pointer;
  
  .left {
    display: flex;

    .label {
      color: var(--foreground-3);
      font: var(--font-14-bold);
      margin-right: 5px;
    }

    .total {
      vertical-align: baseline;
      font: var(--font-14-medium);
      color: var(--foreground-2);
    }
  }

  .amount {
    color: var(--foreground-2);
    text-transform: uppercase;
    font: var(--font-14-medium);
    margin-right: 5px;

    &.active {
      color: var(--foreground-3);
    }
  }

  span:last-child {
    color: var(--foreground-2);
    text-transform: uppercase;
    font: var(--font-14-medium);
  }
}
