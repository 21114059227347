.root {
  position: relative;
  display: flex;
  cursor: pointer;
}

.input {
  position: absolute;
  left: -25px;
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  &:checked + .icon {
    border-color: var(--green-text);
    background-color: var(--green-text);

    &::before {
      opacity: 1;
    }
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-content: center;
  flex: 0 0 auto;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1.5px solid var(--foreground-2);
  transition: all .2s ease;

  &::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    margin: auto;
    border-radius: 50%;
    background-color: var(--white);
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .25);
    opacity: 0;
    transition: opacity .2s var(--transition-cubic);
  }
}
