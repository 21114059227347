.noCoins {
  display: flex;
  align-items: center;
  .label {
    font: var(--font-20-bold);
    color: var(--foreground-3);
    margin-right: 10px;
    white-space: nowrap;
  }
  .hint {
    width: 270px;
    left: 37px;
  }
  .questionButton {
    border-radius: 8.75px;
      width: 25px;
      height: 25px;
    svg {
      height: 12px;
      width: 12px;
    }

  }
}
.noCoinsDisabled {
  @extend .noCoins;
  opacity: 0.2;
  pointer-events: none;
}

