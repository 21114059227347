@import "src/styles/mixins";

.component {
  color: var(--foreground-3);
  background-color: var(--background-2);
  border-radius: 10px;
  padding: 10px 16px;
  width: fit-content;
  display: inline-block;
}

.inner {
  display: inline-flex;
  align-items: center;
  justify-items: center;
  gap: 16px;
}

.close {
  cursor: pointer;
}

