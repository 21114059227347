.rightBar {
  max-width: 905px;
  margin-top: 60px;
  width: 100%;
}

.top {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
