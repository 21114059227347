.content {
  margin: 0 auto;
  padding-top: 50px;
  max-width: 460px;
  width: 100%;
}

.loaderWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader {
  margin-bottom: 15px;
}
