.mainTabCryptocurrency {
  display: flex;
  align-items: center;
  gap: 10px;

  .viewAssets {
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: var(--gradient-foreground);
    font: var(--font-14-extrabold);
    white-space: nowrap;
    cursor: pointer;
  }
}
