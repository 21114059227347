@import './src/styles/mixins';

.label {
  font: var(--font-17-bold);

  .gradient {
    color: transparent;
    @include gradientText;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  overflow: hidden;

  margin-bottom: 30px;

  .title {
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .close {
    flex-shrink: 0;
  }
}

.description {
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 100%;
  text-align: left;
  margin-bottom: 30px;
  font: var(--font-14-medium);
  color: var(--foreground-2);

  .gradient {
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: var(--gradient-foreground);
    margin: 0;
  }

  .link {
    color: transparent;
    border-bottom: 1px solid var(--second-red-text);
  }
}

.additional {
  display: flex;
  align-items: center;

  margin: 20px 0 0;
  padding: 20px;

  font: var(--font-14-semibold);
  line-height: 20px;
  border-radius: 25px;
  background: var(--background-3);
  color: var(--white);
}

.additionalImage {
  margin: 0 20px 0 0;
  flex-basis: 40px;
  * {
    display: block;
  }
}

.button {
  width: 100%;
}

.mobile {
  &.label {
    font: var(--font-12-semibold);
  }
}

.colored {
  color: var(--white);
}

.gradientText {
  @include gradientText;
}

.whiteText {
  color: var(--white);
}

.subheading {
  display: block;
  font: var(--font-14-semibold);
  color: #000;
  color: var(--foreground-2);
}

.subDescription {
  font-style: italic;
}

.modalOkx {
  .header {
    text-align: left;
  }
  .description {
    margin: 0;
  }
  .cta {
    margin-top: 20px;
  }
}

.widgetOkx{
  display: flex;
  align-items: center;
  color: var(--white);
  font-weight: 600;
}

.widgetOkxIcon {
  margin-right: 14px;
}

.modalButtonOkx {
  background: var(--foreground-1);
  &.modalButtonOkx:hover {
  background: var(--foreground-2);
  }
}