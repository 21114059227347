.createAddress {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .coinSelect {
      width: 50%;
    }
    .networkSelect {
      width: 50%;
      display: flex;
      min-width: 290px;
      position: relative;
      margin-left: 20px;
    }
  }
}

.buttonsWrapper {
  margin-top: 35px;
}

.loader {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  width: 100%;
  margin-top: 20px;
}
