.wrapper {
  width: 100%;
  box-sizing: border-box;
  color: var(--foreground-2);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  color: var(--white);
  background: var(--background-3);
  cursor: pointer;
}

.description {
  font: var(--font-17-semibold);
  padding: 12px 0 30px 0;
  text-align: start;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 400px;
  overflow-y: auto;
}