.chooseExchange {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.title {
  margin: 40px 0 40px;
  @media screen and (max-width: 768px) {
    text-align: center;
  }
}

.cardList {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 0;
  list-style: none;
  flex-wrap: wrap;
}

.message {
  padding-bottom: 40px;
}

.postScriptum {
  margin-top: 50px;
  font: var(--font-14-medium);
  line-height: 20px;
  color: var(--foreground-2);
}
