.grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: minmax(80px, auto);
  grid-gap: 14px;
  padding-bottom: 120px;
}

.smallBox {
  grid-column: span 1;
  grid-row: span 1;
}

.mediumBox {
  grid-column: span 1;
  grid-row: span 2;
}

.chartBox {
  grid-column: span 3;
  min-height: 383px;
  max-height: 383px;
}