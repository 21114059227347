.supportMessage {
  display: flex;
  flex-direction: column;
  font: var(--font-14-medium);
  color: var(--foreground-2);

  &:hover span:last-child {
    color: var(--foreground-3);
  }
}
