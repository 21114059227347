.tabContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tabHeaders {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-bottom: 20px;
}

.tabButton {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font: var(--font-20-bold);
  color: var(--foreground-2);
  font-size: 30px;

  &:hover {
    color: var(--foreground-2);
  }

  &.active {
    color: var(--white);

    .counter {
      background-color: rgba(249, 112, 82, 0.2);
      color: var(--second-red-text)
    }
  }
}

.counterWrap {
  width: 24px;
  height: 24px;

  .counter {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(103, 101, 115, 0.2);
    padding-top: 2px;

    font: var(--font-14-bold);
  }
}