@import "./src/styles/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 440px;
  height: 330px;
  border-radius: 20px;
  padding: 35px 26px;
  box-sizing: border-box;
  background-color: rgba(18, 16, 26, 1);
  background-image: radial-gradient(92.57% 111.92% at -3.13% -11.92%, rgba(229, 113, 255, 0.15) 0%, rgba(229, 113, 255, 0) 80%),
    radial-gradient(92.42% 111.92% at 104.88% 111.92%, rgba(255, 137, 51, 0.15) 0%, rgba(255, 137, 51, 0) 80%);
}

.header {
  padding-bottom: 15px;
}

.manager {
  width: 440px;
  height: 330px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(18, 16, 26, 1);
  border-radius: 20px;
  background-image: radial-gradient(92.57% 111.92% at -3.13% -11.92%, rgba(229, 113, 255, 0.15) 0%, rgba(229, 113, 255, 0) 80%),
    radial-gradient(92.42% 111.92% at 104.88% 111.92%, rgba(255, 137, 51, 0.15) 0%, rgba(255, 137, 51, 0) 80%);
}