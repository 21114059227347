//TODO: Remove custom loaders and animations, add common Skeleton component

.skeletonCashback {
  display: flex;
  overflow: hidden;
  position: relative;
  .skeletonCashbackLeftBar {
    width: 305px;
    min-width: 305px;
    display: flex;
    flex-direction: column;

    .label {
      font: var(--font-30-extrabold);
      color: rgba(250, 250, 250, 0.2);
      padding: 9px 0 30px;
      background: var(--background-1);
      position: relative;
      z-index: 5;
    }
    .wrap {
      .items {
        overflow: hidden;
        display: grid;
        grid-template-columns: 1fr 1fr;
        position: relative;
        z-index: 1;

        .itemWrap {
          overflow: hidden;
          display: flex;
          padding-bottom: 5px;
          .text {
            font: var(--font-14-medium);
            color: var(--foreground-2);
            margin-top: -3px;
          }
          .item {
            border-radius: 9px;
            background: var(--background-2);
            margin-bottom: 15px;
            margin-top: 5px;
            height: 11px;
          }
          &:nth-child(2) {
            justify-content: flex-end;
            .item {
              width: 89px;
            }
          }
          &:nth-child(4) {
            justify-content: flex-end;
            .item {
              width: 36px;
            }
          }
        }
      }
      .itemWrapVolume {
        overflow: hidden;
        position: relative;
        z-index: 40;
        padding-bottom: 15px;
        .text {
          font: var(--font-14-medium);
          color: var(--foreground-2);
          margin-top: -3px;
        }
      }
      .itemWrapBottom {
        overflow: hidden;
        position: relative;
        z-index: 10;
        .item {
          border-radius: 9px;
          background: var(--background-2);
          margin-bottom: 15px;
          margin-top: 5px;
          height: 11px;
        }
      }
    }
    .bottomBlock {
      height: 100%;
      background: var(--background-1);
      position: relative;
      z-index: 10;
    }
  }
  .between {
    position: relative;
    z-index: 6;
    max-width: 70px;
    width: 100%;
    background: var(--background-1);
    display: flex;
    flex: 1 0 auto;
  }
  .skeletonCashbackRightBar {
    max-width: 905px;
    width: 100%;
    .top {
      position: relative;
      z-index: 5;
      .topItems {
        display: flex;
        justify-content: space-between;
        .leftPart {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .itemWrapper {
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            .item {
              width: 403px;
              border-radius: 9px;
              background: var(--background-2);
              height: 27px;
            }
            &:last-child {
              padding-top: 20px;
              .item {
                width: 195px;
                height: 19px;
              }
            }
          }
        }
        .center {
          flex-basis: 100%;
          height: auto;
        }
        .rightPart {
          overflow: hidden;
          display: flex;
          justify-content: flex-end;
          width: 100%;
          .itemWrap {
            height: 100%;
            padding-left: 7px;
            .item {
              width: 147px;
              height: 40px;
              border-radius: 10px;
              background: var(--background-2);
            }
          }
        }
      }
    }
    .middle {
      height: 45px;
      width: 100%;
      background: var(--background-1);
      position: relative;
      z-index: 10;
    }
    .bottom {
      overflow: hidden;
      .title {
        font: var(--font-25-extrabold);
        color: var(--foreground-1);
        background: var(--background-1);
        position: relative;
        z-index: 10;
        padding-bottom: 23.5px;
      }
      .bottomContent {
        overflow: hidden;
        z-index: 10;
        position: relative;
        &:not(:last-child) {
          padding-bottom: 10px;
        }

        .blockWrap {
          border-radius: 15px;
          padding: 20px;
          background: var(--background-2);
          display: grid;
          grid-template-columns: 2fr 1fr 1fr 15% auto;
          justify-content: space-between;
          align-items: center;
          position: relative;
          z-index: 5;

          .left {
            display: flex;
            .circle {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background: var(--background-3);
              margin-right: 20px;
            }
            .itemWrapper {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .item {
                width: 179px;
                height: 15px;
                border-radius: 9px;
                background: var(--background-3);
                &:last-child {
                  width: 103px;
                  height: 11px;
                }
              }
            }
          }
          .itemWrap {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .item {
              width: 103px;
              height: 15px;
              border-radius: 9px;
              background: var(--background-3);
              &:last-child {
                width: 45px;
                height: 11px;
              }
            }

            &:last-child {
              align-items: flex-end;
              .item {
                width: 137px;
                height: 15px;
                border-radius: 9px;
                background: var(--background-3);
                &:last-child {
                  width: 50px;
                  height: 11px;
                }
              }
            }
          }
        }
      }
    }
  }

  .animatable {
    overflow: hidden;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: var(--btn-hover);
      animation: animate 2s linear infinite;
      transform: skewX(-10deg);
      z-index: 1;
    }
  }

  // &:before {
  //   content: '';
  //   position: absolute;
  //   width: 30px;
  //   height: 700px;
  //   top: 0;
  //   left: 0;
  //   background: #1B1824;
  //   box-shadow: 0 0 50px 50px #1B1824;
  //   animation: animate 2.2s linear infinite;
  //   z-index: 1;
  // }
}

@keyframes animate {
  0% {
    background: var(--background-1);
    // left: calc(-100% + 20px);
  }
  30% {
    background: var(--btn-hover);
    // left: calc(100% + 20px);
  }
  80% {
    background: var(--background-2);
    // left: calc(100% + 20px);
  }
  100% {
    background: var(--background-1);
    // left: calc(100% + 20px);
  }
}
