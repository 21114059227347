.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px;
  border-radius: 20px;
  color: var(--foreground-3);
  gap: 20px;
}

.banner {
  padding: 20px;
  width: 100%;
  background: var(--background-2);
  border-radius: 15px;
}

.titleText {
  margin-bottom: 10px;
  font: var(--font-20-bold);
}

.text {
  color: var(--foreground-2);
  font: var(--font-14-medium);
}

.textWhite {
  color: var(--foreground-3);
}

.textGradient {
  background-image: var(--gradient-foreground);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font: var(--font-20-bold);
}

.comingSoon {
  margin-top: 10px;
  background-image: var(--gradient-foreground);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
