@import 'src/styles/mixins';

.cryptocurrency {
  display: flex;
  align-items: flex-start;
  .icon {
    &:not(:first-child) {
      margin-left: -11px;
    }
    svg {
      border: 2px solid var(--background-1);
      border-radius: 50%;
      width: 35px;
      height: 35px;
    }
  }
  .coinsCount {
    @include flex-center;
    border: 2px solid var(--background-1);
    background: var(--background-3);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin-left: -11px;
    font: var(--font-12-extrabold);
    color: var(--foreground-3);
  }
}
.cryptocurrencyBig {
  @extend .cryptocurrency;
  .icon {
    svg {
      border: 2px solid var(--background-2);
      width: 60px;
      height: 60px;
    }
  }
  .coinsCount {
    width: 60px;
    height: 60px;
    border: 2px solid var(--background-2);
    font: var(--font-14-extrabold);
  }
}
