.contentWrapper{
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.content{
  padding-top: 50px;
  width: calc(100% - 300px);
}

.list{
  color: var(--foreground-2);
  font-size: 14px;
  padding-left: 0;
  margin: 0;
  list-style: none;
  padding-top: 30px;
  max-width: 662px;
  img{
    max-width: 650px;
    margin: 15px 0 30px 0;
  }
}

.listTextWrapper{
  display: flex;
  align-items: center;
}
.listCount{
 width: 30px;
}

.listText{
  font-size: 17px;
  color: var(--foreground-3);
  padding-left: 10px;
}