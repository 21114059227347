.content {
  display: flex;
  gap: 20px;
}

.vertical {
  flex-direction: column;
}

.wrapper {
  overflow: hidden;
}
