.root {
  width: 360px;
}

.icon {
  margin-bottom: 15px !important;
}

.text {
  font: var(--font-17-semibold) !important;

  .accent {
    color: var(--foreground-3);
  }
}