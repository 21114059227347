.modal{
  align-items: baseline;
  border-radius: 30px;
  padding: 40px;
  text-align: left;
  font-size: 14px;
  color: var(--foreground-2);
  font-weight: 500;
  line-height: 20px;
  width: 500px;
  max-width: inherit !important; //todo: need to change it
}

.selectedText{
  color: var(--foreground-3)
}

.ofertaTextWrapper{
  text-transform: uppercase;
  height: 361px;
  overflow-y: scroll;
  h3, h4{
    color: var(--foreground-3);
  }
}

.list{
  list-style-type: none;
  padding-left: 0;
  &.offset{
    padding-left: 10px;
  }
}

.list, .markedList{
  & li {
    padding-bottom: 10px;
  }
}

.markedList{
  list-style-type: upper-latin;
}

// form

.formWrapper{
  padding-top: 30px;
  width: 100%;
}
.checkboxItem{
  margin-bottom: 10px;
}