.root {
  display: flex;
  align-items: center;
  gap: 8px;
  font: var(--font-12-semibold);
  color: var(--foreground-2);
}

.statusIcon {
  --foreground: var(--white);
  color: var(--white);
}