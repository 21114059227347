@import 'src/styles/mixins';

.root {
  text-align: center;
  max-width: 400px;
}

.checkmark {
  width: 60px;
  height: 60px;
}

.header {
  font: var(--font-25-bold);
  color: var(--foreground-3);
  margin-bottom: 10px;
}

.text {
  font: var(--font-14-medium);
  color: var(--foreground-2);
  margin-bottom: 30px;
}

.button {
  margin: 0 auto;
  width: 300px;
  margin-bottom: 15px;
}

.mobile {
  .button {
    width: 100%;
  }
}
