@import 'src/styles/mixins';

.withoutBg {
  background-color: var(--background-1);
}

.headerBackgroundWrap {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.headerBackground {
  @include background;
  position: absolute;
  top: 0;
  right: 0;
  left: 50%;
  transform: translateX(-50vw);
  overflow: hidden;
  width: 100vw;
  height: 360px;
  z-index: -1;

  &:before {
    position: absolute;
    bottom: 0;
  }
}

.headerMobileBackground {
  @include background($is-mobile: true);
}

.headerDesktopInner {
  padding: 20px 0;
  position: relative;
}

.headerDesktopContent {
  position: relative;
  &:not(:first-child) {
    margin-top: 20px;
  }
}

.headerDesktopContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
}

.header {
  margin: 0 auto;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;

  &::before {
    right: 10px; // prevent overflow scrollbar in chrome
  }

  .arrowBackWrap {
    position: absolute;
    left: -48px;
    top: 50%;
    transform: translateY(-50%);
    width: 28px;
    height: 28px;
    border-radius: 50% 4px 4px 50%;
  }
}

.arrow {
  width: 6px;
}

.left {
  display: flex;
  align-items: center;
  margin-right: 25px;

  svg {
    display: block;
  }
}

.right {
  display: flex;
  gap: 10px;
}

.mobile {
  &.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 auto;

    height: var(--mobile-header-height);
    padding: 20px 0 10px;

    .arrowBackWrap {
      margin-right: 12px;
      position: static;
      left: auto;
      top: auto;
      transform: none;
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .link {
    display: flex;
  }
}
