.balanceSheet {
  margin: 0 0 25px; /* TODO: remove margin when progress bar is visible */
}

.itemWrap {
  &:not(:first-child) {
    margin: 15px 0 0;
  }
}

.item {
  display: flex;
  align-items: center;
  gap: 4px;
  font: var(--font-14-medium);
  color: var(--foreground-2);
}

.value {
  margin-left: auto;
  padding-left: 10px;
  font: var(--font-14-bold);
  color: var(--foreground-3);
  white-space: nowrap;
}

.currency {
  margin-left: 4px;
  color: var(--foreground-2);
}

.colorRed {
  color: var(--red-text);
}

.colorGreen {
  color: var(--green-text);
}

.profit {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
  display: flex;
  justify-content: space-between;

  &._reset {
    align-items: center;
  }
}

.reset {
  cursor: pointer;
  text-decoration: underline;
}