.root {
  text-align: center;
  max-width: 320px;
  width: 100%;
  padding: 10px;

  .gradientText {
    color: transparent;
    -webkit-background-clip: text;
    background-image: var(--gradient-foreground);
  }
  .header {
    font: var(--font-25-bold);
    color: var(--foreground-3);
    margin-bottom: 10px;
  }

  .headerUnlimited {
    margin-bottom: 30px;
  }

  .text {
    font: var(--font-14-medium);
    color: var(--foreground-2);
    margin-bottom: 30px;
  }

  .button {
    margin: 0 auto;
    width: 300px;
    margin-bottom: 15px;
  }

  .returnButton {
    margin-bottom: 0 !important;
  }
}

.mobile.root {
  padding: 0;

  .button {
    width: 100%;
    margin-bottom: 10px;
  }
}
