.root {
  margin: 40px 0;
}

.text {
  margin-top: 5px;
  font: var(--font-14-semibold);
  color: var(--foreground-2);
}

.input {
  margin-top: 30px;
}

.button {
  margin-top: 40px;
  width: 100%;
}