.rightBar {
  width: 905px;
}

.header {
  display: flex;
  margin:  0 0 40px;
  .icon {
    margin: 0 20px 0 0;
  }
}

.img{
  width: 50px;
  height: 50px;
}