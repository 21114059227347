@import "./src/styles/mixins";

.status {
  display: flex;
  align-items: center;
}

.svg {
  margin-right: 5px;
}

.statusCompleted {
  color: var(--green-text);
  font: var(--font-12-semibold);
}

.statusPending {
  color: var(--foreground-2);
  font: var(--font-12-semibold);
}

.statusFailed {
  color: var(--second-red-text);
  font: var(--font-12-semibold);
}

.wrapLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}