@import "./src/styles/mixins";

.modal {
  min-width: 500px;
}

.root {
  font: var(--font-14-medium);
  width: 420px;
  max-width: 100%;
  text-align: left;
}

.heading {
  margin: 0 0 30px;
  font: var(--font-20-bold);
  display: flex;
}

.selectToggle {
  font: var(--font-17-semibold);
}

.selectLabel {
  color: var(--foreground-2);
}

.selectDropdown {
  width: 100%;
}

.closeControl {
  width: 30px;
  height: 30px;
  margin-left: auto;
  background: var(--background-3);

  &:hover {
    background: var(--btn-hover-on-bg);
  }

  svg {
    width: 8px;
    height: 8px;
  }
}

.submitLine {
  margin-top: 30px;
}

.btnWide {
  width: 100%;
}



.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.checkboxItem {
  display: flex;
  align-items: center;
}

.availableTrading {
  display: flex;
  gap: 12px;
  align-items: center;
}

.description {
  font: var(--font-14-medium);
  margin-bottom: 20px;
  color: var(--foreground-3);
}