@import "./src/styles/mixins";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  width: 100%;
}

.contentWrapp {
  width: 100%;
  max-width: 500px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 40px;
}

.selectWrapp {
  display: flex;
  align-items: center;
  gap: 8px;
}

.market {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
  font: var(--font-25-bold);
}

.wallet {
  font: var(--font-20-bold);
  padding-top: 5px;
}

.btn {
  display: flex;
  width: 100%;
}

.changeWrapp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.changeBtn {
  cursor: pointer;
  color: var(--foreground-2);

  &:hover {
    color: var(--white);
  }

  &:disabled {
    color: var(--foreground-2);
    cursor: not-allowed;
  }
}

.label {
  font: var(--font-20-bold);
}

.maxBtn {
  height: 30px;
  padding: 0 10px;
  font: var(--font-12-semibold);
}