.wrap {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 35px;
  .percentageItem {
    display: grid;
    grid-template-columns: 115px 1fr;
    align-items: baseline;
    span {
      font: var(--font-20-bold);
      color: var(--foreground-2);
      &:first-child {
        font: var(--font-25-extrabold);
      }
    }
  }
  .activePercentageItem {
    span {
      color: var(--foreground-3);
    }
  }
}
