.wrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;

  width: 500px;
  background: var(--background-2);
  border-radius: 30px;
  padding: 40px;
}

.title {
  font: var(--font-20-bold);
}

.description {
  display: flex;
  flex-direction: column;
  gap: 10px;

  font: var(--font-14-medium);
  color: var(--white);
  line-height: 20px;
}

.link {
  border-bottom: 1px solid var(--second-red-text);
}

.info {
  font: var(--font-14-medium);
  line-height: 20px;
  color: var(--foreground-2);
}

.additional {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;

  padding: 20px;

  font: var(--font-14-semibold);
  line-height: 20px;
  border-radius: 20px;
  background: var(--background-3);
  color: var(--white);
}

.additionalImage {
  flex-basis: 40px;

  * {
    display: block;
  }
}

.gradient {
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: var(--gradient-foreground);
}

.mobile.wrapper {
  max-width: 100%;
  width: auto;

  .popupContent {
    max-width: 100%;
    min-width: auto;

    border-radius: 20px;
    overflow-y: auto;

    padding: 30px 20px;
  }
}
