@import './src/styles/mixins';

.colorWhite {
  color: var(--white);
  margin: 0;
}

.strong {
  font-weight: bold;
}

.colorGradient {
  @include gradientText;
}

.textLeft {
  text-align: left;
}

.okButton {
  max-width: none !important;
}