.wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 54px;
  border-top: 1px solid #1C1A24;
  padding: 0 15px;
}

.header {
  display: flex;
  width: 100%;
  padding-bottom: 10px;
  font: var(--font-12-semibold);
}

.title {
  padding-bottom: 10px;
}

.description {
  font: var(--font-17-semibold);
  padding: 12px 0 32px 0;
}

.symbol {
  display: flex;
  align-items: center;
  gap: 4px;
  font: var(--font-12-semibold);
  color: var(--white);
  width: 160px;
  text-align: start;
}

.amount {
  font: var(--font-12-semibold);
  color: var(--white);
  width: 125px;
  text-align: start;
}

.margin {
  font: var(--font-12-semibold);
  width: 120px;
  text-align: right;
}

.setting {
  cursor: pointer;
}

.negative {
  color: #F97052;
}