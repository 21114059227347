.address {
  height: 100%;
  padding: 40px 0 76px;
  width: 100%;

  &_title {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    padding-bottom: 40px;
  }

  &_searchBlock {
    display: flex;
    justify-content: space-between;
  }
}
