.root {
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  width: 100%;
}

.header {
  display: flex;
  gap: 40px;
}

.title {
  color: var(--foreground-2);
  font: var(--font-40-extrabold);

  &:hover {
    color: var(--foreground-3);
  }

  &.active {
    color: var(--foreground-3);
    cursor: default;
  }
}
