.widget {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.statistics {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.labelWrap {
  display: flex;
  gap: 10px;
}

.amount {
  font: var(--font-14-bold);
  color: var(--foreground-3);
}

.symbol {
  color: var(--foreground-2);
}

.pnlSince {
  display: flex;
  align-items: center;
  color: white;
  gap: 10px;

  .leftPart {
    display: flex;
    align-items: center;
    gap: 5px;

    .title {
      font: var(--font-14-medium);
      color: var(--foreground-2);
      white-space: nowrap;
    }

    .reset {
      font: var(--font-12-semibold);
      color: var(--foreground-1);
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .rightPart {
    font: var(--font-14-bold);
    white-space: nowrap;
  }

  .colorRed {
    color: var(--red-text);
  }

  .colorGreen {
    color: var(--green-text);
  }
}
