.root {
  position: absolute;
  top: calc(100% - 10px);
  right: -20px;
  border: 20px solid transparent;
  opacity: 0;
  transform: translateY(-5px);
  pointer-events: none;
  transition: all 0.2s ease;
}

.wrap {
  width: 250px;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--background-2);
  overflow: hidden;
}

.info,
.action {
  display: grid;
  grid-template-columns: 40px auto;
  grid-column-gap: 10px;
  align-items: center;
}

.infoText {
  overflow: hidden;
}

.title {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
}

.email {
  margin-top: 2px;
  font: var(--font-14-bold);
  color: var(--white);
  overflow: hidden;
  text-overflow: ellipsis;
}

.actions {
  margin-top: 20px;
}

.action {
  width: 100%;
  color: var(--white);
  font: var(--font-14-bold);
  line-height: 20px;

  cursor: pointer;
  transition: color 0.2s ease;

  &:not(:first-child) {
    margin-top: 15px;
  }

  &:last-child {
    margin-top: 0;
  }

  &:hover {
    color: var(--gray-5);
  }
}

.separator {
  margin: 15px 0;
  height: 3px;
  border-radius: 3px;
  background-color: var(--background-3);
}

.logoutPopup {
  width: 300px;
}

.icon {
  // display: block;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 20px;
  }
}

.iconEnlarged {
  svg {
    width: 30px;
  }
}

.okxWidget {
  padding: 15px;
}

.img{
  width: 30px;
  height: 30px;
}
