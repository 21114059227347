@import "@/styles/mixins";

.heading {
  margin: 5px 0 25px;
  font: var(--font-17-bold);
  color: var(--white);
}

.headingLabel {
  margin-left: auto;
}

.subheading {
  font: var(--font-12-semibold);
  line-height: 17px;
  text-transform: uppercase;
  margin: 0 0 15px;
}

.item {
  &+.item {
    margin-top: 20px;
  }
}

.itemHeading {
  margin: 0 0 10px;
  display: flex;
  align-items: center;
  font: var(--font-17-bold);
  color: var(--white);
}

.itemHeadingNumber {
  margin: 0 10px 0 0;
  display: block;
  width: 30px;
  height: 30px;
  padding: 5px 5px 3px;
  text-align: center;
  font: var(--font-14-bold);
  background: var(--background-3);
  border-radius: 50%;
}

.itemHeadingLabel {
  display: block;
  margin-left: auto;
  font: var(--font-12-extrabold);
  text-transform: uppercase;
  @include gradientText()
}

strong {
  color: var(--white);
}

._white {
  color: var(--white);
}

._gray {
  color: var(--foreground-2);
}

._nowrap {
  white-space: nowrap;
}

.single {
  .heading {
    font: var(--font-20-bold);
    margin: 0 0 10px;
  }
}

.button {
  width: 100%;
  margin-top: 20px;
}