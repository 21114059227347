.card {
  display: flex;
  flex-direction: column;
  gap: 5px;

  background-color: var(--background-3);
  padding: 8px 15px 0;
  border-radius: 10px;
  user-select: none;
}

.topLine {
  display: flex;
  align-items: center;
  gap: 10px;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;
  overflow: hidden;
}

.topDescription {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;

  .wrapper {
    display: flex;
    gap: 7px;
    align-items: center;
  }
}

.currency,
.side {
  font: var(--font-14-extrabold);
  line-height: 1.4;
}

.currency {
  color: var(--white);
}

.side,
.timestamp {
  color: var(--foreground-2);
}

.timestamp {
  font: var(--font-12-extrabold);
}

.table {
  table-layout: fixed;
  width: 100%;
  border-spacing: 0 5px;

  th {
    padding: 1px 0;
  }
}

.cell {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: nowrap;

  overflow: hidden;
  font: var(--font-12-semibold);
  color: var(--foreground-2);

  .title {
    white-space: nowrap;
  }

  .value {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.expand {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  width: 100%;
  height: 22px;
  margin: 5px 0;
}

.circle {
  width: 4px;
  height: 4px;
  background-color: var(--foreground-2);
  border-radius: 50%;
}

.active {
  & > .circle {
    background-color: var(--white);
  }
}

.green {
  color: var(--green-text);
}

.red {
  color: var(--red-text);
}

.white {
  color: var(--white);
}
