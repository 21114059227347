.root {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.header {
  font: var(--font-20-bold);
  color: var(--foreground-3);
  margin-bottom: 5px;
  text-align: center;
}

.text {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
  margin-bottom: 20px;
  text-align: center;
}

.codesWrapper {
  display: inline-block;
  margin: 0 auto;
}

.codes {
  display: flex;
  gap: 5px;

  & :nth-child(4) {
    margin-left: 13px;
  }
}

.mobile.root {
  .header {
    margin: 30px 0 2px;
  }

  .text {
    line-height: 17px;
  }

  .hint {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    margin-top: 20px;

    font: var(--font-12-semibold);
    color: var(--white);
    line-height: 17px;
  }
}
