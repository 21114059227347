@import 'src/styles/mixins';

.root {
  text-align: center;
  max-width: 400px;
  margin: 100px auto;

  .header {
    font: var(--font-25-bold);
    color: var(--foreground-3);
    margin-bottom: 10px;
  }

  .text {
    font: var(--font-14-medium);
    color: var(--foreground-2);
    margin-bottom: 30px;
  }

  .button {
    margin: 0 auto;
    width: 300px;
    margin-bottom: 15px;
  }

  .supportMessage {
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    font: var(--font-14-medium);
    color: var(--foreground-2);
    &:hover span:last-child {
      color: var(--foreground-3);
    }
  }
}

.mobile.root {
  max-width: none;
  margin: 0;
  width: 100%;

  .header {
    font: var(--font-20-bold);
    color: var(--foreground-3);
    margin-bottom: 10px;
  }

  .button {
    width: 100%;
    margin-bottom: 10px;
  }
}
