.transfer {
  width: 200px;

  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .instrument {
      font: var(--font-14-medium);
      color: var(--foreground-3);
      line-height: 20px;
    }

    .toggleBtn {
      --transfer-color: var(--foreground-3);
      width: 30px;
      padding: 0;
    }
  }

  .available {
    font: var(--font-14-medium);
    color: var(--foreground-2);
    line-height: 20px;
    text-align: right;
  }
}
