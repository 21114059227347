.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px 30px 30px 20px;
  border-radius: 20px;
  background: var(--background-2);
  position: relative;

  .title {
    font: var(--font-20-bold);
    color: var(--foreground-3);
    margin-bottom: 20px;
  }
  .text {
    font: var(--font-12-semibold);
    font-size: 12px;
    line-height: 15px;
    color: var(--foreground-2);
  }
  .qIcon {
    position: absolute;
    top: 15.16px;
    right: 15.66px;
  }
  .pIcon {
    position: absolute;
    top: -15px;
    right: -20px;
  }
}
