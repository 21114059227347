.root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.lined {
  border-bottom: 1px solid var(--foreground-1);

  &.small {
    height: 70px;
  }

  &.medium,
  &.large {
    height: 80px;
  }

  &.error {
    border-color: var(--second-red-text);
  }
}

.groupTitle {
  padding: 10px;
  color: var(--foreground-3);
  font-size: 12px;
}

.toggle {
  display: flex;
  gap: 10px;
  height: 100%;
  color: var(--foreground-3);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  .small &,
  .medium & {
    font: var(--font-20-bold);
  }

  .large & {
    font: var(--font-25-bold);
  }

  .disabled & {
    opacity: 0.2;
    cursor: not-allowed;
  }
}

.label {
  font: var(--font-14-bold);
}

.toggleChevron {
  .open & {
    transform: rotate(180deg);
  }
}
.labelContent {
  width: 100%;
  margin-top: 10px;

  input {
    padding-right: 68px;
    font-size: 20px;
  }
}

.hideFocusInput {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.dropdown {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  max-height: 450px;
  padding: 10px;
  border-radius: 15px;
  background: var(--background-3);
  overflow: auto;
  z-index: 999;
}

.optionsContainer {
  overflow: auto;
  max-height: 400px;
  height: auto;
}

.search {
  min-width: 250px;
  margin-bottom: 15px;
  background: var(--background-2);
}

.noOptions {
  margin-bottom: 15px;
  text-align: center;
  color: var(--foreground-2);
}

.optionLabel {
  display: flex;
  flex-direction: column;
}

.address {
  color: var(--foreground-2);
}
.addressEllipsis {
  max-width: 420px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: var(--foreground-2);
}

.addressIcon {
  position: absolute;
  right: 0;
  top: 34px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border-radius: 8px;
    background: var(--background-2);
  }
}

.option {
  display: flex;
  gap: 10px;
  width: 100%;
  min-width: 90px;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  background: var(--background-3);
  font: var(--font-17-semibold);
  color: var(--foreground-3);
  transition: background-color, opacity 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: var(--btn-hover-on-bg);
  }

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &.active {
    background-color: var(--btn-hover-on-bg);

    .optionChevron {
      opacity: 1;
    }
  }

  &.disabled {
    cursor: not-allowed;
  }
}
.addButton {
  height: 40px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.optionChevron {
  opacity: 0;
}

.info {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -22px;
  display: flex;
  font: var(--font-14-semibold);
}

.errorMessage {
  color: var(--red-text);
}

.description {
  margin-left: auto;
  color: var(--foreground-2);
}

.rightIcon {
  flex-shrink: 0;
}
