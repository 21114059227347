.root {
  font: var(--font-14-medium);
  width: 420px;
  max-width: 100%;
  text-align: left;
}

.heading {
  margin: 0 0 30px;
  font: var(--font-20-bold);
  display: flex;
}

.form {
  margin: 20px 0 0;
}

.subheading {
  margin-bottom: 15px;
  font: var(--font-17-semibold);
}

.selectToggle {
  font: var(--font-17-semibold);
}

.selectLabel {
  color: var(--foreground-2);
}

.selectDropdown {
  width: 100%;
}

.inputsCheckbox ~ .inputsCheckbox {
  margin-top: 10px;
}

.controls {
  display: flex;
  flex-direction: column;
  margin: 20px 0 0;
}

.button {
  & + & {
    margin-top: 10px;
  }
}

.titleError {
  font: var(--font-25-bold);
  color: var(--foreground-3);
}

.disableButton {
  &,
  &:not(:disabled) {
    color: var(--foreground-2);
  }
  &:hover,
  &:hover:not(:disabled) {
    color: var(--second-red-text);
    background: none;
  }
}

.formItem {
  & + & {
    margin-top: 30px;
  }
  & + .formItemSmall {
    margin-top: 30px;
  }
}

.formItemSmall {
  & + & {
    margin-top: 10px;
  }

  & + {
    .formItem {
      margin-top: 30px;
    }
  }
}

.formItemMedium {
  & + & {
    margin-top: 15px;
  }

  & + {
    .formItem {
      margin-top: 30px;
    }
  }
}

._withMargin {
  margin-top: 30px;
}

.typeFormItem {
  text-align: left;
}

.rmMessage {
  display: flex;
  align-items: center;
  margin: 0 0 12px;
}

.rmIcon {
  margin: 0 4px 0 0;
}

.statusWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
}

.statusDesc {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
}

.status {
  margin-top: 4px;
  font: var(--font-17-semibold);
  display: flex;
  gap: 5px;
  align-items: center;
}

.closeControl {
  width: 30px;
  height: 30px;
  margin-left: auto;
  background: var(--background-3);
  &:hover {
    background: var(--btn-hover-on-bg);
  }
  svg {
    width: 8px;
    height: 8px;
  }
}

.horizontalControls {
  display: flex;
  justify-content: space-between;
}

.submitLine {
  margin-top: 30px;
}

.errorMessage {
  display: flex;
  flex-direction: column;
  color: var(--second-red-text);
  gap: 2px;
}

.submitBtn {
  width: 100%;
}

.btnWide {
  width: 100%;
}

.redText {
  &, &:not(:disabled) {
  color: var(--red-text);
  }
}

.subtitle {
  color: var(--white);
  font: var(--font-14-medium);
}

.errorWrap {
  text-align: center;
}

.noBg {
  &,
  &:hover {
    background: none;
  }
}