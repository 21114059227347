.topUpAccount {
  max-width: 337px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;

  .description {
    font: var(--font-14-medium);
    color: var(--foreground-2);
    text-align: center;
    margin: 15px 0 30px;
    max-width: 260px;
  }

  .buttons {
    display: flex;
    gap: 15px;
  }
}

.whiteIcon {
  --transfer-color: var(--foreground-3);
}

.topUpAccountMobile {
  padding-top: 50px;

  .description {
    margin: 10px 0 20px;
    line-height: 20px;
  }

  .title {
    font: var(--font-25-extrabold);
    line-height: 33px;
  }

  .buttons {
    flex-direction: column;
    width: 170px;
    gap: 10px;

    & > * {
      flex-grow: 1;
    }
  }
}
