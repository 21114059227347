.assetItem {
  background: var(--background-2);
  border-radius: 15px;
  height: 80px;
  display: grid;
  grid-template-columns: 1fr 1.8fr 180px;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:hover {
    background: var(--btn-hover);
  }

  .left {
    display: flex;
    align-items: center;
    gap: 15px;
    svg {
      width: 30px;
      height: 30px;
    }
    .currencyWrap {
      display: flex;
      flex-direction: column;
      color: var(--foreground-3);
      .currency {
        font: var(--font-14-bold);
      }
      .desc {
        font: var(--font-12-semibold);
        margin-top: 3px;
        color: var(--foreground-2);
      }
    }
  }
  .item {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .quantity {
      color: var(--foreground-3);
      font: var(--font-14-bold);
    }
    .quantityUsdt {
      font: var(--font-12-semibold);
      color: var(--foreground-2);
    }
  }
  &:nth-child(1n) {
    .barLine {
      background: var(--gradient-1);
    }
  }
  &:nth-child(2n) {
    .barLine {
      background: var(--gradient-2);
    }
  }
  &:nth-child(3n) {
    .barLine {
      background: var(--gradient-3);
    }
  }
  &:nth-child(4n) {
    .barLine {
      background: var(--gradient-4);
    }
  }
  &:nth-child(5n) {
    .barLine {
      background: var(--gradient-5);
    }
  }
  &:nth-child(6n) {
    .barLine {
      background: var(--gradient-6);
    }
  }
  &:nth-child(7n) {
    .barLine {
      background: var(--gradient-7);
    }
  }
  &:nth-child(8n) {
    .barLine {
      background: var(--gradient-8);
    }
  }
}

.spotAsset {
  grid-template-columns: 1fr 1.8fr 180px 100px;
}

.mobile.assetItem {
  display: flex;
  flex-direction: column;
  gap: 14.5px;
  height: auto;
  justify-content: initial;

  & > * {
    width: 100%;
  }

  &:hover {
    background: var(--background-2);
  }

  .itemWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .item {
    align-items: flex-end;
  }

  .left {
    .currencyWrap {
      .currency {
        line-height: 20px;
      }

      .desc {
        margin-top: 0;
        line-height: 17px;
      }
    }
  }
  .item {
    gap: 0px;

    .quantity {
      line-height: 20px;
    }

    .quantityUsdt {
      line-height: 17px;
    }
  }
}

.sellButton {
  margin-left: 20px;
  background: var(--background-3);
}

.gradientText {
  margin: 0;
  background-image: var(--gradient-foreground);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font: var(--font-14-bold);
}

.explanationText {
  font: var(--font-14-medium);
  color: var(--foreground-3);
}

.linkToProfile {
  text-decoration: underline;
  color: var(--gradient-foreground);

  p {
    margin: 0;
  }
}

.cancelButton {
  background-color: var(--background-3);
  height: 40px;
  margin-top: 15px;

  &:hover {
    color: var(--white);
  }
}
