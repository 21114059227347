@import "./src/styles/mixins";

.contentWrapp {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  max-width: 500px;
  font: var(--font-30-extrabold);
  padding-bottom: 20px;
}

.btnWrapp {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
}

.totalAmount {
  width: 100%;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font: var(--font-14-semibold);
}