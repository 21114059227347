.toggle {
  position: relative;
  width: 50px;
  height: 28px;
  border-radius: 30px;
  background-color: var(--background-1);
  border: 1px solid var(--btn-hover);
  cursor: pointer;
  opacity: 1;

  transition-property: border-color, opacity;
  transition-duration: 100ms;
  transition-timing-function: linear;

  &:not(.disabled):hover {
    border-color: var(--foreground-1);
  }
}

.disabled {
  opacity: 0.6;
  cursor: default;
}

.round {
  position: absolute;
  left: 4px;
  top: 3px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--btn-hover-on-bg);
  transition: 0.2s linear;

  .active & {
    left: auto;
    right: 3px;
    background-color: var(--green-text);
  }
}

.text {
  margin: 5px 0 0;
  font: var(--font-12-semibold);
  color: var(--foreground-2);
}
