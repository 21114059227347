.table {
  color: var(--white);
  width: 100%;
  font: var(--font-14-bold);
  border-collapse: collapse;
  text-align: left;
}

.container {
  overflow: auto;
  max-height: calc(80vh - 10rem);
}

tbody .tr:nth-child(odd) {
  background: var(--background-2);
}

.th {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
  padding: 20px;
}

.td {
  padding: 15px 20px;

  &:nth-child(even) {
    color: var(--foreground-2);
  }
}

.isActive:not(.sort) {
  color: var(--white);
}

.headerBlock {
  display: flex;
}

.sort {
  margin-top: -2px;
}

.selectedSort path {
  fill: var(--white);
}

.sortParent {
  width: 1px;
  margin-left: -7px;
  margin-right: 8px;
}

.container {
  align-items: center;
  display: flex;
  gap: 5px;
}

.gap {
  height: 10px;
  background-color: var(--background-1);
}

.table [class*='headerFrozen'] {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 2;
  background-color: inherit;
}

.hint {
  left: 24px !important;

  &:nth-child(2) div::after {
    left: -23px !important;
  }
}
.hintContainer {
  top: 4px !important;
  min-width: 0;
}

.hintContainer div {
  justify-content: center;
}

.emptyDiv {
  height: none;
}

.hintBlock {
  white-space: initial;
}

tfoot {
  position: sticky;
  bottom: 0;
  z-index: 2;
  opacity: 1;
  background-color: var(--background-2);
}

.hintHistory {
  position: absolute;
  top: 0;
  right: -23%;
  cursor: pointer;
}

.wrapper {
  position: relative;
  display: inline-block;
}

.firstHint {
  right: -30%;
}
