.root {
  position: relative;
}

.small {
  height: 30px;
  font: var(--font-12-semibold);
}

.medium {
  height: 40px;
  font: var(--font-14-extrabold);
}

.large {
  font: var(--font-20-bold);
}

.dark {
  --toggle-background: var(--background-2);
  --toggle-hover: var(--btn-hover);
  --color: var(--foreground-3);
}

.light {
  --toggle-background: rgba(255, 255, 255, 0.3);
  --toggle-hover: rgba(255, 255, 255, 0.2);
  --color: var(--foreground-3);
}

.toggle {
  display: flex;
  align-items: center;
  height: inherit;
  cursor: pointer;
  padding: 0 15px;
  background-color: var(--toggle-background);
  border-radius: 10px;
  color: var(--color);
  white-space: nowrap;

  &:hover {
    background: var(--toggle-hover);
  }

  &:active {
    background: var(--toggle-background);
  }

  .light & {
    backdrop-filter: blur(15px);
  }
}

.label {
  display: flex;
  align-items: center;
  height: inherit;
  gap: 5px;
}

.arrow {
  margin-left: 8px;
  width: 10px;

  .open & {
    transform: rotate(180deg);
  }
}

.dropdown {
  position: absolute;
  margin-top: 7px;
  padding: 10px;
  border-radius: 15px;
  background-color: var(--background-3);
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.2s ease;

  .left & {
    right: 0;
    transform: translate(0, -10px);
  }

  .right & {
    left: 0;
    transform: translate(0, -10px);
  }

  .center & {
    left: 50%;
    transform: translate(-50%, -10px);
  }

  .open &,
  .open & {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }

  .open.left &,
  .open.right & {
    transform: translate(0, 0);
  }

  .open.center & {
    transform: translate(-50%, 0);
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  border-radius: 10px;
  padding: 10px;
  min-width: 144px;
  color: var(--color);
  cursor: pointer;
  position: relative;

  &:not(:first-child) {
    margin-top: 5px;
  }

  &:hover {
    background: var(--btn-hover-on-bg);
  }

  &.active {
    background: var(--btn-hover-on-bg);
  }
}
