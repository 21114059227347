.position {
  display: grid;
  align-items: center;
  background: var(--background-2);
  transition: background-color, opacity 0.2s linear;

  &.tab {
    grid-template-columns: 2.4fr 1.2fr 1.2fr 1.2fr 32px 1.5fr 30px;
    gap: 20px;
    padding: 20px;
    border-radius: 15px;
  }
  &.table {
    grid-template-columns: 2fr 1fr 1fr 1.2fr 32px 1.5fr 30px;
    gap: 10px;
    padding: 10px;
    border-radius: 12px;
  }

  &.deleted {
    opacity: 0.2;
    pointer-events: none;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:hover {
    background: var(--btn-hover);
  }
}

.item {
  color: var(--foreground-3);

  .tab & {
    font: var(--font-14-bold);

    &:nth-child(5) {
      text-align: right;
    }
  }

  .table & {
    font: var(--font-12-semibold);

    &:nth-child(6) {
      text-align: right;
    }
  }
}

.description {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
}

.currency {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;

  .table & {
    font: var(--font-12-extrabold);
  }

}

.side {
  color: var(--foreground-2);
}

.green {
  color: var(--green-text);
}

.red {
  color: var(--red-text);
}

.closeButton {
  height: 30px;
  width: 30px;
  padding: 0;
}

.smallFont{
  font-size: 9px;
}