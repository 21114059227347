.container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.avatarWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}

.commonBalance {
  font: var(--font-14-extrabold);
  color: var(--white);
}

.amount {
  font: var(--font-20-extrabold);
  color: var(--white);
}

.avatar {
  width: 40px;
  height: 40px;
}

.table {
  width: 100%;
  table-layout: fixed;
}

.row {
  .first {
    text-align: left;
  }

  .middle {
    text-align: center;
  }

  .last {
    text-align: right;
  }
}

.columnInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font: var(--font-12-semibold);
  line-height: 1.4;
}

.label,
.currency,
.commonBalance,
.amount {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.label {
  color: var(--foreground-2);
}

.currency {
  color: var(--white);
}

.skeletonCoins {
  width: 125px;
  height: 25px;
  border-radius: 13px;
  background-color: var(--foreground-1);
}

.skeletonColumns {
  .first {
    align-items: flex-start;
  }

  .middle {
    align-items: center;
  }

  .last {
    align-items: flex-end;
  }
}

.skeletonContentWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.skeletonTitle {
  width: 90px;
  height: 17px;

  border-radius: 9px;
  background-color: var(--foreground-1);
}

.skeletonCurrency {
  width: 50px;
  height: 17px;

  border-radius: 9px;
  background-color: var(--foreground-1);
}
