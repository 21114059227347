.cashbackLeftBar {
  position: relative;
  width: 305px;
  min-width: 305px;
  display: flex;
  flex-direction: column;
  padding-top: 9px;
  .title {
    margin-bottom: 30px;
  }
  .progress {
    margin: 10px 0 40px;
  }
  ._white {
    color: var(--white);
  }
  ._nowrap {
    white-space: nowrap;
  }
}

.btnWrap {
  margin: 40px 0 0;
}

.btn {
  width: 100%;
}

._flexAlignStart {
  align-items: flex-start;
}