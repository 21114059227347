.root {
  padding: 20px;
    height: 219px;
    background: var(--background-2);
  border-radius: 15px;
}

.heading {
    background: var(--background-1);
    height: 20px;
    margin: 0 0 40px;
}

.item {
  margin: 20px 0 0;
  height: 40px;
  border-radius: 15px;
    background: var(--background-1);
}

.animatable {
  overflow: hidden;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 30px;
    height: 700px;
    top: 0;
    left: 0;
    background: #1B1824;
    box-shadow: 0 0 50px 50px #1B1824;
    animation: animate 2.2s linear infinite;
    z-index: 1;
  }
}

@keyframes animate {
  0% {
    transform: translate(-100px) skewX(-10deg);
  }
  30% {
    transform: translate(100vw) skewX(-10deg);
  }
  100% {
    transform: translate(100vw) skewX(-10deg);
  }
}
