@import "src/styles/mixins";

.root {
  position: fixed;
  background: rgba(var(--background-2), .8);
  top: 15px;
  left: 60px;
  right: 65px;
  backdrop-filter: blur(50px);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px 15px 20px;
  gap: 19px;

  .header {
    font: var(--font-17-semibold);
    color: var(--foreground-3);
    margin-right: 20px;
  }

  .text {
    font: var(--font-17-semibold);
    color: var(--foreground-2);
    margin-right: 20px;
  }

  .button {
    width: 150px; 
  }

  .close {
    background:  transparent;
    border: 0;
    cursor: pointer;
    padding: 10px;
  }
}
