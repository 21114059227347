@import "./src/styles/mixins";

.resultCard {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 60px;
  padding: 15px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.03);
  border-radius: 10px;
}

.medal {
  font-size: 24px;
  font-weight: bold;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleWrapp {
  display: flex;
  flex-direction: column;
}

.amountWrapp {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.title {
  font: var(--font-12-semibold);
  color: var(--white);
}

.description {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
}

.amount {
  font: var(--font-12-semibold);
  color: var(--white);
}

.currency {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
}

.position {
  font: var(--font-14-medium);
  color: var(--white);
}