@import "./src/styles/mixins";

.wallet {
  @include contentWrapper();

  .content {
    @include contentPage();
  }
}

.customError {
  margin: 10px 0 0;
  width: 300px;
}

