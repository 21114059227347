.root {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skeleton {
  margin-top: 0;
}
