.headers {
  display: flex;
  gap: 41px;
  justify-content: space-between;
}

.head {
  color: var(--white);
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.price {
  display: inline-block;
  color: var(--foreground-3);
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 32px;
  line-height: 50px;
}

.price.balance {
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: var(--gradient-foreground);
}

.symbol {
  color: var(--general-gray);
}
