@import "./src/styles/mixins";

.container {
  margin: 10px 0 0;
  width: 318px;
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
}

.buttonIcon {
  width: 45px;
  height: 45px;
}

.button {
  margin: 0 auto 15px;
  width: 300px;
  height: 140px;
}

.buttonInner {
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;

  svg {
    margin-right: 0;
  }
}

.mobile {
	&.button {
		width: 100%;
		margin: 0 0 10px;
	}
}
