.root {
  margin: 40px 0;
}

.headingWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.heading {
  font: var(--font-25-extrabold);
  color: var(--foreground-3);
}

.codes {
  margin-top: 20px;
}