.progressWrap {
  width: 6px;
  display: flex;
  border-radius: 35px;
  position: relative;
  .barLine {
    position: absolute;
    height: 100%;
    width: 100%;
    background: var(--gradient-foreground-2);
    border-radius: 35px;
    background-size: 150% 150%;
    animation: bar 5s ease infinite;
    animation-duration: 2s;
  }
  .barLineBg {
    position: absolute;
    height: 100%;
    width: 100%;
    background: var(--gradient-foreground-3);
    border-radius: 35px;
    background-size: 150% 150%;
    transform: rotate3d(0, 0, 0, 0);
    opacity: 0.2;
  }
}

@keyframes bar {
  0% {
    background-position: 0% 20%;
  }
  50% {
    background-position: 100% 70%;
  }
  100% {
    background-position: 0% 70%;
  }
}
