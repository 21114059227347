.howItWorksQuestions {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
  padding: 30px;
  border-radius: 20px;
  background: var(--background-2);

  .itemWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;

    .question {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      color: var(--foreground-2);
    }
    .text {
      font: var(--font-20-bold);
      color: var(--foreground-3);
      &._small {
        font-size: 16px;
      }
    }

    &:first-child .text {
      width: 210px;
      &._small {
        width: 240px;
      }
    }
    &:last-child .text {
      width: 240px;
    }
  }
}
