.status {
  display: flex;
  align-items: center;
  font: var(--font-12-semibold);

  padding: 8px 10px;
  border-radius: 101px;
  background: var(--background-3);
  color: var(--white);

  &.loading .statusName {
    color: var(--foreground-2);
  }

  &.failed .statusName {
    color: var(--second-red-text);
  }

  svg {
    margin-right: 5px;
  }
}