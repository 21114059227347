.root {
  position: relative;
  display: flex;
  justify-content: center;
  place-items: center;
  gap: 7px;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.2s ease;

  &:not(:has(.text)) {
    padding: 0;
  }

  &:disabled {
    pointer-events: none;
  }
}

.text {
  text-align: center;
}

.description {
  font: var(--font-12-semibold);
  opacity: 0.7;
}

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  place-items: center;
  justify-content: center;
  border-radius: inherit;
  color: inherit;
  cursor: progress;
}

.disabled:disabled {
  background: var(--background-3);
  color: var(--foreground-1);
  fill: var(--foreground-1);
  stroke: var(--foreground-1);
  cursor: not-allowed;
  svg {
    opacity: 0.3;
  }
}

.loading > *:not(.spinner) {
  opacity: 0;
}

// Sizes
.extraSmall {
  height: 30px;
  font: var(--font-12-semibold);
  line-height: 17px;

  &.root {
    padding: 6px 15px;
  }
}

.small {
  height: 40px;
  font: var(--font-14-bold);
}

.medium {
  height: 45px;
  font: var(--font-14-bold);
}

.large {
  height: 70px;
  font: var(--font-17-semibold);
}

// Variants

.accent {
  background: var(--gradient-foreground);

  &:not(:disabled) {
    color: var(--foreground-3);
  }

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    background: var(--btn-hover-grad);
  }
}

.accentDark {
  background-color: var(--background-3);
  &:not(:disabled) {
    color: var(--foreground-3);
  }

  &:not(:disabled) > *:not(.spinner) {
    background-image: var(--gradient-foreground);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    background: var(--btn-hover-on-bg);
  }
}

.primary,
.primaryGreen {
  background: var(--background-3);

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    background: var(--btn-hover-on-bg);
  }
}

.primary {
  &:not(:disabled) {
    color: var(--foreground-3);
  }
}

.primaryGreen {
  &,
  &:disabled {
    color: var(--green-text);
  }
}

.secondary {
  background: var(--background-2);
  &:not(:disabled) {
    color: var(--foreground-3);
  }

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    background: var(--btn-hover);
  }
}

.whiteBlured {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  color: var(--white);

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    background: rgba(255, 255, 255, 0.2);
  }

  &:disabled.disabled {
    background: rgba(255, 255, 255, 0.1);
    color: var(--foreground-2);
  }
}

.white {
  background: var(--foreground-3);
  color: var(--background-1);

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    background: var(--white-hover);
  }
}

.clean {
  padding: 0 10px;
  height: auto;
  width: auto;
  background-color: transparent;
  color: var(--foreground-3);

  &:hover,
  &:active {
    color: var(--foreground-4);
  }
}
