.supportButton {
  display: flex;
  align-items: center;
  border-radius: 10px;
  font: var(--font-12-semibold);
  height: 40px;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  position: relative;
  z-index: 10;
  gap: 5px;
  background: var(--background-2);
  color: var(--foreground-2);
  span {
    &:nth-child(2) {
      padding-top: 3px;
    }
  }
  &:hover {
    background: var(--background-3);
    span:last-child {
      color: var(--foreground-3);
    }
  }
  &:active {
    scale: 0.95;
  }
}
