.root {
  margin-top: 20px;
  padding: 177px 20px 15px;
  border-radius: 20px;
  background-color: var(--background-2);
  background-image: url('../../../assets/png/banner-bg.png');
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
}

.title {
  font: var(--font-17-semibold);
  color: var(--white);
  text-align: center;
}

.button {
  position: relative;
  margin-top: 10px;
  width: 100%;
}

.icon {
  position: absolute;
  left: 15px;
  width: 18px !important;
}

.windows {
  color: var(--blue-windows);
}