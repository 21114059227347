.root {
  width: 100%;
  min-width: 100%;
  min-height: 382px;
  background: var(--background-2);
  padding: 20px 25px 20px 25px;
  border-radius: 15px;
  margin-top: 7px;
}

.header {
  display: flex;
  color: var(--white);
  gap: 8px;
  font: var(--font-20-bold);
  padding-bottom: 10px;
}

.hint {
  cursor: pointer;
}

.switchContainer {
  background: var(--background-3);
  padding: 4px;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  border-radius: 13px;
  margin-bottom: 16px;
}

.switchItem {
  width: 100%;
  min-width: 147px;
  text-align: center;
  font: var(--font-14-bold);
  padding: 10px 15px;
  color: var(--white);
  cursor: pointer;

  &.active {
    background: var(--background-1);
    border-radius: 10px;
  }
}

.loaderBlock {
  width: 100%;
  min-width: 382px;
  height: 382px;
}
