.filterContainer {
  display: flex;
  width: 100%;
  gap: 40px;
  margin-top: 40px;
}

.filterContent {
  display: flex;
  flex-direction: column;
}

.filterName {
  color: var(--foreground-2);
}

.datePickerBlock {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.date {
  display: inline-block;
  padding-left: 5px;
}

.line {
  padding: 0 5px;
}

.select {
  min-width: 180px;
}

.date {
  font-size: 14px;
  font: var(--font-20-semibold);
  color: var(--white);
}

.toggleChevron {
  margin-left: 10px;
  flex-shrink: 0;

  .open & {
    transform: rotate(180deg);
  }
}

.selectContent {
  position: relative;
  top: -11px;
}

.resetButton {
  margin-left: 10px;
  text-decoration: underline;
  cursor: pointer;
  color: var(--white);
  display: flex;
  align-items: center;
  position: relative;
  top: -4px;
}

.moreData {
  color: var(--foreground-2);
  font: var(--font-14-semibold);
  padding: 10px 0;
}