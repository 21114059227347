.select {
  width: auto;
  height: 40px;
  background: var(--background-3);
  color: var(--foreground-3);
  border-radius: 10px;
  padding: 10px;
  padding: 11px 10px 9px;

  & div {
    font: var(--font-14-bold);
    left: 0px;
    z-index: 101;
  }
}

.tickerSelect {
  min-width: 122px;
  max-height: 400px;
  & div {
    max-height: 305px;
  }
}

.exchange {
  & div {
    max-height: 305px;
  }
}


.selectContainer {
  min-width: 102px;

  & > div:not(:first-child) {
    width: 200px;
  }
}

.icon {
  width: 24px;
  display: flex;
}

.exchangeFilterContainer {
  display: flex;
  align-items: center;
  gap: 7px;
}
