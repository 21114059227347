.container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  height: 189px;
  background: radial-gradient(circle at top right, rgba(229, 113, 255, 0.1) 00%, rgba(255, 137, 51, 0) 45%), var(--background-2);
  border-radius: 25px;
  color: var(--white);
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 10px;
}

.backgroundText {
  position: absolute;
  top: 4px;
  right: -30px;
  color: rgba(255, 255, 255, 0.03);
  font: var(--font-family);
  font-size: 128px;
  font-weight: 800;
  line-height: 128px;
}

.content {
  position: relative;
  text-align: left;
}

.percent {
  background: linear-gradient(90deg, var(--second-purple-text) 10%, var(--third-foreground) 40%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font: var(--font-family);
  font-size: 64px;
  font-weight: 800;
}

.description {
  color: var(--foreground-2);
  font: var(--font-14-medium);
}