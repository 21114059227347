@import './src/styles/mixins';

.transactions {
  height: 100%;
  padding: 91px 0 76px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
  }

  .mobileContent {
    max-width: none;
  }
}

.mobileTransactions {
  padding: 20px 0 70px;
}

.customError {
  margin: 10px 0 0;
  width: 318px;
}
