@import "./src/styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: fit-content;
  padding: 20px 0 5px;
  border-radius: 20px;
  background-color: var(--background-2);
  width: 400px;
  padding-bottom: 20px;
}

.titleWrapp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.title {
  font: var(--font-20-bold);
  color: var(--foreground-3);
  text-wrap: nowrap;
}

.range {
  font: var(--font-14-semibold);
  color: var(--foreground-2);
}

.content {
  color: var(--white);
  width: 100%;
  font: var(--font-14-bold);
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 12px 20px;
  font: var(--font-12-semibold);
  color: var(--foreground-2);
}


.leaderItem {
  display: flex;
  align-items: center;
  height: 50px;
  gap: 20px;
  padding: 0 20px;
  border-top: 0.5px solid var(--foreground-1);
  font: var(--font-14-medium);
  color: var(--white);
}

.place {
  width: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user {
  width: 150px;
  text-align: left;
}

.result {
  flex: 1;
  text-align: right;
}

.emptyData {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-weight: 400;
}