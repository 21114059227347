@import 'src/styles/mixins';

.dropdownWrap {
  position: absolute;
  top: 30px;
  right: 0;
  .dropdown {
    height: 40px;
    cursor: pointer;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 162px;
    span {
      color: var(--foreground-3);
      font: var(--font-20-bold);
      margin-right: 14px;
      display: flex;
      align-items: center;
      svg {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }
    }
  }
  .openDropdown {
    @extend .dropdown;
    span svg {
      transform: rotate(0);
    }
    svg {
      transform: rotate(180deg);
    }
  }
  .dropdownList {
    position: absolute;
    z-index: 1;
    right: 0;
    margin-top: 15px;
    padding: 10px;
    background: var(--background-3);
    border-radius: 15px;
    gap: 5px;
    display: flex;
    flex-direction: column;
    width: max-content;

    .listItem {
      @include flex-center;
      background: var(--background-3);
      border-radius: 10px;
      padding: 10px;
      height: 42px;
      min-width: 160px;
      cursor: pointer;
      color: var(--foreground-3);
      font: var(--font-17-semibold);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .value {
        svg {
          width: 20px;
          height: 20px;
          margin-right: 7px;
        }
        display: flex;
        align-items: center;
      }
      .ok {
        padding-bottom: 2px;
        svg {
          width: 13px;
          height: 8px;
        }
      }
      &:hover {
        background: var(--btn-hover-on-bg);
      }
    }
    .active {
      @extend .listItem;
      background: var(--btn-hover-on-bg);
    }
  }
}

.dropdownWrapDisabled {
  @extend .dropdownWrap;
  opacity: 0.2;
  pointer-events: none;
}
