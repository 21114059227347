@import "./src/styles/mixins";

.leaderItem {
  display: flex;
  align-items: center;
  height: 50px;
  gap: 20px;
  padding: 0 20px;
  border-top: 0.5px solid var(--foreground-1);
  font: var(--font-14-medium);
  color: var(--white);
  background-color: var(--background-2);

  &:hover {

    .copy {
      display: flex;
    }
  }

}

.place {
  width: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copy {
  display: none;
  align-items: center;
  justify-content: center;
}


.user {
  width: 150px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.result {
  flex: 1;
  text-align: right;
}

.active {
  position: relative;
  font: var(--font-14-bold);
  transform: scale(1.05);
  border: 1px solid var(--white);
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
}

.active::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: var(--background-2);
  z-index: -1;
  border-radius: 10px;
}

.active::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: inherit;
  pointer-events: none;
}

.firstPlace {
  background: radial-gradient(100% 8153.48% at 0% 100%, rgba(251, 180, 16, 0.2) 0%, rgba(251, 180, 16, 0) 90%);
}

.firstPlace.active {
  border: 1px solid #FBB410;

  &::after {
    background: radial-gradient(100% 8153.48% at 0% 100%, rgba(251, 180, 16, 0.2) 0%, rgba(251, 180, 16, 0) 90%);
  }
}

.secondPlace {
  background: radial-gradient(100% 8153.48% at 0% 100%, rgba(218, 217, 217, 0.2) 0%, rgba(218, 217, 217, 0) 90%);
}

.secondPlace.active {
  border: 1px solid rgba(218, 217, 217, 0.5);

  &::after {
    background: radial-gradient(100% 8153.48% at 0% 100%, rgba(218, 217, 217, 0.2) 0%, rgba(218, 217, 217, 0) 90%);
  }
}

.thirdPlace {
  background: radial-gradient(100% 8153.48% at 0% 100%, rgba(173, 106, 94, 0.2) 0%, rgba(173, 106, 94, 0) 90%);
}

.thirdPlace.active {
  border: 1px solid rgba(173, 106, 94, 0.5);

  &::after {
    background: radial-gradient(100% 8153.48% at 0% 100%, rgba(173, 106, 94, 0.2) 0%, rgba(173, 106, 94, 0) 90%);
  }
}