@import 'src/styles/mixins';

.modalWrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(8, 6, 16, 0.94);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 1000;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  max-height: 100vh;
  height: 100%;
  right: 10px;
  background-color: var(--background-2);
  padding: 24px;
  color: var(--white);
  box-sizing: border-box;

  .header {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 40px;

    .titleWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.readAll {
  cursor: pointer;
  color: var(--foreground-2);

  &:hover {
    color: var(--white);
  }
}

.closeBtn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: var(--background-3);
  font: var(--font-12-semibold);
  text-transform: uppercase;
  color: var(--white);
  cursor: pointer;
}

.checkbox {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding-bottom: 20px;

  font: var(--font-14-semibold);
  line-height: 20px;
  color: var(--foreground-2);
  white-space: nowrap;

  &:hover {
    color: var(--white);
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}