.root {
  margin: 40px 0;
  width: 100%;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 50px;

  .columnLeft {
    padding-right: 30px;
    border-right: 1px solid var(--background-3);
  }

  .columnRight {
    padding-left: 30px;
  }
}

.columnHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.mobileRoot {
  width: 100%;
}

.tabs {
  margin: 20px 0;
}

.assetsBtn {
  margin-top: 10px;
  width: 100%;
}

.headerWrapp {
  display: flex;
  align-items: center;
  gap: 12px;
}

.margin {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-family: var(--font-12-medium);
  line-height: 20px;
  text-align: left;
  color: var(--foreground-2);
  text-decoration: underline dashed;
  text-underline-offset: 4px;
  cursor: pointer;
}

.isolatedMargin {
  color: #f97052;
}

.modal {
  min-width: 572px;
  box-sizing: border-box;
}

.columnsBig {
  display: flex;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 70px;

  .columnLeft {
    width: 60%;
    border: none;
  }
}

.closeButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}