.wrapper {
  position: absolute;
  left: calc(100% + 20px);

  .smallPopup {
    position: absolute;
    top: 8px;
    display: flex;
    gap: 13px;
    align-items: center;
    width: 210px;
    padding: 16px 15px 16px 20px;
    border-radius: 15px;
    background-color: var(--background-2);
    border: 0.5px solid var(--foreground-1);

    .text {
      font: var(--font-14-semibold);
      line-height: 18px;
      color: var(--foreground-3);

      .amount {
        white-space: nowrap;
        color: transparent;
        -webkit-background-clip: text;
        background-image: var(--gradient-foreground);
      }
    }

    .buttonWithIcon {
      padding: 6.4px 8.2px;
      border-radius: 7px;
      width: 20px;
      height: 20px;
      svg path {
        outline: none;
      }
    }

    .buttonWrap {
      width: max-content;
    }
  }
  .smallPopupUnlimited {
    border: 0.5px solid var(--gradient-foreground);
  }

  .bigPopup {
    position: absolute;
    top: -225px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 320px;
    padding: 35px 24px 25px;
    border-radius: 15px;
    background-color: var(--background-2);
    border: 0.5px solid var(--foreground-1);

    .buttonClose {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 9px;
      border-radius: 7px;
      width: 25px;
      height: 25px;

      svg path {
        outline: none;
      }
    }

    .title {
      color: var(--foreground-3);
      font: var(--font-20-bold);
      margin-bottom: 20px;
    }

    .progressBars {
      display: flex;
      gap: 5.5px;
      margin-bottom: 25px;

      .pbWrap {
        display: flex;
        flex-direction: column;
        align-items: center;

        .progressBar {
          height: 6px;
          width: 130px;
          border-radius: 12px;
          background: var(--foreground-1);
          position: relative;
          margin-bottom: 10px;

          .barLine {
            background: var(--gradient-1);
            position: absolute;
            right: 0;
            height: 100%;
            width: 100%;
            border-radius: 12px;
            background-size: 150% 150%;
            transform: translate3d(0, 0, 0);
            animation: bar 5s ease infinite;
            animation-duration: 2s;
          }
        }
        .count {
          display: flex;
          align-items: center;
          font: var(--font-14-bold);
          color: var(--foreground-2);
        }

        .disabledCount {
          color: var(--foreground-1);
          gap: 5.3px;
        }
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .listItem {
        display: flex;
        gap: 12px;

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 4px 7px 3px;
          width: 30px;
          height: 30px;
          background: var(--background-3);
          border-radius: 10px;
        }

        .text {
          width: calc(100% - 42px);
          font: var(--font-14-semibold);
          color: var(--foreground-2);

          .count {
            font: var(--font-14-bold);
            color: var(--foreground-3);
          }
        }
      }
    }

    .verificationButtonWrap {
      margin-top: 25px;
      width: 100%;
    }

    .verificationButton {
      width: 100%;
    }

    .disabledButton {
      color: var(--foreground-2);
      width: 20%;
      background: none;
    }
  }
}

._nowrap {
  white-space: nowrap;
}

.mobile.mobileModal {
  max-width: 320px;
  border: 0.5px solid var(--foreground-1);
  position: relative;

  .buttonClose {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 8px;
    border-radius: 8px;
    width: 25px;
    height: 25px;

    svg path {
      outline: none;
    }
  }
}

.mobile.wrapper {
  position: relative;
  left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  .smallPopup {
    position: relative;
    top: auto;
    gap: 5px;

    width: auto;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    border: none;

    cursor: pointer;

    .buttonWithIcon {
      padding: 0;
    }

    .text {
      line-height: 20px;
    }
  }

  .bigPopup {
    position: static;
    top: auto;

    width: 100%;
    padding: 5px 0 0;

    border-radius: 0;
    background-color: transparent;
    border: none;

    .progressBars {
      width: 100%;

      .pbWrap {
        flex: 1 1;
        width: 0;
        max-width: 130px;

        .progressBar {
          width: 100%;
        }

        .count {
          line-height: 20px;
        }
      }
    }

    .list {
      .listItem {
        .text {
          width: auto;
          line-height: 20px;
          text-align: left;

          .count {
            line-height: inherit;
          }
        }
      }
    }
  }
}
