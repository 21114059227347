.container {
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--btn-hover-on-bg);

  border-radius: 10px;
  padding: 0 20px;
}

.text {
  color: var(--foreground-2);
  font: var(--font-14-bold);
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
}

.copyButton {
  position: absolute;
  right: 15px;
  padding-top: 8px;
  cursor: pointer;

  &:hover {
    color: var(--white);
  }
}