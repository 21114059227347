.root {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.mobile.root {
  position: absolute;
  align-items: end;
  top: 15px;
  left: 0;
  z-index: 10;
}
