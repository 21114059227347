@import '@/styles/mixins';

.loaderWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--white);
  text-align: center;
}

.loader {
  margin-bottom: 15px;
}

.msg {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
}

.overlay,
.overlayMobile {
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: var(--background-1);
  position: fixed;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  width: auto;
  @include background;
}

.overlayMobile {
  @include background($is-mobile: true);
}
