.rightBar {
  max-width: 905px;
  margin-top: 18px;
  width: 100%;
}

.title {
  display: block;
  padding-right: 152px;

  &:last-of-type {
    padding-right: 0;
    margin-bottom: 40px;
  }
}

.text {
  margin-top: 40px;
  font: var(--font-20-bold);
  color: var(--foreground-2);
}

.subtitle {
  color: var(--foreground-4);
}