.wrapper{
  display: flex;
}

.dangerText {
  color: var(--red-text);
  font: var(--font-14-semibold);
  white-space: nowrap;
}

.hintWrapper {
  position: relative;
  max-width: fit-content;
}
