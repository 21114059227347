.root {

}

.invited {
  margin: 40px 0 30px;
}

.invitedTitle {
  font: var(--font-14-medium);
  color: var(--foreground-2);
}

.invitedCount {
  display: flex;
  align-items: baseline;
  gap: 10px;
  margin-top: 5px;
  font: var(--font-40-extrabold);
  color: transparent;
  background-clip: text;
  background-image: var(--gradient-foreground);
}

.invitedMonth {
  font: var(--font-14-medium);
  color: var(--foreground-3);
  background: none;
}

.referral {
  margin: 20px 0;
}

.referralTitle {
  font: var(--font-14-semibold);
  color: var(--foreground-2);
}

.referralContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-top: 5px;
  font: var(--font-17-semibold);
  color: var(--foreground-3);
}

.referralCode {
  overflow: hidden;
  text-overflow: ellipsis;
}

.copyCodeBtn {
  flex: 0 0 30px;
}

.referralLink {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color .2s ease;

  &:hover {
    color: var(--gray-5);
  }
}

.referralButton {
  margin-top: 15px;
  width: 100%;
}

.transactions {
  margin: 40px 0;
}

.transactionsBtn {
  width: 100%;
}