.root {
  margin-top: 40px;
  padding: 20px;
  border-radius: 15px;
  background-color: var(--background-2);
}

.heading {
  font: var(--font-20-bold);
  color: var(--white);
}

.info {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.refCode {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.teamTitle {
  display: flex;
  align-items: center;
  gap: 3px;
}

.code {
  margin-top: 10px;
  font: var(--font-14-medium);
  color: var(--foreground-2);
  display: flex;
  flex-direction: column;
}

.referral {
  font: var(--font-14-medium);
  color: var(--foreground-2);
  margin-top: 20px;
}
.referralUrl {
  color: var(--white);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.button {
  width: 100%;
  margin-top: 20px;
}
.membersCount {
  font: var(--font-14-medium);
  color: var(--foreground-2);
}
.copy {
  --background: var(--background-3);
}

.infoAccent {
  font: var(--font-14-bold);
  color: var(--white);
  white-space: nowrap;
}

.members {
  font: var(--font-40-extrabold);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: var(--gradient-foreground);
  padding-right: 10px;
}

.count {
  color: var(--white);
}
