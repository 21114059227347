.header {
  display: grid;
  grid-template-columns: 2.4fr 1fr 1fr 1fr 30px;
  gap: 20px;
  min-height: 30px;
  margin: 20px 0 10px;
  padding: 0 10px;
  font: var(--font-12-semibold);
  color: var(--foreground-2);

  & > *:nth-child(4) {
    text-align: right;
  }
}

.loader {
  margin: 60px auto;
}

.empty {
  margin-top: 10px;
  font: var(--font-20-bold);
  color: var(--foreground-1);
}