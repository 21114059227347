.widgetItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 200px;
  min-height: 78px;
  background: var(--background-2);
  border-radius: 16px;
  align-items: center;
  padding: 10px;
}
.widgetItem:has(.withoutPercentValue) {
  border: 1px solid var(--foreground-2);
}

.widgetItem {
  border: 1px solid var(--background-3);
}

.widgetItem:has(.positive) {
  border: 1px solid var(--green-text);
}

.widgetItem:has(.negative) {
  border: 1px solid var(--negative-text);
}

.result {
  font: var(--font-20-extrabold);
  color: var(--white);
}

.text {
  font: var(--font-14-semibold);
  color: var(--foreground-2);
  display: flex;
  gap: 5px;
}

.diaryWeekContainer {
  display: flex;
  flex-direction: column;
}

.positive {
  color: var(--green-text);
}

.negative {
  color: var(--second-red-text);
}
