@import 'src/styles/mixins';

.root {
  padding: 10px 0 20px 20px;
}

.info {
  color: var(--foreground-2);
  font: var(--font-12-semibold);
  margin-bottom: 5px;
}

.addressWrap {
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 100%;
}

.address {
  margin-right: auto;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: var(--gradient-foreground);
  font: var(--font-17-bold);
}

.addressButtons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.copyButton {
  flex: 0 0 30px;
}

.mobile.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;

  width: 100%;
  padding: 15px 0 0;

  .info {
    font: var(--font-12-extrabold);
    margin: 0;
  }

  .addressWrap {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .address {
    font: var(--font-16-bold);
    line-height: 25px;
	text-align: center;
    word-break: break-all;
  }

  .button {
    width: 40px;
    height: 40px;
    flex: auto;
    border-radius: 10px;
  }
}
