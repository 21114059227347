.root {
  margin: 0 -18px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 15px 15px 15px 20px;
  border-radius: 20px;
  background: var(--background-2-80);
  font: var(--font-14-semibold);
  color: var(--foreground-2);
  backdrop-filter: blur(15px);
}

.inner {
  display: flex;
  align-items: center;
}

.icon {
  min-width: 30px;
  min-height: 30px;
  margin-right: 15px;
  vertical-align: top;
}

.content {
  margin: -8px 0 -7px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.heading {
  margin-right: 15px;
  color: var(--white);
  display: inline;
  padding-top: 4px;
  font: var(--font-17-semibold);
}

.actions {
  display: flex;
  align-items: center;
  gap: 10px;

  &:not(.mobile &) {
    padding: 0 0 0 20px;
  }

  .control {
    width: 40px;
    height: 40px;

    background-color: var(--background-3);
    color: var(--white);

    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: var(--foreground-1);
    }
  }
}

.mobile.root {
  margin: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  pad: 15px 10px;

  .inner {
    width: 100%;
    align-items: flex-start;
  }

  .icon {
    margin-right: 10px;
  }

  .content {
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    margin: 0;

    .heading {
      font: var(--font-12-semibold);
      line-height: 17px;
      padding: 0;
    }

    .description {
      font: var(--font-12-semibold);
      padding: 0;
    }
  }

  .controlsWrapper {
    position: relative;
    min-width: 25px;

    .control {
      position: absolute;
      top: -5px;
      background-color: transparent;
      color: var(--white);
    }
  }

  .controlsIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    .control {
      background-color: transparent;
      color: var(--white);
    }
  }

  .actions,
  .modalButton {
    width: 100%;
  }
}


.showContent {
  width: fit-content;
}