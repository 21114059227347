.progressBar {
  display: flex;
  flex-direction: column;
  .label {
    font: var(--font-14-medium);
    color: var(--foreground-2);
    margin-bottom: 15px;
  }
  .progressWrap {
    height: 8px;
    width: 100%;
    border-radius: 12px;
    background: var(--background-3);
    position: relative;
    .barLine {
      position: absolute;
      height: 100%;
      width: 100%;
      background: radial-gradient(100% 2339.91% at 0% 0%, #e571ff 9.59%, #ff8933 100%);
      border-radius: 12px;
      background-size: 150% 150%;
      transform: translate3d(0, 0, 0);
      animation: bar 5s ease infinite;
      animation-duration: 2s;
    }
    &+ * {
      margin-top: 16px;
    }
  }
  .amount {
    display: flex;
    justify-content: space-between;
    .actual {
      font: var(--font-14-extrabold);
      color: var(--foreground-3);
    }
    .total {
      font: var(--font-14-medium);
      color: var(--foreground-2);
    }
  }
}
@keyframes bar {
  0% {
    background-position: 0% 20%;
  }
  50% {
    background-position: 100% 70%;
  }
  100% {
    background-position: 0% 70%;
  }
}
