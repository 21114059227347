.button {
  white-space: nowrap;
  cursor: pointer;

  .text,
  .textMobile {
    color: var(--white);
    opacity: 0.7;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 1;
    }
  }

  .text {
    font: var(--font-14-semibold);
  }

  .textMobile {
    font: var(--font-24-bold);
  }
}
