@import './src/styles/mixins';

.wallet {
  @include contentWrapper(var(--layout-padding-major));

  .content {
    @include contentPage(60px);
  }
}

.mobileRoot {
  width: 100%;
}

.tabs {
  margin: 20px 0;
}

.tabButtons {
  margin-top: 10px;
}
