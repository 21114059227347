.rightBar {
  max-width: 905px;
  margin-top: 59px;
  width: 100%;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .tab {
    &:last-child {
      justify-content: flex-end;
      flex: 1;
    }
  }
}
