.container {
  display: flex;
  flex-direction: column;
  gap: 30px;

  width: 100%;
  padding-top: 25px;

  .top {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.backButton {
  display: flex;
  align-items: center;
  gap: 16px;

  font: var(--font-16-semibold);
  color: var(--white);
  cursor: pointer;
}

.tabsButton {
  display: flex;
  align-items: center;
  gap: 10px;

  .button {
    flex-grow: 1;
    height: 50px;
    font: var(--font-14-semibold);
  }
}
