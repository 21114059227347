.result {
  font: var(--font-30-extrabold);
  color: var(--white);
}

.text {
  font: var(--font-14-medium);
  color: var(--foreground-2);
}

.diaryWeekContainer {
  display: flex;
  flex-direction: column;
}

.positive {
  color: var(--green-text);
}

.negative {
  color: var(--second-red-text);
}
