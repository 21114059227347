.weekResultBlock {
  width: 100%;
  min-height: 168px;
}

.headerWeek {
  display: flex;
  gap: 10px;
}

.weekResult {
  font: var(--font-25-bold);
  color: var(--foreground-2);
}

.week {
  font: var(--font-25-bold);
  color: var(--white);
}

.weekBorder {
  width: 5px;
  height: 26px;
  border-radius: 2px;
  margin-top: 4px;
}

.positive {
  background: var(--green-text);
}

.emptyList {
  display: flex;
  justify-content: center;
  align-items: center;
}

.switchBlock .btnDisabled {
  cursor: not-allowed;
}

.negative {
  background: var(--second-red-text);
}

.without {
  background: var(--white);
}

.weekDataContent {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.weekData {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}

.weekResultContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 39px;
}

.result {
  font: var(--font-30-extrabold);
  color: var(--white);
}

.text {
  font: var(--font-14-medium);
  color: var(--foreground-2);
}

.switchBlock {
  display: flex;
}

.filter {
  margin-right: 16px;
}

.arrowLeft {
  width: 40px;
  height: 40px;
  border-radius: 50% 4px 4px 50%;
  margin-right: 3px;
  background: var(--background-3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.arrowRight {
  width: 40px;
  height: 40px;
  border-radius: 4px 50% 50% 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background-3);
  cursor: pointer;
}

.arrow {
  width: 6px;
}

.btnBlock {
  display: flex;
  flex-direction: column;
}

.close {
  width: 40px;
  height: 40px;
  padding: 0;
  background: var(--background-3);
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  margin-left: 16px;
  cursor: pointer;
}

.closeHint {
  left: -2px !important;
}
.closeHint > div {
  justify-content: center;
}

.rightArrowHint {
  left: -5px !important;
}

.rightArrowHint > div {
  justify-content: center;
}

.leftArrowHint > div {
  justify-content: center;
}
