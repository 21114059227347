.topBarLabels {
  display: flex;
  gap: 40px;
  margin-top: 30px;
}

.labelWrap {
  display: flex;
  gap: 10px;
}

.amount {
  font: var(--font-14-bold);
  color: var(--foreground-3);
}

.symbol {
  color: var(--foreground-2);
}
