.root {
  font: var(--font-14-medium);
  width: 420px;
  max-width: 100%;
  text-align: left;
}

.heading {
  margin: 0 0 30px;
  font: var(--font-20-bold);
  display: flex;
}

.subheading {
  margin-bottom: 15px;
  font: var(--font-17-semibold);
}

.form {
  margin: 20px 0 0;
}

.selectToggle {
  font: var(--font-17-semibold);
}

.selectLabel {
  color: var(--foreground-2);
}

.selectDropdown {
  width: 100%;
}

.horizontalControls {
  display: flex;
  justify-content: space-between;
}

.submitLine {
  margin-top: 30px;
}

.btnWrapper .btnSubmit {
  margin-top: 0px;
}

.btnWrapper .btnSubmitWithMargin {
  margin-top: 10px;
}

.errorMessage {
  display: flex;
  flex-direction: column;
  color: var(--second-red-text);
  gap: 2px;
}

.submitBtn {
  flex-basis: 48%;
  padding-left: 2px;
  padding-right: 2px;
}

.formItem {
  & + & {
    margin-top: 30px;
  }
  & + .formItemSmall {
    margin-top: 30px;
  }
}

.formItemSmall {
  & + & {
    margin-top: 10px;
  }

  & + {
    .formItem {
      margin-top: 30px;
    }
  }
}

.formItemMedium {
  & + & {
    margin-top: 15px;
  }

  & + {
    .formItem {
      margin-top: 30px;
    }
  }
}

._withMargin {
  margin-top: 30px;
}

.error {
  font: var(--font-14-semibold);
  color: var(--second-red-text);
}

.formCashback {
  width: 100%;
  padding-top: 20px;
}

.inputEllipsis {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: var(--foreground-2);
  label {
    color: var(--foreground-2);
  }
}

.cashbackInput {
  max-width: 100px;
}

.cashbackInputContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 10px;
}

.infoWrap {
  position: relative !important;
}

.btnWide {
  width: 100%;
}

.redText {
  &, &:not(:disabled) {
  color: var(--red-text);
  }
}

.closeControl {
  width: 30px;
  height: 30px;
  margin-left: auto;
  background: var(--background-3);
  &:hover {
    background: var(--btn-hover-on-bg);
  }
  svg {
    width: 8px;
    height: 8px;
  }
}

.globalError {
  color: var(--second-red-text);
}

.globalErrorCashback {
  color: var(--second-red-text);
  padding-bottom: 15px;
}

.removeMemberBlock {
  width: 100%;
  padding-top: 20px;
}

.removeMemberContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font: var(--font-14-semibold);
}

.removeMemberId {
  width: 100%;
  max-width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.btnContainer {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  gap: 15px;
}

._colorRed {
  color: var(--second-red-text);
}