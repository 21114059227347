.root {}

.select {
  margin-top: 20px;
  text-align: left;
}

.selectContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.selectLabel {
  color: var(--foreground-2);
}

.dropdown {
  max-height: 250px !important;
  left: -13px;
}

.input {
  width: 100%;
  margin: 20px 0 0;
}

.inputLabel {
  position: relative;
  top: 15px;
  margin-bottom: 0;
}

.inputContainer {
  width: 100%;
}

.inputContainer .input {
  margin-bottom: 10px;
}

.checkBoxContainer {
  width: 100%;
  padding-top: 20px;
}

.checkboxItem {
  display: flex;
  align-items: center;
}