.tigerWidgetDistribution,
.tigerWidgetDistribution * {
  box-sizing: content-box;
}

.tigerWidgetDistribution {
  min-width: 200px;
  display: block;
  position: relative;
  text-align: center;
  text-decoration: none;
  color: var(--foreground-2);
  min-height: 152px;
  padding-top: 16px;
  text-transform: none;
  letter-spacing: normal;
  white-space: normal;
  background: var(--background-2);
  border: 1px solid var(--background-3);
  border-radius: 16px;
  overflow: hidden;
  backface-visibility: hidden;
  transform: translateZ(0);
}

.tigerWidgetDistributionCircle {
  width: 76px;
  height: 76px;
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  margin: 0 auto;
}
.tigerWidgetDistributionCircle svg {
  width: 100%;
  height: 100%;
  display: block;
  stroke: var(--background);
  stroke-width: 1px;
  stroke-linecap: round;
}

.text {
  font: var(--font-14-semibold);
}
.dotText {
  color: var(--white);
  font: var(--font-10-medium);
}
.withoutValue {
  color: '';
}
.tigerWidgetDistributionCircle svg {
  transition: all 0.5s ease;
  filter: grayscale(100%);
  opacity: 0.3;
}
.tigerWidgetDistributionReady .tigerWidgetDistributionCircle svg {
  filter: grayscale(0%);
  opacity: 1;
}
.tigerWidgetDistributionValue {
  position: absolute;
  top: 44px;
  white-space: nowrap;
  color: var(--foreground-2);
  transition: color 0.5s ease;
}
.tigerWidgetDistributionValueLeft {
  right: 48%;
  margin-right: 47px;
}
.tigerWidgetDistributionValueRight {
  left: 50%;
  margin-left: 47px;
}

.tigerWidgetDistributionValueGreen {
  color: #93e9b9;
  font: var(--font-14-bold);
}
.tigerWidgetDistributionValueRed {
  color: #ff8a77;
  font: var(--font-14-bold);
}

.tigerWidgetDistributionLegend {
  display: inline-flex;
  align-items: center;
  vertical-align: top;
  transition: color 0.5s ease;
  text-transform: uppercase;
  background: var(--btn-hover-on-bg);
  padding: 4px 10px;
  border-radius: 13px;
  gap: 4px;
  margin-top: 14px;
}
.tigerWidgetDistributionReady .tigerWidgetDistributionLegend {
  color: var(--white);
}
.tigerWidgetDistributionLegendDot {
  flex: 0 0 auto;
  position: relative;
  background: var(--foreground-2);
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.tigerWidgetDistributionLegendDot:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: opacity 0.5s ease;
  opacity: 0;
}
.tigerWidgetDistributionLegendDotGreen {
  background: linear-gradient(38.61deg, #75e6ff 0%, #a3ea90 47.16%, #ffb951 100%);
}
.tigerWidgetDistributionLegendDotRed {
  background: linear-gradient(38.61deg, #fcc4ed 0%, #fea471 48.21%, #ff627e 100%);
}
.tigerWidgetDistributionReady .tigerWidgetDistributionLegendDot:before {
  opacity: 1;
}
.tigerWidgetDistributionLegendLine {
  flex: 0 0 auto;
  width: 1px;
  height: 8px;
  background: var(--foreground-1);
  margin: 0 6px;
}
