@import "./src/styles/mixins";

.fieldInput{
  margin-bottom: 47px;
  position: relative;
}
.coinSelect{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
}

.course{
  text-align: center;
  color: var(--foreground-3);
  padding-bottom: 30px;
}

.button {
  width: 100%;
  height: 70px;
}

.noFunds {
  margin: 30px 0 15px;
  font: var(--font-20-bold);
  color: var(--white);
  text-align: center;
}
