.leftBar {
  flex-basis: 305px;
  flex-shrink: 0;
}

.widget {
    color: var(--foreground-2);
    .header {
        margin: 0 0 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font: var(--font-20-bold);
        color: var(--white);
    }
    .headerIcon {
        margin-left: 10px;
        * {
            vertical-align: top;
        }
    }
    .performText {
        color: var(--white);
    }
    .performTop {
        display: flex;
        justify-content: space-between;
        margin:  0 0 15px;
    }
    .performCount {
        margin-left: 10px;
        font: var(--font-30-extrabold);
        color: var(--foreground-1);
    }
    .list {
        margin-bottom: 0;
    }
    .control {
        .btn:first-child {
            margin-top: 30px;
        }
    }
    .btn {
        width: 100%;
        & + .btn {
            margin-top: 15px;
        }
    }
}

.indicator {
    display: flex;
    justify-content: space-between;
}

.indicatorItem {
    height: 8px;
    border-radius: 4px;
    background: var(--foreground-1);
    &.active {
        background: var(--gradient-foreground);
    }
}

.gradientText {
color: transparent;
-webkit-background-clip: text;
background-image: var(--gradient-foreground);
}