.leftBar {
  position: relative;
  width: 305px;
  min-width: 305px;
  display: flex;
  flex-direction: column;
  ._white {
    color: var(--white);
  }
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 305px;
  min-height: 460px;
}

.title {
  padding: 30px 0;
}
