.skeletonWallet {
  display: flex;
  background: var(--background-2);
  overflow: hidden;
  position: relative;
  .between {
    margin-top: 46px;
    position: relative;
    z-index: 6;
    max-width: 70px;
    width: 100%;
    background: var(--background-1);
    display: flex;
    flex: 1 0 auto;
  }

  &:before {
    content: '';
    position: absolute;
    width: 30px;
    height: 700px;
    top: 0;
    left: 0;
    background: #1B1824;
    box-shadow: 0 0 50px 50px #1B1824;
    animation: animate 2.2s linear infinite;
    z-index: 1;
  }
}


.skeletonWalletLeftBar {
  margin-top: 46px;
  width: 305px;
  min-width: 305px;
  display: flex;
  flex-direction: column;
  background: var(--background-2);
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 30px;
    height: 700px;
    top: 0;
    left: 0;
    background: #1B1824;
    box-shadow: 0 0 50px 50px #1B1824;
    animation: animate 2.2s linear infinite;
    z-index: 1;
  }

  .label {
    font: var(--font-14-medium);
    color: var(--foreground-2);
    padding-bottom: 9px;
    background: var(--background-1);
    position: relative;
    z-index: 5;
  }
  .wrap {

    .itemWrap {
      overflow: hidden;
      position: relative;
      z-index: 2;
      .item {
        width: 195px;
        height: 19px;
        border-radius: 30px;
        box-shadow: 0 0 0 200px var(--background-1);
        margin-bottom: 37px;
      }
    }
    .buttonWrap {
      background: var(--background-1);
      padding-bottom: 24px;
      position: relative;
      z-index: 2;
      .button {
        width: 100%;
        height: 40px;
        border-radius: 10px;
        background: var(--background-2);
        color: var(--background-3);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .items {
      overflow: hidden;
      display: grid;
      grid-template-columns: 1fr 1fr;
      position: relative;
      z-index: 1;
      .itemWrap {
        overflow: hidden;
        display: flex;
        .item {
          border-radius: 9px;
          box-shadow: 0 0 0 300px var(--background-1);
          margin-bottom: 15px;
          height: 11px;
        }
        &:first-child {
          .item {
            width: 87px;
          }
        }
        &:nth-child(2) {
          justify-content: flex-end;
          .item {
            width: 55px;
          }
        }
        &:nth-child(3) {
          .item {
            width: 146px;
          }
        }
        &:nth-child(4) {
          justify-content: flex-end;
          .item {
            width: 45px;
          }
        }
        &:nth-child(5) {
          .item {
            width: 130px;
          }
        }
        &:nth-child(6) {
          justify-content: flex-end;
          .item {
            width: 61px;
          }
        }
      }
    }
  }
  .bottomBlock {
    height: 100%;
    background: var(--background-1);
    position: relative;
    z-index: 10;
  }
}

.skeletonWalletRightBar {
  margin-top: 46px;
  max-width: 905px;
  width: 100%;
}

.top {
  z-index: 5;
  position: relative;
  overflow: hidden;
  background: var(--background-2);

  &:before {
    content: '';
    position: absolute;
    width: 30px;
    height: 700px;
    top: 0;
    left: 0;
    background: #1B1824;
    box-shadow: 0 0 50px 50px #1B1824;
    animation: animate 2.2s linear infinite;
    z-index: -1;
  }

  .labelWithQ {
    background: var(--background-1);
    padding-bottom: 8px;
  }
  .topItems {
    display: grid;
    grid-template-columns: 348px 1fr 348px;
    justify-content: space-between;
    .leftPart {
      display: flex;
      align-items: center;
      .itemWrapper {
        position: relative;
        overflow: hidden;
        padding-right: 39px;
        display: flex;
        align-items: center;
        height: 100%;
        .item {
          width: 195px;
          border-radius: 9px;
          box-shadow: 0 0 0 300px var(--background-1);
          height: 19px;
        }
      }

      .circles {
        overflow: hidden;
        position: relative;
        z-index: 10;
        display: flex;
        .circleWrap {
          overflow: hidden;
          &:not(:first-child) {
            margin-left: -2px;
          }
          .circle {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            box-shadow: 0 0 0 300px var(--background-1);
            border: 2px solid var(--background-1);
          }
        }
      }
    }
    .center {
      flex-basis: 100%;
      background: var(--background-1);
      height: auto;
    }
    .rightPart {
      overflow: hidden;
      display: flex;
      align-items: center;
      width: 100%;
      .itemWrap {
        overflow: hidden;
        height: 100%;
        padding-left: 7px;
        .item {
          width: 109px;
          height: 40px;
          border-radius: 10px;
          box-shadow: 0 0 0 300px var(--background-1);
        }
      }
    }
  }
}

.bottom {
  overflow: hidden;
  position: relative;
  background: var(--background-2);
  margin-top: 50px;

  &:before {
    content: '';
    position: absolute;
    width: 30px;
    height: 700px;
    top: 0;
    left: 0;
    background: #1B1824;
    box-shadow: 0 0 50px 50px #1B1824;
    animation: animate 2.2s linear infinite;
    z-index: 1;
  }
  .title {
    font: var(--font-25-extrabold);
    color: var(--foreground-1);
    background: var(--background-1);
    position: relative;
    z-index: 10;
    padding-bottom: 23.5px;
  }
  .bottomContent {
    overflow: hidden;
    z-index: 10;
    position: relative;
    &:not(:last-child) {
      padding-bottom: 10px;
    }

    .blockWrap {
      border-radius: 15px;
      padding: 20px;
      box-shadow: 0 0 0 300px var(--background-1);
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 15% auto;
      justify-content: space-between;
      align-items: center;
      position: relative;
      z-index: 5;

      .left {
        display: flex;
        .circle {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: var(--background-3);
          margin-right: 20px;
        }
        .itemWrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .item {
            width: 179px;
            height: 15px;
            border-radius: 9px;
            background: var(--background-3);
            &:last-child {
              width: 103px;
              height: 11px;
            }
          }
        }
      }
      .itemWrap {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .item {
          width: 103px;
          height: 15px;
          border-radius: 9px;
          background: var(--background-3);
          &:last-child {
            width: 45px;
            height: 11px;
          }
        }

        &:last-child {
          align-items: flex-end;
          .item {
            width: 137px;
            height: 15px;
            border-radius: 9px;
            background: var(--background-3);
            &:last-child {
              width: 50px;
              height: 11px;
            }
          }
        }
      }
    }
  }
}

@keyframes animate {
  0% {
    transform: translate(-100px) skewX(-10deg);
  }
  30% {
    transform: translate(100vw) skewX(-10deg);
  }
  100% {
    transform: translate(100vw) skewX(-10deg);
  }
}
