.root {
  width: 360px;
}

.icon {
  margin-bottom: 15px !important;
}


.text {
  max-width: 280px;
  font: var(--font-17-semibold) !important;
}