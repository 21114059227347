.root {
  padding-top: 40px;
  width: 100%;
}

.headerBlock {
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
}

.headerText {
  color: var(--white);
  font: var(--font-30-extrabold);
}

.errorContainer {
  position: relative;
  flex-grow: 1;
}
.error.error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  margin: 0;
}

.empty {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
