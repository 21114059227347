.ticker {
  display: flex;
  align-items: center;
}

.symbolName {
  margin-left: 6px;
  margin-right: 4px;

  font: var(--font-12-semibold);
  color: var(--white);
  line-height: 17px;
}
