.stickySection {
  position: sticky;
  top: 70px;
  z-index: 100;

  display: flex;
  flex-direction: column;
  gap: 30px;

  background-color: var(--background-1);
  padding: 30px 0 20px;
  height: 80px;
}

.gain.mobileTabs {
  position: absolute;
  display: flex;
  right: -20px;
  left: -20px;
  overflow: hidden;

  .content {
    min-width: 100%;
  }

  .item:first-child {
    margin-left: 20px;
  }

  .item:last-child {
    margin-right: 20px;
  }

  .item {
    margin: 0;
    padding: 0;
  }

  .tab {
    display: inline-flex;
    gap: 5px;
    align-items: flex-start;

    white-space: nowrap;
    font: var(--font-20-extrabold);
    color: var(--foreground-1);
    cursor: pointer;
  }

  .active {
    color: var(--white);
    cursor: default;
  }
}

.checkbox {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding-bottom: 20px;

  font: var(--font-14-semibold);
  line-height: 20px;
  color: var(--white);
  white-space: nowrap;
}

[data-amount]::after {
  content: attr(data-amount);
  display: block;
  font: var(--font-14-extrabold);
}