.cashbackRightBarHeader {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.left {
  span {
    color: var(--foreground-3);
    font: var(--font-40-extrabold);
  }
  .symbol {
    color: var(--foreground-3);
    font: var(--font-30-extrabold);
  }
  strong {
    background-image: var(--gradient-foreground);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font: var(--font-40-extrabold);
  }
}

.noWrap {
  white-space: nowrap;
}

.work {
  padding-left: 30.5px;
  padding-right: 30.5px;
  min-width: 150px;
}

.right {
  display: flex;
  align-items: center;
  gap: 15px;
  font: var(--font-12-semibold);
  color: var(--foreground-2);
  white-space: nowrap;
  text-align: right;
}

.accent {
  color: var(--foreground-3);
}

.description {
  font: var(--font-14-medium);
  color: var(--foreground-2);
}
