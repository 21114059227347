.table {
  width: 100%;
  &._themeDefault {
    text-align: right;
    border-collapse: collapse;
    .th,
    .td {
      text-align: right;
    }
    .th:first-child,
    .td:first-child {
      text-align: left;
    }
  }
}

.row {
  display: flex;
  justify-content: space-between;
  font: var(--font-14-semibold);
  line-height: 20px;
}

.rowGrid {
  display: grid;
  grid-template-columns: 1fr 50px 50px;
  grid-gap: 10px;
  color: var(--foreground-2);
}

.th {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
}
