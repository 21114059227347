.history {
  padding: 20px 30px 20px 20px;
  border-radius: 15px;
  background: var(--background-2);
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 15% auto;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  &:hover {
    background: var(--btn-hover);
  }
  .left {
    display: flex;
    align-items: center;
    gap: 20px;
    svg {
      width: 30px;
      height: 30px;
    }
    .currencyWrap {
      display: flex;
      flex-direction: column;
      color: var(--foreground-2);
      .currency {
        font: var(--font-14-bold);
        .baseCurrency, .percent {
          color: var(--foreground-3);
        }
      }
      .date {
        font: var(--font-12-semibold);
        margin-top: 5px;
      }
    }
  }
  .item {
    .amount {
      color: var(--foreground-3);
      font: var(--font-14-bold);
      margin-bottom: 5px;
    }
    .text {
      font: var(--font-12-semibold);
      color: var(--foreground-2);
    }
    .new {
      color: var(--green-text);
      font: var(--font-14-bold);
    }
    .newMinus {
      @extend .new;
      color: var(--red-text);
    }
    &.last {
      text-align: right;
    }
  }
}
