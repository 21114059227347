.heading {
  font: var(--font-20-bold);
  color: var(--white);
}

.code {
  color: var(--foreground-2);
  display: flex;
  flex-direction: column;
}

.codeBlock {
  padding: 20px 0;
}

.referral {
  color: var(--foreground-2);
  padding-bottom: 10px;
}

.refCode {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.referralContainer {
  padding-bottom: 20px;
}

.button {
  width: 100%;
}

.membersCount {
  color: var(--foreground-2);
}

.copy {
  --background: var(--background-3);
}

.infoAccent {
  font: var(--font-14-bold);
  color: var(--white);
  white-space: nowrap;
}

.members {
  font: var(--font-40-extrabold);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: var(--gradient-foreground);
  padding-right: 10px;
}

.count {
  color: var(--white);
}


.btnWrapp {
  padding: 5px 0;
  display: flex;
  flex-direction: column;
}