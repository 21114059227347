.contentListItemsWrap {
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .contentListItems {
    position: relative;
  }
  .bottomGradientWrap {
    display: flex;
    justify-content: flex-end;
    position: sticky;
    bottom: 0;
    .bottomGradient {
      width: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(6, 4, 11, 0.24) 36.7%, #080610 66.09%);
      height: 80px;
      z-index: 20;
    }
  }
}

