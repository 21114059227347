:root {
  --desktop: 1441px;
  --tablet: 1194px;
  --tablet-mini: 1025px;

  --layout-padding-default: 40px 0 76px;
  --layout-padding-major: 87px 0 76px;
  --layout-sidebar-gap-default: 70px;
  --layout-sidebar-gap-major: 130px;

  --mobile-header-height: 70px;
}
