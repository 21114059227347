.wrapper {
  box-sizing: border-box;
  color: var(--foreground-2);
  width: 100%;
}

.title {
  padding-bottom: 30px;
}

.content {
  position: relative;
  width: 100%;
  height: 320px;
  padding: 10px;
  overflow: hidden;
  box-sizing: border-box;
}

.background {
  display: flex;
  position: absolute;
  top: -8px;
  right: -4px;
  background: #1C1A24;
  z-index: 0;
  height: 110%;
}

.visible {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  box-sizing: border-box;
  z-index: 10;
  height: 100%;
}

.logo {
  padding-bottom: 10px;
}

.btnWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
}

.personalWrapp {
  background-color: #1C1A24;
}

.img {
  max-width: 100%;
}