.keys {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: var(--background-2);
  font: var(--font-12-semibold);
  color: var(--white);
  padding: 25px 20px 30px 20px;
  border-radius: 20px;
}

.title {
  font: var(--font-20-bold)
}

.statusWrap {
  display: flex;
  flex-direction: column;
  color: var(--foreground-2);
}

.statusTitle {
  font-size: 12px;
}

.status {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-top: 4px;
  font-size: 17px;
}

.statusInfo {
  color: var(--white);
}

.statusSeparator {
  color: var(--white);
}

.statusIcon {
  color: var(--green-text)
}