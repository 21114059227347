.root {
  display: grid;
  grid-template-columns: 3fr 3.2fr 3fr 3.5fr;
  font: var(--font-12-semibold);
  align-items: center;
  color: var(--white);
  gap: 5px;
  justify-content: space-between;

  & > div {
    display: flex;
  }
  & > div:last-child {
    justify-content: flex-end;
  }
}
.wrapper {
  grid-template-columns: 1fr 3.2fr 2fr 3.5fr;
}
