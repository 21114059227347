.root {
  align-self: center;
  text-align: right;
  color: var(--white);
  white-space: nowrap;

  &:hover .title {
    opacity: 1;
  }
}

.title {
  font: var(--font-12-semibold);
  opacity: .7;
  transition: opacity .2s linear;
}

.status {
  font: var(--font-14-semibold);
  line-height: 20px;
}