@import 'src/styles/mixins';


.copyButton {
  --background: var(--background-3);
  --background-hover: var(--foreground-1);
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  //flex: 0 0 30px;
  height: 30px;
  width: 30px;
  background-color: var(--background);
  color: var(--gray-10);
  border-radius: 5px;
  padding: 0;
  border: 0;
  cursor: pointer;
  transition: all .2s linear;

  .paper {
    background-color: var(--background);
    position: absolute;
    width: 8px;
    height: 10px;
    border: 1px solid currentColor;
    box-shadow: 0 0 0 1px var(--background);
    border-radius: 2.5px;
    transition: transform 0.2s linear;
  }

  .paper1 {
    @extend .paper;
    top: calc(50% - 7px);
    left: calc(50% - 3px);
  }

  .paper2 {
    @extend .paper;
    top: calc(50% - 3px);
    left: calc(50% - 7px);
  }
  
  .mark {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    opacity: 0;
    transition: opacity 0.5s;

    .markRect {
      fill: var(--background);
    }
  }

  .markActive {
    opacity: 1;
  }

  &:hover {
    background-color: var(--background);

    .paper {
      background-color: var(--background);
      box-shadow: 0 0 0 1px var(--background);
    }

    .paper1 {
      transform: translate(2px, -1px) rotate(15deg) ;
    }

    .paper2 {
      transform: translate(-2px, 1px) rotate(-15deg) ;
    }

    .markRect {
      fill: var(--background);
    }
  }

}

