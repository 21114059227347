.root {

}

.loader {
  height: 240px;
}

.empty {
  margin-top: 80px;
}