.card {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  background-color: var(--background-3);
  padding: 10px 15px;
  border-radius: 15px;

  // TODO: Если нужна анимация (Возможно поменять цвета)
  //   &:before {
  //     content: '';
  //     position: absolute;
  //     width: 30px;
  //     height: 100px;
  //     top: 0;
  //     left: 0;
  //     background: var(--btn-hover);
  //     box-shadow: 0 0 50px 50px var(--btn-hover);
  //     animation: animate 2.2s linear infinite;
  //     z-index: 1;
  //   }

  .leftPart {
    position: relative;
    z-index: 2;

    display: flex;
    align-items: center;
    gap: 10px;
  }

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--foreground-1);
  }

  .currency {
    width: 100px;
    height: 20px;
    border-radius: 10px;
    background-color: var(--foreground-1);
  }

  .date {
    position: relative;
    z-index: 2;

    width: 60px;
    height: 20px;
    border-radius: 10px;
    background-color: var(--foreground-1);
  }
}

@keyframes animate {
  0% {
    transform: translate(-100px) skewX(-10deg);
  }
  30% {
    transform: translate(100vw) skewX(-10deg);
  }
  100% {
    transform: translate(100vw) skewX(-10deg);
  }
}
