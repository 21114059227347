@import './src/styles/mixins';

.hint {
  position: absolute;
  width: max-content;
  max-width: 240px;
  min-width: 200px;
  padding: 15px;
  border-radius: 15px;
  background: var(--btn-hover-on-bg);
  text-align: center;
  transition: opacity 0.1s linear;
  z-index: 100;

  .hintArrow {
    position: absolute;
    width: 7px;
    height: 18px;

    &::after {
      position: absolute;
      width: 7px;
      height: 18px;
      background-color: var(--btn-hover-on-bg);
      clip-path: polygon(0% 0%, 25% 32%, 50% 42%, 75% 47%, 100% 50%, 75% 53%, 50% 58%, 25% 68%, 0% 100%);
      content: '';
    }
  }

  &[data-popper-placement='bottom'] .hintArrow {
    top: -12px;
    &::after {
      transform: rotate(270deg);
    }
  }

  &[data-popper-placement='left'] .hintArrow {
    right: -3px;
  }

  &[data-popper-placement='top'] .hintArrow {
    bottom: -12px;
    &::after {
      transform: rotate(90deg);
    }
  }

  &[data-popper-placement='right'] .hintArrow {
    left: -10px;
    &::after {
      transform: rotate(180deg);
    }
  }

  .hintText {
    display: flex;
    text-align: center;
    font: var(--font-14-semibold);
    line-height: 20px;
    color: var(--foreground-3);
  }

  .variantIcon {
    margin: 2px 10px 0 0;
  }
}

.smallSize {
  max-width: 100px;
  min-width: 100px;
}
