@import 'src/styles/mixins';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  width: 100%;
  position: relative;
  color: var(--foreground-3);
  width: min-content;
}

.left {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  padding-top: 50px;
}

.backButtonWrapper {
  position: absolute;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  top: 0;
  width: 30px;
  height: 30px;
}

.backButton {
  transform: rotate(90deg);
}

.header {
  font: var(--font-30-extrabold);
  margin-bottom: 20px;
  white-space: nowrap;
  text-align: left;
}

.gradient {
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: var(--gradient-foreground);
}

.instructions {
  font: var(--font-14-medium);
  margin-bottom: 56px;
  text-align: left;
}

.helpText {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
  margin-bottom: 20px;
}

.getApps {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.app {
  display: flex;
  background-color: var(--background-3);
  cursor: pointer;
  padding: 12px 0 12px 18px;
  border-radius: 10px;
  width: 150px;
  height: 55px;
  box-sizing: border-box;
  text-decoration: none;
  transition: background-color 0.2s;
  position: relative;

  .storeQRWrapper {
    display: none;
    position: absolute;
    height: 180px;
    width: 150px;
    left: 0;
    top: -180px;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out, top 0.2s ease-in-out;

    .storeQR {
      transform: translateY(180px);
    }
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--background-1);

      .storeQRWrapper {
        display: block;

        .storeQR {
          transform: translateY(20px);
          animation: animatePosition 0.5s ease-in-out;

          @keyframes animatePosition {
            0% {
              transform: translateY(180px);
            }
            70% {
              transform: translateY(0);
            }
            100% {
              transform: translateY(20px);
            }
          }
        }
      }
    }
  }

  @media (hover: none) {
    &:active {
      background-color: var(--background-1);

      .storeQRWrapper {
        display: block;

        .storeQR {
          transform: translateY(20px);
          animation: animatePosition 0.5s ease-in-out;

          @keyframes animatePosition {
            0% {
              transform: translateY(180px);
            }
            70% {
              transform: translateY(0);
            }
            100% {
              transform: translateY(20px);
            }
          }
        }
      }
    }
  }
}

.appIcon {
  padding-top: 8px;
  color: var(--white);
}

.appText {
  text-align: left;
  padding-left: 15px;
}

.appText1 {
  font: var(--font-10-semibold);
  color: var(--foreground-2);
}

.appText2 {
  font: var(--font-14-medium);
  color: var(--foreground-3);
}

.right {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background-3);
  border-radius: 20px;
  padding: 20px;
}

.tabs {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: var(--background-2);
  padding: 5px;
  border-radius: 12px;
}

.tab {
  background-color: transparent;
  border: 0;
  color: var(--foreground-3);
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 24px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: var(--background-3);
  }
}

.tabActive {
  background-color: var(--foreground-1);
}

.qrWrapper {
  box-sizing: border-box;
}

.qr {
  border-radius: 10px;
  margin: 10px auto 20px;
  box-sizing: border-box;
  width: 140px;
  height: 140px;
  background-position: center;
  padding: 5px;
  background: var(--white);
}

.instruction {
  font: var(--font-17-semibold);
  margin: 20px 0 3px 0;
}

.codeWords {
  width: 250px;
  height: 100px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font: var(--font-12-semibold);

  .codeWord {
    display: inline-block;
    background-color: var(--foreground-1);
    border-radius: 5px;
    position: relative;
    padding: 5px 8px;
    margin-right: 3px;
    height: 25px;
    //margin: 13px 1px 0 1px;

    &:before {
      content: '';
      position: absolute;
      background-color: var(--foreground-1);
      border-radius: 50%;
      width: 4px;
      height: 4px;
      top: 10px;
      left: -2px;
    }

    &:after {
      content: '';
      position: absolute;
      background-color: var(--foreground-1);
      border-radius: 50%;
      width: 4px;
      height: 4px;
      top: 10px;
      right: -2px;
    }
  }

  .codeWord:first-child {
    &:before {
      display: none;
    }
  }

  .codeWord:nth-child(8) {
    &:after {
      display: none;
    }
  }
}

.copyButton {
  transform: translate(2px, -4px);
}

.mobile.root {
  width: 100%;
  justify-content: center;

  .left {
    width: 100%;
    padding: 0;
    align-items: center;
  }

  .header {
    font: var(--font-20-bold);
    text-align: center;
    margin-bottom: 10px;
    white-space: initial;
  }

  .helpText {
    font: var(--font-14-semibold);
    color: var(--foreground-3);
    line-height: 20px;
    margin-bottom: 30px;
  }

  .getApps {
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
  }

  .app {
    padding: 10px 17px;
    gap: 13px;
    align-items: center;
  }

  .appIcon {
    padding-top: 4px;
  }

  .appText {
    padding-left: 0px;
  }

  .appText2 {
    line-height: 20px;
  }

  .actionButtons {
    display: flex;
    flex-direction: column;
    gap: 20px;

    width: 100%;
    margin-top: 30px;
  }

  .right {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
  }

  .instructions {
    font: var(--font-14-semibold);
    text-align: center;
    line-height: 20px;
    margin-bottom: 30px;
  }

  .qrWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .helpText {
      max-width: 310px;
      font: var(--font-12-semibold);
      line-height: 17px;
      margin-bottom: 20px;
    }
  }

  .codeWords {
    height: auto;
    gap: 10px 0;
  }

  .copyButton {
    transform: none;
    display: inline-block;
    width: 25px;
    height: 25px;
  }
}

.mobilePopup {
  padding: 30px 20px !important;
  flex-grow: 1;
}
