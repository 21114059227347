.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 7px;
  background-color: var(--background-3);
  transition: background-color 0.2s linear;
  cursor: pointer;

  &:hover {
    background-color: var(--btn-hover-on-bg);
  }
}

.popup {
  min-width: 360px;
  background-image: url('../../../assets/png/qr-popup-bg.jpg') !important;
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;
}

.qrWrap {
  height: 166px;
  width: 166px;
  padding: 10px;
  background: var(--white);
  margin-bottom: 30px;
  border-radius: 20px;
}

.mobile {
  // Костыль для усиления веса у типа
  &.subHeader.subHeader.subHeader {
    margin: 0;
    font: var(--font-25-bold);
    line-height: 33px;
  }

  // Костыль для усиления веса у типа
  &.subExplanation.subExplanation.subExplanation {
    font: var(--font-14-medium);
    line-height: 20px;
  }
}
