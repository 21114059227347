:root {
  //Colors
  // 
  --background-1: #080610;
  --background-2: #12101a;
  --background-3: #1c1a24;
  --background-4: #18171f;
  --background-light-dark: #1d1b26;
  --foreground-1: #37363e;
  --foreground-2: #676573;
  --foreground-3: #fafafa;
  --foreground-4: #838287;

  --background-2-80: rgba(18, 16, 26, 0.8);

  --warning-color: #ffcd1c;
  --danger-color: #ff4016;
  --black-80: rgba(0, 0, 0, 0.8);
  --white: #ffffff;
  --white-10: rgba(255, 255, 255, 0.1);
  --gray-5: #eaeaea;
  --gray-10: #d9d9d9;
  --gray-90: #011119;
  --green-text: #1acc81;
  --negative-text: #fb5e5e;
  --red-text: #c2634e;
  --second-red-text: #f97052;
  --third-foreground: #ff8933;
  --gray-text: #7ea1b3;
  --general-gray: #89859d;
  --second-green-text: #0de7b2;
  --second-purple-text: #e571ff;
  --blue-windows: #2f82ff;
  --green-70: #0F8E58;
  --red-70: #AA422B;

  //Interaction colors
  --btn-hover: #1b1824;
  --btn-hover-on-bg: #262430;
  --white-hover: #eaeaea;
}
