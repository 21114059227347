.root {
    font: var(--font-14-medium);
}

.heading {
    font: var(--font-20-extrabold);
    color: var(--white);
    margin: 0 0 10px;
}

.form {
    margin: 25px 0 0;
}

.controls {
    margin: 20px 0 0;
}

.button {
    width: 100%;
    & + & {
        margin-top: 20px;
    }
}

.rmMessage {
    display: flex;
    align-items: center;
    margin: 0 0 12px;
}

.rmIcon {
    margin: 0 4px 0 0;
}

.subtitle {
  color: var(--white);
  font: var(--font-14-medium);
}