.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header {
  display: flex;
  padding: 0px 5px;
  gap: 36px;
}

.resultContainer {
  display: flex;
  gap: 10px;
}

.leftBlock {
  min-width: 168px;
  width: 100%;
  min-height: 177px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: var(--background-3);
  padding: 15px;
  border-radius: 10px;
}

.fontSmall {
  font: var(--font-20-extrabold);
  padding-bottom: 8px;
}

.line {
  line-height: 17px;
  margin-top: 2px;
}

.rightBlock {
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 142px;
  min-height: 168px;
  padding: 15px;
  background: var(--background-3);
  border-radius: 10px;
}

.countSize {
  font: var(--font-14-bold);
}
.profit {
  min-width: 153px;
}
