.list {
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1.5fr 440px 1.5fr;
  gap: 40px;
  width: 100%;
  padding: 0 10px;

  & > *:last-child {
    text-align: right;
  }
  & div {
    padding: 15px 20px;
  }

  &:nth-child(odd) {
    background: transparent;
  }
  &:nth-child(even) {
    background: var(--background-2);
  }
}

.note {
  font: var(--font-14-bold);
  color: var(--foreground-2);
}

.cryptocurrency {
  font: var(--font-17-semibold);
  color: var(--white);
}

.network {
  font: var(--font-14-bold);
  color: var(--white);
}

.address {
  font: var(--font-14-bold);
  color: var(--white);
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.edit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.closeButton {
  height: 30px;
  width: 30px;
  padding: 0;
}
