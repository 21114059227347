.cardItem {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.value {
  color: var(--white);
  font: var(--font-14-extrabold);
}

.text {
  color: var(--foreground-2);
  font: var(--font-12-medium);
}

.positive {
  color: var(--green-text);
}

.negative {
  color: var(--second-red-text);
}
