.month {
  color: var(--white);
  font: var(--font-20-medium);
  padding-bottom: 16px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 12px;
  min-height: 39px;
  width: max-content;
  cursor: pointer;
}

.cardBlock {
  display: flex;
  gap: 10px;
  overflow: hidden;
  padding-bottom: 30px;
  width: 100%;
}

.btnContainer {
  position: relative;
}
.analyzeMonth {
  display: none;
}

.month:hover .analyzeMonth {
  display: flex;
  cursor: pointer;
  color: var(--white);
  font: var(--font-14-semibold);
  align-items: center;
  gap: 15px;
}

.month:hover {
  color: var(--foreground-2);
}

.btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 1111;
  width: 30px;
  height: 30px;
  background: var(--background-3);
  top: 180px;
}

.arrowRight {
  right: 0;
  top: 200px;
  cursor: pointer;
  background: var(--btn-hover);
}

.arrowLeft {
  left: 0;
  cursor: pointer;
  background: var(--btn-hover);
}
