.wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  & > * {
    flex: 1 1 0;
	min-width: 40%;
  }
}

.skeleton {
  height: 45px;
  border-radius: 10px;
  background-color: var(--foreground-1);
}
