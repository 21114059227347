.container {
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 25px;
}

.popup.popup {
  min-width: 900px;
  max-width: 100%;

  padding: 0px !important;
  border-radius: 30px;
}

.title {
  font: var(--font-20-extrabold);
}

.closeBtn {
  width: 40px;
  height: 40px;
}

.info {
  display: grid;
  grid-template-columns: .9fr 1.2fr .9fr 1fr;
  gap: 10px;

  width: 100%;
  padding: 25px;
  border-top: 1px solid var(--background-3);
  border-bottom: 1px solid var(--background-3);
}

.infoTitle {
  color: var(--foreground-2);
  font: var(--font-14-medium);
}

.infoValue {
  color: var(--foreground-3);
  font: var(--font-14-semibold);
}

.infoColumn {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.infoColumnRight {
  align-items: flex-end;
}

.infoColumnLeft {
  align-items: flex-start;
}

.table {
  width: 100%;
  padding: 25px;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.tableSpacing.tableSpacing {
  border-collapse: separate;
  border-spacing: 0 10px;
}

.th.th {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
  padding: 0 20px;
}

.row.row.row {
  height: 54px;
  font: var(--font-12-semibold);
  line-height: 17px;

  & td {
    background-color: var(--btn-hover);
    padding: 10px 20px;
    color: var(--white);
  }

  & td:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  & td:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}

.alignRight {
  text-align: end;
}

.quote {
  color: var(--foreground-2);
}

.nowrap {
  white-space: nowrap;
}