.root {
  position: relative;

  &:hover {
    .popup {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: var(--background-3);
  font: var(--font-12-semibold);
  text-transform: uppercase;
  color: var(--white);
  cursor: pointer;
}

.popup {
  position: absolute;
  top: 100%;
  left: 20px;
  min-width: 200px;
  padding: 10px 0;
  transform: translate(-50%, 0);
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s ease;
}

.popupContent {
  padding: 15px;
  border-radius: 20px;
  background-color: var(--background-2);
}

.language {
  padding: 10px;
  border-radius: 10px;
  font: var(--font-14-extrabold);
  color: var(--white);
  cursor: pointer;
  transition: all 0.2s linear;

  &.active {
    background-color: var(--btn-hover);
    cursor: default;
  }

  &:hover {
    color: var(--gray-5);
    background-color: var(--btn-hover);
  }

  &:not(:first-child) {
    margin-top: 5px;
  }
}
