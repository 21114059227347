.header {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1.2fr 32px 1.5fr 30px;
  gap: 10px;
  margin: 20px 0 10px;
  padding: 0 10px;
  font: var(--font-12-semibold);
  color: var(--foreground-2);

  & > *:nth-child(6) {
    text-align: right;
  }
}

.loader {
  margin: 60px auto;
}

.empty {
  margin-top: 10px;
  font: var(--font-20-bold);
  color: var(--foreground-1);
}