@import 'src/styles/mixins';

.popup {
  padding: 30px 40px 40px 40px !important;
  color: var(--foreground-3);
  max-width: 410px;
  margin: auto;

  &.wide {
    width: 1130px;
    max-width: 100%;
  }

  &.medium {
    width: 500px;
    max-width: 100%;
  }

  .headerIcon {
    margin-bottom: 30px;
    svg {
      width: 50px;
      height: 50px;
    }
    * {
      vertical-align: top;
    }
  }

  .icon {
    display: flex; // remove extra margin
    margin-bottom: 30px;

    svg {
      width: 50px;
      height: 50px;
    }
  }

  .header,
  .single {
    font: var(--font-14-medium);
    margin-bottom: 5px;
  }

  .subHeader {
    font: var(--font-25-bold);
    margin-bottom: 5px;
  }

  .subHeaderL2 {
    font: var(--font-20-bold);
    margin-bottom: 5px;
  }

  .subHeaderL3 {
    font: var(--font-17-semibold);
    margin-bottom: 5px;
  }
  * + .subHeaderL3 {
    margin-top: 25px;
  }
  .header + .subHeaderL3,
  .subHeader + .subHeaderL3 {
    margin-top: 15px;
  }

  .mainInfo {
    font: var(--font-40-extrabold);
    white-space: nowrap;

    &_small {
      font-size: 32px;
      line-height: 1.2;
      margin-bottom: 16px;
    }

    &_extraSmall {
      white-space: normal;
      font-size: 28px;
    }
  }

  .explanation {
    font: var(--font-14-semibold);
    color: var(--foreground-2);
    margin-top: 5px;
  }

  .subExplanation {
    font: var(--font-12-semibold);
    color: var(--foreground-2);
    margin-top: 5px;
  }

  .statusAndDate {
    display: flex;
    align-items: center;
    margin: 20px 0 30px;
    font: var(--font-12-semibold);
    padding: 8px 10px;
    border-radius: 101px;
    background: var(--background-3);
    color: white;

    &.loading .statusName,
    &.loading .statusDate {
      color: var(--foreground-2);
    }

    &.failed .statusName {
      color: var(--second-red-text);
    }

    svg {
      margin-right: 5px;
    }

    .statusDate {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-left: 5px;

      &::before {
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: var(--foreground-2);
      }
    }
  }

  .single {
    .separator {
      color: var(--foreground-1);
    }
  }

  .tableItem {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    span {
      font: var(--font-14-semibold);
      color: var(--foreground-2);
    }

    span:last-child {
      font: var(--font-14-extrabold);
      color: var(--foreground-3);
      display: flex;
      align-items: center;

      .button {
        width: 30px;
        height: 30px;
        padding: 0;
        margin-left: 10px;

        svg {
          width: 20px;
          height: 16px;

          path {
            stroke: none;
          }
        }
      }
    }

    .copyButton {
      margin-left: 10px;
    }
  }

  .wrap {
    width: 100%;
    max-width: 330px;
    margin-top: 30px;

    & ~ .wrap {
      margin-top: 10px;
    }
  }

  .button {
    width: 100%;
    height: 45px;
  }
}

.sheet {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  font: var(--font-14-semibold);
  color: var(--white);
  gap: 16px;
}

.sheetItemTitle {
  color: var(--foreground-2);
  margin: 0 0 5px;
}

.player {
  width: 960px;
  height: 480px;
}

.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  z-index: 1000;
}

.mobile.popup {
  padding: 30px 20px !important;
  flex-grow: 1;

  .mainInfo {
    font: var(--font-30-extrabold);
    white-space: nowrap;
    margin-bottom: 30px;

    &_small {
      font-size: 22px;
      line-height: 1.2;
      margin-bottom: 16px;
    }

    &_extraSmall {
      white-space: normal;
      font-size: 18px;
    }
  }

  .tableItem {
    margin: 0;
  }

  .tableItem ~ .tableItem {
    margin: 15px 0 0;
  }

  .subHeader {
    font: var(--font-20-bold);
    margin: 30px 0 0;
  }

  .subExplanation {
    font: var(--font-12-semibold);
    line-height: 17px;
  }

  .wrap {
    max-width: none;

    & ~ .wrap {
      margin-top: 10px;
    }
  }

  .wrap {
    & ~ .clean.wrap {
      margin-top: 20px;
    }
  }

  .statusAndDate {
    margin: 0 0 30px;
  }
}
