@import 'src/styles/mixins';

.hiddenWrapper {
  display: none;

  @media screen and (max-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10 !important;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 85px;
    padding: 0 20px;
  }

  .versionButton {
    width: 100%;
  }
}

.bgLayer {
  @include background;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1;
  height: 360px;
}

.bgLayerMobile {
  height: 0;
}

.outerMobileBackground {
  @include background($is-mobile: true);
}

.outer {
  min-width: 1140px;

  .inner {
    padding: 0 80px;

    @media screen and (max-width: 768px) {
      padding: 85px 80px 0;
    }
  }
}

.innerLayout {
  position: relative;

  display: flex;
  flex-direction: column;
  
  width: 100%;
  min-height: 100%;
  margin: 0 auto;

  .content {
    flex: 1 1 auto;
    display: flex;
  }
}

.outerXl {
  height: 100%;
  width: 100%;
  margin: 0 auto;

  .innerXl {
    @media screen and (min-width: 992px) {
      padding: 0 40px;
    }

    @media screen and (min-width: 1400px) {
      padding: 0 80px;
    }
  }
}

.outerMobile {
  .inner {
    padding: 0 20px;
  }
}

.outer,
.outerMobile {
  height: 100%;
  margin: 0 auto;

  &::before {
    height: 320px;
  }

  .inner {
    max-width: 1440px;
  }
}

.customError {
  margin: 10px 0 0;
  width: 318px;
}
