@import '@/styles/mixins';

.root {
  margin: 50px 0 0;
}

.list {
  display: grid;
  grid-template-columns: calc(100% / 3 - 40px / 3) calc(100% / 3 - 40px / 3) calc(100% / 3 - 40px / 3);
  grid-gap: 20px;
}

.article {
  border: 1px solid var(--background-3);
  background: var(--background-2);
  position: relative;
  color: var(--white);
  font: var(--font-12-semibold);
  border-radius: 20px;
  cursor: default;
  display: flex;
  flex-direction: column;
}

.image {
  height: 162px;
  object-fit: cover;
  img {
    max-width: 100%;
    border-radius: 20px 20px 0 0;
  }
  &._link {
    cursor: pointer;
  }
}

.disabledImage {
  height: 162px;
}

.disableTextWrapper {
  position: absolute;
  z-index: 1;
  padding: 40px 20px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .3);

  p {
    font: var(--font-14-semibold);
    text-align: center;
    margin: 0 0 10px;
  }
}

.volumes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  p {
    font: var(--font-17-semibold);
  }
}

.barLine {
  background: var(--gradient-1) !important;
}

.blurImage {
  img {
    filter: blur(5px);
  }
}

.textWrap {
  padding: 15px 20px 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.details {
  margin: 0 0 5px;
  color: var(--foreground-2);
}
.tag {
  @include gradientText();
}
.heading {
  margin: 0 0 15px;
  font: var(--font-14-bold);
}
.text {
  margin: 0 0 15px;
}
.articleControl {
  margin-top: auto;
}
.btn {
  width: 100%;
}