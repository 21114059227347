@import "./src/styles/mixins";

.component {
  @include contentWrapper();
  margin: auto 0;
  
  .content {
    @include contentPage();
  }
}
