.root {
  margin: 28px 0 40px;
}

.row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0 10px;
  padding: 12px 20px;
  border-radius: 12px;

  &:nth-child(2n) {
    background-color: var(--background-2);
  }

  & > div:nth-child(3),
  & > div:nth-child(4) {
    text-align: right;
  }
}

.header {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
}

.cell {
  font: var(--font-17-semibold);
  color: var(--foreground-3);

  &:nth-child(2n) {
    color: var(--foreground-2);
  }
}