.okxSuspensionRoot {
    font: var(--font-14-medium);
    color: var(--foreground-2);
}

.preContent {
    &, * {
        vertical-align: top;
    }
}

.heading {
    font: var(--font-17-semibold);
    color: var(--white);
    margin: 0 0 5px;
}

.cta {
    justify-content: space-between;
    padding-right: 8px;
    padding-left: 8px;
    gap: 14px;
    &,
    &.cta:hover {
        background: var(--green-text);
    }
}

.ctaIcon{
    background: var(--white);
    flex-basis: 24px;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 12px;
        height: 12px;
        path {
            fill: #12101A;
        }
    }
}

.column {
    .content {

  margin-bottom: 20px;
    }
    .cta {
        width: 100%;
    }
}

.row {
    display: flex;
    align-items: center;
    width: 600px;

    .content {
        width: 300px;
    }

    .cta {
        margin-left: auto;
    }

    .postContent {
        margin-left: 14px;
    }
}

.bordered {
    border-radius: 24px;
    border: 1px solid #B9F9FF;
    background: var(--background-2);
    padding: 20px;
}