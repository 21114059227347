@import 'src/styles/mixins';

.transfer {
  display: flex;
  flex-direction: column;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    .select {
      display: flex;
      min-width: 240px;
      position: relative;
    }

    .button {
      --transfer-color: var(--foreground-3);
      margin-top: 20px;
      padding: 5px;

      svg {
        width: 17px;
        height: 17px;
      }
    }
  }

  .coinSelect {
    width: auto;
  }
}

.coins {
  margin-bottom: 20px;
}

.available {
  color: var(--foreground-3);
}

.inputAside {
  display: flex;
  align-items: center;
  gap: 10px;
}

.maxBtn {
  height: 30px;
  padding: 0 10px;
  font: var(--font-12-semibold);
}

.buttonWrap {
  margin: 0 30px;
}

.buttonSubmit {
  margin-top: 20px;
}

//todo: move it in  share component
.informationBanner {
  background: var(--background-2);
  border-radius: 25px;
  padding: 20px 25px 20px 20px;
  margin-bottom: 20px;
  color: var(--foreground-3);
  font-weight: 600;
}

.infromationWarningMessage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font: var(--font-14-semibold);
}

.warningWalletText {
  font: var(--font-14-extrabold);
  @include gradientText(var(--gradient-foreground));
}
.warningCoinsText {
  font: var(--font-14-extrabold);
  @include gradientText(var(--btn-hover-grad));
}

.mobile {
  &.transfer {
    .top {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    .buttonWrap {
      --transfer-color: var(--foreground-3);
      margin: 15px 0 30px;
      width: 40px;
      height: 40px;
    }

    .informationBanner {
      margin: 0;
    }

    .infromationWarningMessage {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      font: var(--font-14-semibold);
      color: var(--foreground-3);
      flex-grow: 1;
    }
  }

  // Увелечение веса селектора
  .mainInfo.mainInfo.mainInfo {
    margin-bottom: 20px;
  }

  .explanation {
    margin-bottom: 5px;
  }

  .actionButton {
	margin-top: 20px;
    height: auto;
  }
}
