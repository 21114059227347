@import "src/styles/mixins";

.input {
  padding-top: 5px;
  text-align: center;
  width: 40px;
  height: 55px;
  box-sizing: border-box;
  color: var(--foreground-3);
  border: 1px solid var(--background-1);
  border-radius: 10px;
  caret-color: var(--foreground-3);
  background-color: var(--background-3);
  font: var(--font-25-bold);

  &::placeholder {
    color: var(--foreground-2);
  }

  &:focus {
    outline: 0;
    border-color: var(--foreground-2);
  }
}

.invalid {
  color: var(--second-red-text);
  border-color: var(--background-1);
}

.valid {
  border-color: var(--foreground-1);
  color: var(--green-text);
}

.darken {
  background-color: var(--background-2);
}
