.root {
  width: 100vw;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: var(--background-2);
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: red($color: #000000);
  padding: 0 100px;
  margin: 0 auto;
  max-width: 1440px;
  height: 70px;
}

.colorGradient {
  background-image: var(--gradient-foreground);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 200px;
}

.binanceLinkModal {
  width: 100%;
  max-width: 500px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  gap: 10px;
  font: var(--font-14-bold);
}

.buttonBlock {
  display: flex;
  align-items: center;
  gap: 20px;
}

.closeBtn {
  color: var(--white);
  &:hover {
    cursor: pointer;
  }
}
