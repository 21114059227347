.withdraw {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 84px;
  padding: 16px 20px 16px 20px;
  border-radius: 15px;
  background-color: var(--background-2);
  font: var(--font-12-semibold);
  color: var(--foreground-2);
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.asset {
  font-size: 20px;
  font-weight: 700;
}

.amount {
  color: var(--white);
}