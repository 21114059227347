.container {
  position: absolute;
  bottom: 8px;
  left: 0;

  display: flex;
  align-items: center;
  gap: 6px;

  height: 25px;
  width: 100%;
  padding: 0 8px;
}

.legends {
  display: flex;
  align-self: center;
  flex-grow: 1;

  height: 100%;
  width: 100%;

  border-radius: 6px;
  background-color: var(--background-3);
  overflow: hidden;
}

.scrollable {
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 0 14px;
}

.button.button {
  max-width: 25px;
  min-width: 25px;
  min-height: 25px;
  max-height: 25px;
  padding: 0;
  border-radius: 6px;
}
