.progressBar {
  display: flex;
  align-items: center;
  //width: 140px;
  justify-content: space-between;
  .progressWrap {
    height: 6px;
    //width: 140px;
    width: 100%;
    border-radius: 12px;
    background: var(--background-3);
    position: relative;
    .barLine {
      position: absolute;
      right: 0;
      height: 100%;
      width: 100%;
      max-width: 100%;
      border-radius: 12px;
      background-size: 150% 150%;
      transform: translate3d(0, 0, 0);
      animation: bar 5s ease infinite;
      animation-duration: 2s;
    }
  }
  .percent {
    color: var(--foreground-3);
    font: var(--font-12-extrabold);
    margin-left: 15px;
  }
}
@keyframes bar {
  0% {
    background-position: 0% 20%;
  }
  50% {
    background-position: 100% 70%;
  }
  100% {
    background-position: 0% 70%;
  }
}
