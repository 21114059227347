@import "./src/styles/mixins";

.container{
  @include contentWrapper(var(--layout-padding-major));
}

.levelsWrapper{
  padding-top: 30px;
  margin: 0 -15px;
  display: flex;
}

.levelsWrapperItem {
  min-height: 100%;
  padding: 0 15px;

  & + & {
    border-left: 1px solid var(--background-3);
  }
  
  &._active + & {
    border-left-color: transparent;
  }
  
  & + &._active {
    border-left-color: transparent;
  }
}