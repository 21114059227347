.leftBar {
  position: relative;
  width: 315px;
  min-width: 315px;
  display: flex;
  flex-direction: column;
}

.buttonWrapp {
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.icon {
  color: white;
  border: 1px solid white;
}