.hintWrapper {
  position: relative;

  .hint {
    background-color: red;
    display: none;
    opacity: 0;
    position: absolute;
    right: 0;
    margin-top: 13px;
    z-index: 100;
    color: var(--foreground-3);
    padding: 10px 15px;
    background: var(--background-3);
    border-radius: 10px;
    width: max-content;
    transition: opacity 0.1s linear;

    .hintText {
      font: var(--font-12-semibold);
      white-space: nowrap;
    }
  }

  &:hover .hint {
    display: flex;
    opacity: 1;
  }
}
