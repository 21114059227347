:root {
  --font-family: Gilroy, sans-serif;

  // Font styles
  --font-10-medium: 500 10px/14px var(--font-family);
  --font-10-semibold: 600 10px/14px var(--font-family);

  --font-12-semibold: 600 12px/15px var(--font-family);
  --font-12-medium: 500 12px/15px var(--font-family);
  --font-12-extrabold: 800 12px/15px var(--font-family);

  --font-14-semibold: 600 14px/22px var(--font-family);
  --font-14-medium: 500 14px/22px var(--font-family);
  --font-14-bold: 700 14px/22px var(--font-family);
  --font-14-extrabold: 800 14px/22px var(--font-family);

  --font-16-semibold: 600 16px/23px var(--font-family);
  --font-16-bold: 700 16px/23px var(--font-family);

  --font-17-semibold: 600 17px/25px var(--font-family);
  --font-17-bold: 700 17px/25px var(--font-family);

  --font-18-semibold: 600 18px/26px var(--font-family);
  --font-20-semibold: 600 20px/28px var(--font-family);
  --font-20-bold: 700 20px/30px var(--font-family);
  --font-20-extrabold: 800 20px/30px var(--font-family);

  --font-24-bold: 700 24px/31px var(--font-family);
  --font-24-extrabold: 800 24px/31px var(--font-family);

  --font-25-bold: 700 25px/35px var(--font-family);
  --font-25-extrabold: 800 25px/35px var(--font-family);

  --font-30-extrabold: 800 30px/40px var(--font-family);

  --font-40-extrabold: 800 40px/50px var(--font-family);
}
