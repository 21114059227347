.wrapper {
  max-width: 450px;
  box-sizing: border-box;
  color: var(--foreground-2);
}

.title {
  padding-bottom: 10px;
}

.description {
  font: var(--font-17-semibold);
  padding: 12px 0 32px 0;
}

.btnWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}