.content {
  margin: 0 auto;
  padding-top: 50px;
  max-width: 580px;
  width: 100%;

  .content_title {
    display: block;
    margin-bottom: 30px;
  }
}

.container {
  position: relative;
}

.arrow {
  width: 6px;
}

.arrowBackWrap {
  position: absolute;
  top: 50%;
  left: -45px;
  transform: translateY(-50%);
  width: 28px;
  height: 28px;
  border-radius: 50% 4px 4px 50%;
}