.empty {
  margin-top: 10px auto;
  font: var(--font-20-bold);
  color: var(--foreground-1);
  padding-bottom: 100px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 10px;

  margin: 0;
  padding: 0;
}

.consentText {
  color: var(--white) !important;
}
