.tabs {
  display: flex;
  align-items: baseline;
  margin-bottom: 40px;
}

.gain.carousel {
  display: flex;
  width: 100%;
  height: 40px;
  margin-bottom: 30px;

  .wrapperContent {
    position: absolute;
    top: 0;
    left: -20px;
    right: -20px;
  }

  .content {
    align-items: flex-end;
  }

  .item:first-child {
    margin-left: 20px;
  }
  .item:last-child {
    margin-right: 20px;
  }

  .item {
    margin: 0;
    padding: 0;
  }
}
