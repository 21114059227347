.root {
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 1fr;
  align-items: center;
  grid-column-gap: 20px;
  padding: 20px;
  border-radius: 15px;
  background-color: var(--background-2);
  font: var(--font-14-bold);
  color: var(--foreground-3);

  &:not(:first-child) {
    margin-top: 10px;
  }

  &.expired {
    color: var(--foreground-2);
  }
}

.info {
  margin-top: 3px;
  font: var(--font-12-semibold);
  color: var(--foreground-2);

  .expired & {
    color: var(--foreground-1);
  }
}

.status {
  text-align: right;

  .root:not(.expired) & {
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: var(--gradient-foreground);
  }
}

.iconWrap {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 0 0 0;
}

.icon {
  max-width: 20px;
  height: 20px;
}