.component {
  width: 300px;
}

.title {
  word-break: break-all;
  margin: 0;
}

.msg {
  margin-top: 5px;
  color: var(--foreground-3);
  word-break: break-all;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.buttonsWrapper {
  margin-top: 30px;
}
.button {
  width: 100%;
  margin-top: 15px;
}

.label {
  display: block;
  margin: 20px 0;
}
