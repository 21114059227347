.sectionIcon {
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
}

.setting {
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 20px;
  border-radius: 15px;
  background-color: var(--background-2);

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &>*:last-child {
    margin-left: auto;
  }
}

.settingTitle {
  font: var(--font-17-semibold);
  color: var(--gray-5);
}

.settingStatus {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 2px;
  font: var(--font-14-semibold);
  color: var(--foreground-2);
}

.settingActions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 47px;
  color: var(--white);
  padding-right: 25px;
}

.sectionTitle {
  color: var(--foreground-2);
}

.settingHeader{
  padding-right: 25px;
}