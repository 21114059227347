.heading {
  margin-top: 30px;
  font: var(--font-40-extrabold);
  color: var(--gray-5);
}

.email {
  margin-top: 5px;
  font: var(--font-17-semibold);
  color: var(--white);
}

.uid {
  display: flex;
  align-items: center;
  padding: 12px 0;
  gap: 12px;
  font: var(--font-14-semibold);
  color: var(--foreground-2);
}


.team {
  margin: 40px 0 0;
}