.root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.lined {
  border-bottom: 1px solid var(--foreground-1);

  &.small {
    height: 70px;
  }
  &.extraSmall {
    height: 70px;
  }

  &.medium,
  &.large {
    height: 80px;
  }

  &.error {
    border-color: var(--second-red-text);
  }
}

.toggle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  color: var(--foreground-3);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  .small &:not(.customized),
  .medium &:not(.customized) {
    font: var(--font-20-bold);
  }
  .extraSmall &:not(.customized) {
    font: var(--font-12-bold);
  }

  .large &:not(.customized) {
    font: var(--font-25-bold);
  }

  .disabled & {
    opacity: 0.2;
    cursor: not-allowed;
  }
}

.toggleInner {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .small & {
    padding: 11px 0 12px;
  }
  .extraSmall & {
    padding: 0px;
  }

  .large &,
  .medium & {
    padding: 13px 0 14px;
  }
}

.label {
  font: var(--font-14-bold);
}

.selectedOption {
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
}

.toggleChevron {
  margin-left: 10px;
  flex-shrink: 0;

  .open & {
    transform: rotate(180deg);
  }
}

.dropdown {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: max-content;
  max-height: 200px;
  padding: 10px;
  border-radius: 15px;
  background: var(--background-3);
  overflow: auto;
  z-index: 1;
}

.search {
  min-width: 250px;
  margin-bottom: 15px;
  background: var(--background-2);
}

.noOptions {
  margin-bottom: 15px;
  text-align: center;
  color: var(--foreground-2);
}

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  min-width: 90px;
  padding: 10px;
  border-radius: 10px;
  background: var(--background-3);
  font: var(--font-17-semibold);
  color: var(--foreground-3);
  transition: background-color, opacity 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: var(--btn-hover-on-bg);

    &.disabled {
      background-color: transparent;
    }
  }

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &.active {
    background-color: var(--btn-hover-on-bg);

    .optionChevron {
      opacity: 1;
    }
  }
}

.optionContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 3px;

  overflow: hidden;

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &.disabledMobile {
    cursor: not-allowed;
  }
}

.value {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  font: var(--font-12-semibold);
  color: var(--foreground-2);
}

.optionChevron {
  opacity: 0;
  flex-shrink: 0;
}

.rightIcon {
  flex-shrink: 0;
}

.info {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -22px;
  display: flex;
  font: var(--font-14-semibold);
}

.errorMessage {
  color: var(--red-text);
}

.description {
  margin-left: auto;
  color: var(--foreground-2);
}

.group {
  & ~ & {
    margin-top: 15px;
  }
}

.groupTitle {
  margin: 0 10px 5px;

  color: var(--foreground-3);
  font: var(--font-12-semibold);
  line-height: 17px;
}

.mobile {
  &.lined {
    &.medium {
      height: 70px;
    }
  }

  &.toggle {
    .small &,
    .medium & {
      font: var(--font-17-bold);
    }
    .extraSmall & {
      font: var(--font-12-bold);
    }
  }

  .option {
    padding: 12.5px 10px;
  }

  .optionItem {
    width: 100%;
  }
}

.likeButton.root {
  width: auto;
}
