@import './src/styles/mixins';

.promoMessage {
  text-align: left;
    font: var(--font-14-semibold);
    color: var(--general-gray);


  .messageWithIcon {
    background: var(--background-3);
  }
}

.coloredMagenta {
  color: #E571FF;
}

.colorWhite {
  color: var(--white);
  margin: 0;
}

.colorGradient {
  @include gradientText;
}

.table {
  border: 1px solid var(--background-3);
  border-radius: 15px;
  .td {
    padding: 10px;
    display: flex;
    align-items: center;
    height: 100%;
    &:first-child {
      color: var(--white);
    }
  }
}

.tableRow {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-radius: 15px;
  min-height: 50px;
  padding: 0 10px;

  &:first-child {
    min-height: 54px;
    background: var(--background-3);
    border-radius: 15px 15px 0 0;
    font: var(--font-12-semibold);
  }
}

.heroValue {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  .value {
    font: var(--font-40-extrabold);
    @include gradientText;
    margin: 0 15px 0 0;
  }
}