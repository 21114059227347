.root {
  position: relative;
  display: grid;
  grid-template-columns: 4fr 2fr minmax(min-content, 1fr) minmax(100px, max-content);
  grid-column-gap: 40px;
  padding: 20px;
  border-radius: 15px;
  background-color: var(--background-2);
  transition: background-color 0.2s linear;
  align-items: center;

  &:not(:first-child) {
    margin-top: 10px;
  }

  &>*:nth-child(3) {
    text-align: right;

    .text {
      justify-content: flex-end;
    }
  }

  &:not(.disabled):hover {
    background-color: var(--background-3);

    .copy {
      --background: var(--background-3);
    }

    .copyBtn {
      background-color: var(--btn-hover-on-bg);
    }

    .deleteBtn {
      opacity: 1;
    }
  }

  &:hover {
    .editBtn {
      display: flex;
    }
  }
}

.cashbackBlock {
  display: flex;
}

.text {
  display: flex;
  align-items: center;
  gap: 5px;
  font: var(--font-14-bold);
  color: var(--white);
  word-break: break-word;
  overflow-wrap: break-word;
}

.code {
  max-width: 220px;
}

.copy {
  --background: var(--background-2);
  width: 20px;
  height: 20px;
}

.info {
  margin-top: 3px;
  font: var(--font-12-semibold);
  color: var(--foreground-2);
  white-space: nowrap;
}

.deleteBtn {
  position: absolute;
  top: -6px;
  right: -6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--btn-hover-on-bg);
  color: var(--foreground-3);
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.2s linear;
}

.head {
  display: flex;
  align-items: center;
  gap: 10px;
}

.editBtn {
  display: none;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: var(--background-3);
  cursor: pointer;
}