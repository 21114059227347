@import 'src/styles/mixins';

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: var(--background-3);
  font: var(--font-12-semibold);
  text-transform: uppercase;
  color: var(--white);
  cursor: pointer;
}

.indicator {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 4px solid transparent;
  box-sizing: border-box;
  background-color: var(--second-red-text);
}