.root {
  display: flex;
  flex-direction: column;
}

.line {
  width: 100%;
  height: 1px;
  border: 1px dashed var(--foreground-2);
  margin-bottom: 30px;
}

.container {
  display: flex;
  gap: 10px;
}

.loader {
  position: relative;
  top: 40%;
}

.emptyList {
  position: relative;
  top: 100px;
}

.errorBlock {
  position: relative;
  top: 120px;
}
