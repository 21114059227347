.history {
  position: relative;
  padding: 20px 30px 20px 20px;
  border-radius: 15px;
  background: var(--background-2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:hover {
    background: var(--btn-hover);
  }

  .left {
    display: flex;
    align-items: center;
    gap: 20px;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  .titleWrap {
    display: flex;
    flex-direction: column;
    color: var(--foreground-2);

    .title {
      font: var(--font-14-bold);
      color: var(--white);
    }

    .date {
      font: var(--font-12-semibold);
      margin-top: 5px;
    }
  }

  .item {
    .new {
      color: var(--green-text);
      font: var(--font-14-bold);
    }

    .newMinus {
      @extend .new;
      color: var(--red-text);
    }

    .amount {
      color: var(--foreground-3);
      font: var(--font-14-bold);
      margin-bottom: 5px;
    }
  }
}

.statusWrap {
  position: absolute;
  display: flex;
  left: 50%;
  transform: translateX(-50%);
}

.popupAmount {
  font: var(--font-40-extrabold);
}

.popupFee {
  font: var(--font-14-semibold);
  color: var(--foreground-2);
}

.popupStatus {
  padding: 20px 0 30px 0;
}