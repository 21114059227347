.resultWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.resultDescription {
  color: var(--foreground-2);
  font: var(--font-14-medium);
}

.support {
  padding-top: 20px;
  color: var(--foreground-2);
  font: var(--font-14-bols);
  line-height: 20px;
  max-width: 210px;
}