@import "./src/styles/mixins";

.wrapper {
  width: 400px;
  height: 285px;
  border: 1px solid white;
  color: white;
}

.leaderboard {
  text-align: center;
  padding: 20px;
  color: #fff;
}

.header {
  font-size: 14px;
  color: #ccc;
  margin-bottom: 20px;
}

.cardsContainer {
  display: flex;
  justify-content: space-around;
  gap: 10px;
}

.empty {
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: var(--font-14-semibold);
}

.title {
  font: var(--font-20-bold);
}

.colorGold {
  color: gold;
}