.root {
  display: flex;
  flex-direction: column;
  width: 41px;
  height: 40px;
  background: #1c1a24;
  border-radius: 7px;
  padding: 0;
  color: var(--white);
  border: 1px solid #29292e;
}

.month {
  height: 14px;
  background: var(--foreground-2);
  font: var(--font-10-medium);
  text-transform: uppercase;
  padding-top: 1px;
  padding-right: 9px;
  padding-left: 9px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  text-align: center;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.day {
  text-align: center;
  padding-top: 3px;
}
