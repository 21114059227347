.historyTransaction {
  height: 80px;
  padding: 20px 30px 20px 20px;
  border-radius: 15px;
  background: var(--background-2);
  display: grid;
  grid-template-columns: 1.75fr 1fr 1fr 21% auto;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  &:hover {
    background: var(--btn-hover);
  }
  .left {
    display: flex;
    align-items: center;
    gap: 20px;
    svg {
      width: 32px;
      height: 32px;
    }
    .currencyWrap {
      display: flex;
      flex-direction: column;
      color: var(--foreground-2);
      .currency {
        font: var(--font-14-bold);
        .baseCurrency, .percent {
          color: var(--foreground-3);
        }
      }
      .type {
        font: var(--font-12-semibold);
        margin-top: 3px;
      }
    }
  }
  .status {
    display: flex;
    align-items: center;
    svg {
      margin-right: 5px;
    }
    .statusCompleted {
      color: var(--green-text);
      font: var(--font-12-semibold);
    }
    .statusPending {
      color: var(--foreground-2);
      font: var(--font-12-semibold);
    }
    .statusFailed {
      color: var(--second-red-text);
      font: var(--font-12-semibold);
    }
  }
  .date {
    font: var(--font-12-semibold);
    color: var(--foreground-2);
  }
  .item {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    .text {
      font: var(--font-12-semibold);
      color: var(--foreground-2);
    }
    .total {
      font: var(--font-12-semibold);
      color: var(--foreground-2);
    }
    .sameTransaction {
      @extend .total;
      color: var(--foreground-1);
    }
    &:nth-child(4) {
      text-align: right;
    }
  }
}

.amount {
  font: var(--font-14-bold);

  &.green {
    color: var(--green-text);
  }

  &.red {
    color: var(--second-red-text);
  }

  &.gray {
    color: var(--foreground-1);
  }
}

.historyPopup {
  color: var(--foreground-3);
  .icon {
    margin-bottom: 30px;
    svg {
      width: 60px;
      height: 60px;
    }
  }
  .transactionType {
    font: var(--font-14-medium);
    margin-bottom: 5px;
  }
  .sum {
    font: var(--font-40-extrabold);
  }
  .networkFee {
    font: var(--font-14-semibold);
    color: var(--foreground-2);
    margin-top: 5px;
  }
  .statusAndDate {
    display: flex;
    align-items: center;
    margin: 20px 0 30px;
    font: var(--font-12-semibold);
    padding: 8px 10px;
    border-radius: 101px;
    background: var(--background-3);
    svg {
      margin-right: 5px;
    }
    span {
      margin: 0 5px;
      color: var(--foreground-2);
    }
  }
  .listItem {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    span {
      font: var(--font-14-medium);
      color: var(--foreground-2);
    }

    span:last-child {
      font: var(--font-14-bold);
      color: var(--foreground-3);
      display: flex;
      align-items: center;
      .button {
        width: 30px;
        height: 30px;
        padding: 0;
        margin-left: 10px;
        svg {
          width: 20px;
          height: 16px;
          path {
            stroke: none;
          }
        }
      }
    }
  }
  .buttonContinue {
    width: 100%;
    margin-top: 20px;
  }
}
