.root {

}

.container {
  height: 40px;
  padding: 5px 15px;
  border-radius: 10px;
  border: none;
  background-color: var(--background-3);
  color: var(--white);

  .small & input {
    font: var(--font-14-bold);
  }
}

.icon {
  width: 20px;
  height: 20px;
  color: var(--foreground-3);

  .small & {
    color: var(--foreground-2);
  }
}