.root {
  display: flex;
  align-items: center;
  gap: 15px;
  height: 40px;
  padding: 0 15px;
  border-radius: 10px;
  background-color: var(--background-3);
  font: var(--font-14-bold);
  color: var(--white);
}

.icon {
  --background: var(--white);
  width: 18px;
  height: 18px;
}