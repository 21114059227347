.react-datepicker {
  background-color: #12101A;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  border: none;
  border-radius: 30px;
}

.react-datepicker__header {
  border-bottom: none;
}

.react-datepicker__day-names {
  display: flex;
  justify-content: space-around;
}

.react-datepicker__day--keyboard-selected {
  background-color: transparent;
}

.react-datepicker__day--selected {
  background-color: transparent;

  &:focus {
    background: var(--gradient-foreground) !important;
    border-radius: 10px;
  }
}

.react-datepicker__day--in-range {
  background: #1B1824;
}

.react-datepicker__day--in-selecting-range {
  background: #1B1824 !important;
}

.react-datepicker__day--range-start, .react-datepicker__day--range-end {
  background: var(--gradient-foreground);
}

.react-datepicker__month {
  margin: 0;
}

.react-datepicker__day--outside-month {
  color: #676573 !important;
}

.react-datepicker__header {
  background-color: #12101A;
  color: white;
}

.react-datepicker__day-name {
  color: #676573;
}

.react-datepicker__month-container {
  float: inherit;
}

.react-datepicker__week {
  display: flex;
  justify-content: space-evenly;
  background-color: #12101A;
}

.react-datepicker__day {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FAFAFA;
  height: 40px;
  width: 40px;
  border-radius: 10px;

  &:hover {
    background: #262430;
  }
}

.react-datepicker__children-container {
  width: 100%;
  margin: 20px 0;
}