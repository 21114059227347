.root {
  position: relative;
  min-width: 60px;
  border-radius: 7px;
  transition: all .2s ease;
  cursor: pointer;

  &.dark {
    &.active,
    &:has(input:focus) {
      .content {
        box-shadow: none;
      }

      &::after {
        content: "";
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        z-index: 0;
        border-radius: inherit;
        background-image: var(--gradient-foreground);
      }

      .arrow {
        color: var(--gray-10);
      }
    }
  }
}

.content {
  position: relative;
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 1px;
  border-radius: inherit;
  z-index: 1;
  transition: all .2s ease;

  .light & {
    background-color: rgba(255, 255, 255, .3);
  }

  .dark & {
    box-shadow: 0 0 0 1px var(--btn-hover);
    background-color: var(--background-1);

    &:hover {
      box-shadow: 0 0 0 1px var(--btn-hover-on-bg);
    }
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 18px;
  height: 100%;
  cursor: pointer;

  &:last-child .arrow {
    transform: rotate(180deg);
  }
}

.arrow {
  .dark & {
    color: var(--foreground-1);
  }

  .light & {
    color: var(--foreground-3);
  }
}

.input {
  max-width: 100%;
  width: 100%;
  border: none;
  background: transparent;
  outline: 0;
  text-align: center;
  font: var(--font-14-bold);
  color: var(--foreground-3);

  &::selection {
    background-color: var(--second-purple-text);
  }
}