.footer {
  position: static;
  z-index: 50;

  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 60px;

  padding: 40px 0;
  background-color: var(--background-1);

  .top {
    display: flex;
    flex-direction: column;
    gap: 40px;

    width: 100%;
  }

  .logo {
    width: 186px;
    height: 30px;
  }

  .section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .sectionTitle {
    text-transform: uppercase;
    font: var(--font-12-semibold);
    color: var(--general-gray);
  }

  .socialList {
    display: flex;
    gap: 10px;

    list-style: none;
    margin: 0;
    padding: 0;
  }

  .socialButton {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 50px;
    height: 50px;
    border-radius: 14px;
    background-color: var(--background-light-dark);
  }

  .linkList {
    display: flex;
    flex-direction: column;
    gap: 15px;

    list-style: none;
    margin: 0;
    padding: 0;
  }

  .bottom {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 30px;

    width: 100%;
    padding-top: 30px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background-color: var(--white);
      opacity: 0.1;
    }
  }

  .bottomSection {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .bottomTitle {
    font: var(--font-16-semibold);
    color: var(--white);
  }

  .bottomDescription {
    white-space: pre-wrap;
    font: var(--font-10-medium);
    color: var(--general-gray);
  }

  .bottomLinkList {
    display: flex;
    flex-direction: column;

    list-style: none;
    margin: 0;
    padding: 0;
  }

  .bottomLink {
    cursor: pointer;
    color: var(--white);
    font: var(--font-12-semibold);
    line-height: 17px;
    white-space: pre-line;
  }
}
