.content {
  margin: 0 auto;
  padding-top: 50px;
  max-width: 460px;
  width: 100%;

  .content_title {
    display: block;
    margin-bottom: 30px;
  }
}
