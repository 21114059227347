.root {
  display: flex;
  gap: 10px;
  width: 344px;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--background-3);
  font: var(--font-14-semibold);
  color: var(--foreground-3);
}

.icon {
  flex: 0 0 18px;
  width: 18px;
  height: 18px;
  margin-top: 2px;
}