.kycLinkStatusRoot {
  margin: 30px 0 0;
}

.kycLinkStatusIcon {
  flex: 0 0 30px;
  width: 30px;
  height: 30px;
  margin-top: 2px;
}

.statusText {
  font: var(--font-17-semibold);
  color: var(--foreground-3);
}

.statusTextYellow {
  font: var(--font-17-semibold);
  color: var(--warning-color);
}

.statusTextGreen {
  color: var(--green-text);
}

.textWrapper {
  display: flex;
  align-items: center;
  gap: 4px
}

.textWrapperColumn {
  p {
    margin: 0;
  }
}

.kycLinkStatusWrapper {
  width: max-content;
  max-width: 940px;
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  gap: 20px;
  background-color: var(--background-2);
  border-radius: 25px;

  .kycLinkStatusIcon {
    path:not(:first-child) {
      fill: var(--white);
    }
  }
}

.kycLinkStatusSuccess {
  .kycLinkStatusIcon {
    path:not(:first-child) {
      fill: var(--white);
    }
  }
}

.descWrapper {
  margin-top: 10px;
    font: var(--font-14-medium);
  p {
    margin: 0;
  }
}

.descTextWhite {
  color: var(--foreground-3);
}

.descTextGrey {
  color: var(--foreground-2);
}

.gradientText {
  background-image: var(--gradient-foreground);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
