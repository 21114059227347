.pageTitle {
  margin-bottom: 40px;
}

.title {
  text-align: center;
  color: var(--foreground-3);
  font: var(--font-25-bold);
}

.empty {
  margin-top: 10px;
}

.loader {
  margin-top: 80px;
}