.root {
  max-width: 400px;
  height: 528px;
  padding: 20px;
  border-radius: 12px;
  background: var(--background-2);
}

.filterContainer {
  display: flex;
  gap: 10px;
}

.tickerContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  height: 400px;
}

.sortContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background: var(--background-3);
  border-radius: 5px;
  padding: 5px 15px;
  height: 34px;
}

.sortContainer .select div {
  font: var(--font-12-semibold);
}

.sortContainer .select div svg {
  width: 6px;
  height: 6px;
  margin-left: 6px;
}

.search {
  width: 97px;
  height: 34px;
  border-radius: 5px;
}

.filterContainer .search input {
  font: var(--font-12-semibold);
}

.elips {
  @media screen and (max-width: 1440px) {
    width: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.filterContainer .search input::placeholder {
  color: var(--foreground-2);
}

.filterContainer .search svg {
  color: var(--white);
}

.sortTitle {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
  white-space: nowrap;
}
