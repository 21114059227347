// CHECK BOOLEAN VALUE
@function bool($value: false) {
  @if $value == false or $value == '' or $value == 'false' or $value == 'false' or $value == 0 {
    @return false;
  }
  @return true;
}

@mixin background($is-mobile: false) {
  position: relative;

  @if bool($is-mobile) == true {
    &::before {
      content: '';
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: inherit;
      background-image: url(/../src/assets/png/background.png);
      background-repeat: no-repeat;
      background-attachment: fixed;
      z-index: -1;

      // mobile properties
      background-color: var(--background-1);
      background-size: 405px 90px;
      background-position: top right;
    }
  } @else {
    &::before {
      content: '';
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: inherit;
      // background-color: var(--background-1);
      background-image: url(/../src/assets/png/background.png);
      background-repeat: no-repeat;
      background-size: 1440px 320px;
      background-position: top center;
      background-attachment: fixed;
      z-index: -1;
      will-change: transform;

      @media screen and (min-width: 1441px) {
        background-size: 1960px auto;
      }

      @media screen and (min-width: 1960px) {
        background-position-y: -100px;
      }
    }
  }

  :global(.outerBlur) &,
  &:global(.outerBlur) {
    &::before {
      top: -5px;
      mix-blend-mode: darken;
      filter: blur(10px) brightness(0.5);
      transform: scale(1.5);
      animation: bg_top 0.3s ease-out forwards;
    }
  }

  //:global(.mainOuterAnimation) &,
  //&:global(.mainOuterAnimation) {
  //  &::before {
  //    top: 0;
  //    transform: scale(1);
  //    animation: bg_main 0.3s ease-out forwards;
  //  }
  //}

  & > * {
    position: relative;
    z-index: 1;
  }
}

@mixin flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin contentWrapper($padding: 40px 0 76px) {
  height: 100%;
  padding: $padding;
  width: 100%;
  display: flex;
  flex-direction: column;
}

@mixin contentPage($gap: var(--layout-sidebar-gap-default)) {
  display: flex;
  gap: $gap;
}

@mixin gradientText($gradient: var(--gradient-foreground)) {
  color: transparent;
  background: $gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes bg_main {
  0% {
    background-position: top -38px center;
    transform: scale(1.1);
  }
  100% {
    background-position: top 0 center;
    transform: scale(1);
  }
}

@keyframes bg_top {
  0% {
    background-position: top 0 center;
    transform: scale(1);
  }
  100% {
    background-position: top -38px center;
    transform: scale(1.5);
  }
}
