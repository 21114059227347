@import "./src/styles/mixins";

.content {
  padding: 4px 0 0 0;
  font: var(--font-14-medium);
  color: var(--foreground-2)
}

.buttons{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}