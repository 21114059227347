.component {
  width: 320px;
}

.title {
  margin: 0;
}

.msg {
  margin-top: 5px;
  color: var(--foreground-3);
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.buttonsWrapper {
  margin-top: 30px;
}

.button {
  width: 100%;
  margin-top: 15px;
}

.button2fa {
  width: 100%;
  margin-top: 40px;
}

.confirmButton {
  color: var(--foreground-3);
}

.title {
  font: var(--font-20-bold);
  color: var(--foreground-3);
  margin-bottom: 5px;
  text-align: center;
}

.enterCodeText {
  text-align: center;
  margin: 40px 0 0 0;
  font: var(--font-17-bold);
  color: var(--white);
}

.label {
  display: block;
  margin: 20px 0;
}

.smallText {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
  margin: 0;
  text-align: center;
}

.footerText {
  margin-top: 15px;
}

.resetDesc {
  padding-top: 16px;
  font: var(--font-12-semibold);
  color: var(--foreground-2);
  text-align: center;
}