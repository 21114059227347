.link {
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;

  .text,
  .textMobile {
    color: var(--white);
    opacity: 0.7;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 1;
    }
  }

  .text {
    font: var(--font-14-semibold);
  }

  .icon {
    display: flex;
    gap: 5px;
  }

  .iconWrap {
    margin-left: 5px;
  }

  .textMobile {
    font: var(--font-24-bold);
  }

  .activeText,
  .activeTextMobile {
    color: var(--white);
    opacity: 1;
  }

  .activeText {
    font: var(--font-14-extrabold);
  }

  .activeTextMobile {
    font: var(--font-24-bold);
  }
}

.badge {
  background: linear-gradient(45deg, #d395ff 0%, #ef32ff 44.56%, #4062ff 100%);
  border-radius: 14px;
  margin-left: 7px;
  font-size: 10px;
  text-transform: uppercase;
  width: 34px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
}

.dropdown {
  position: relative;
  display: flex;
  align-items: center;

  &:hover {
    .dropdownInnerWrap {
      visibility: visible;
      opacity: 1;
    }
  }
}

.dropdownInnerWrap {
  position: absolute;
  right: 0;
  top: 100%;
  padding: 10px 0 0;
  visibility: hidden;
  opacity: 0;
}

.dropdownInner {
  color: var(--white);
  background: var(--background-3);
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 15px;
  padding: 10px;
  .link:hover {
    .text {
      opacity: 1;
    }
  }
}

.dropdownItem {
  padding: 10px;
  display: flex;
  min-width: 177px;

  &:hover {
    background: var(--btn-hover-on-bg);
    border-radius: 10px;
  }
}

.activeLink {
  background: var(--btn-hover-on-bg);
  display: flex;
  border-radius: 10px;
}

.arrow {
  display: block;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}